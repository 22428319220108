import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Checkbox,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useApp } from "../../store";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { editeProfile, twoFactorAuth, updateFaStatus } from "../../api";
import { useState } from "react";
import { phoneValidate } from "../../helper/phoneValidation";
import QRCode from "react-qr-code";

const { Title } = Typography;

export const EditAccount = () => {
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phone, setPhoneState] = useState(null);
  // const [isTwoFA, setisTwoFA] = useState(false);
  // const [dynamicLink, setDynamicLink] = useState("");
  // const [OTP, setOTP] = useState("");
  const { isEditAccount, setIsEditAccount, setIsLogin, setUser, user } =
    useApp();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(editeProfile, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsEditAccount(false);
        user.name = data?.data?.name;
        user.username = data?.data?.username;
        user.email = data?.data?.email;
        user.phone = data?.data?.phone;
        user.twofaEnabled = data?.data?.twofaEnabled;
        setUser(user);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  // const { mutate: twoFactorAuthReq, isLoading: twoFactorAuthLoading } =
  //   useMutation(twoFactorAuth, {
  //     onSuccess: (data) => {
  //       if (data.success) {
  //         message.success(data.msg);
  //         setDynamicLink(data?.data);
  //       } else {
  //         message.error(data.msg);
  //       }
  //     },
  //     onError: (e) => {
  //       message.error(e.message);
  //     },
  //   });

  // const { mutate: mutateFaStatus, isLoading: mutateFaStatusLoading } =
  //   useMutation(updateFaStatus, {
  //     onSuccess: (data) => {
  //       if (data.success) {
  //         message.success(data.msg);
  //         setisTwoFA(false);
  //         setIsEditAccount(false);
  //         localStorage.clear("groceryBO_token");
  //         setUser(null);
  //         setIsLogin(false);
  //       } else {
  //         message.error(data.msg);
  //       }
  //     },
  //     onError: (e) => {
  //       message.error(e.message);
  //     },
  //   });

  const onFinish = (values) => {
    if (phoneValidate(phone) && phoneValidate(phone)?.index === 0) {
      values.phone = phone;
      mutate(values);
    } else {
      setPhoneValidation(true);
    }
  };

  useEffect(() => {
    let _user = localStorage.getItem("groceryBO_user");
    if (_user) {
      setUser(JSON.parse(_user));
    } else {
      localStorage.removeItem("groceryBO_token");
      localStorage.removeItem("groceryBO_refresh_token");
      localStorage.removeItem("groceryBO_user");
      setIsLogin(false);
    }
  }, []);

  // function handleTwoFAModal() {
  //   if (user && !user?.twofaEnabled) {
  //     twoFactorAuthReq();
  //     setisTwoFA(true);
  //   }
  // }

  return (
    <Modal
      title={<Title level={5}>Edit Account</Title>}
      className="app-modal"
      centered
      visible={isEditAccount}
      onCancel={() => {
        setIsEditAccount(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      {/* {isTwoFA ? (
         <Form
         requiredMark={"optional"}
         name="otp"
         autoComplete="off"
         layout="vertical"
       >
        <div>
          {" "}
          <QRCode
            size={400}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={dynamicLink}
            viewBox={`0 20 256 256`}
          />
          <Col span={24}>
            <Form.Item name="otp">
              <Input
                placeholder="enter your otp here"
                onChange={(e) => setOTP(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Form.Item>
            <Button
              loading={isLoading}
              icon={<SaveOutlined />}
              type="default"
              onClick={() =>
                mutateFaStatus({
                  twofaEnabled: true,
                  otp: OTP,
                })
              }
              block
              style={{
                marginTop: "16px",
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
        </Form>
      ) : ( */}
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
          name: user && user.name,
          username: user && user.username,
          email: user && user.email,
          phone: user && user.phone,
          twofaEnabled: user?.twofaEnabled,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Murtadha M. Abed" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true }]}
            >
              <Input placeholder={"baly001"} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={"07**** ****"}
                status={phoneValidation ? "error" : ""}
                maxLength="11"
                minLength="11"
                onChange={(e) => setPhoneState(e.target.value)}
                value={phone}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
              <Input placeholder={"example@baly.iq"} />
            </Form.Item>
          </Col>

          {/* <Col span={24}>
              <Form.Item name="twofaEnabled" valuePropName="checked">
                <Checkbox onClick={handleTwoFAModal}>Activate 2FA</Checkbox>
              </Form.Item>
            </Col> */}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* )} */}
    </Modal>
  );
};
