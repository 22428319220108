import { Card, Input, Space, Button, Breadcrumb, Row, Col } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureMap } from "./map";
import { PureCreate } from "./create";
import { useZone } from "./store";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax

const { Search } = Input;

export const ZonesPage = () => {
  const { setIsCreateModal, setQueryKey, queryKey } = useZone();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Zones List</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[0, 20]}>
        <Col span={14}>
          <Card>
            <div className="app-space-between">
              <Space>
                <Search
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onSearch={(val) => {
                    searchParams.set("q", val);
                    searchParams.set("page_num", 1);
                    setSearchParams(searchParams);
                    setQueryKey(val);
                  }}
                  style={{ width: 340 }}
                  placeholder="Write To Search..."
                />
              </Space>
              <Space>
                {authFunc("AREA_CREATE") && (
                  <Button
                    type="primary"
                    onClick={() => setIsCreateModal(true)}
                    icon={<PlusOutlined />}
                  >
                    Create New
                  </Button>
                )}
              </Space>
            </div>
            <br />
            <PureTable />
          </Card>
        </Col>
        <Col span={10}>
          <Card>
            <PureMap />
          </Card>
        </Col>
      </Row>
      <PureCreate />
    </div>
  );
};
