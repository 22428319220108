import React from "react";
import { Select } from "antd";
import { useVendorDiscount } from "./store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function DiscountFilters() {
  const { status, setStatus, queryKey, setPage } = useVendorDiscount();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(
    () => {
      let obj = {
        status: searchParams.get("status") || null,
      };
      setStatus(obj.status);
    },
    [searchParams.get("q")],
    searchParams.get("status")
  );

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setStatus(val);
              setPage(1);
              searchParams.set("status", val);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setStatus(null);
            searchParams.delete("status");
            setSearchParams(searchParams);
          }}
          placeholder="Select Status"
          style={{ width: "100%" }}
          value={status}
        >
          <Option value={"true"}>Active</Option>
          <Option value={"false"}>InActive</Option>
        </Select>
      </div>
    </div>
  );
}
