import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Upload,
  InputNumber,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useVendorCategory } from "./store";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "react-query";
import { createVendorCategory, editVendorCategory } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { inputNumberFormatter } from "../../helper/inputNumberFormatter";
const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    name,
    priority,
    description,
    setDescription,
    setId,
    setName,
    setPriority,
  } = useVendorCategory();
  const [form] = Form.useForm();

  let resetState = () => {
    setDescription(null);
    setId(null);
    setName(null);
    setPriority(null);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const { mutate, isLoading } = useMutation(createVendorCategory, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "categories",
        setterList: [() => setIsCreateModal(false)],
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorCategory,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "categories",
          setterList: [() => setIsCreateModal(false)],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      values.img = values.img.file;
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Category</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={460}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, name, description, priority }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={16}>
            <Form.Item
              label="Category Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Write category name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                placeholder="0"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <TextArea
                rows={4}
                placeholder={"Write description of this category"}
              />
            </Form.Item>
          </Col>

          {!id ? (
            <Col span={24}>
              <Form.Item name="img" rules={[{ required: true }]}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag Image to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    This Image will view in Product details
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
