import {
  Form,
  Button,
  Row,
  Col,
  Divider,
  Space,
  Select,
  InputNumber,
  Checkbox,
} from "antd";

import { useVendor } from "../store";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import {
  getCategories,
  getStatus,
  getFee,
  getDistance,
  getServiceFee,
} from "../../../api";
import { inputNumberFormatter } from "../../../helper/inputNumberFormatter";
import { useDataStore } from "../dataStore";

const { Option } = Select;

export const OtherInfo = () => {
  const {
    step,
    setStep,
    rank,
    businessCategoryIDS,
    deliveryRangeDistance,
    businessCategories,
    statusCodeID,
    delieveryFeeID,
    deliveryDistanceID,
    serviceFeeID,
    maxamount,
    minamount,
    setMaxamount,
    setMinamount,
    setRank,
    setStatusCodeID,
    setBusinessCategoryIDS,
    setDeliveryRangeDistance,
    setdDelieveryfeeid,
    setDeliveryDistanceID,
    setServiceFeeID,
    commission,
    commissionType,
    id,
  } = useVendor();

  const { vendorData } = useDataStore();

  const [form] = Form.useForm();
  const [catSearch, setCatSearch] = useState("");
  const [feeSearch, setFeeSearch] = useState("");
  const [servicFeeSearch, setServiceFeeSearch] = useState("");

  const {
    isLoading: feeLoading,
    error: feeError,
    data: feeList,
  } = useQuery(["deliverFee", feeSearch], getFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const {
    isLoading: ServiceFeeLoading,
    error: ServiceFeeError,
    data: ServiceFeeList,
  } = useQuery(["serviceFee", servicFeeSearch], getServiceFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const {
    isLoading: DistanceLoading,
    error: DistanceError,
    data: DistanceList,
  } = useQuery("deliverDistance", getDistance, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const {
    isLoading: catLoading,
    error: catError,
    data: catList,
  } = useQuery(["category", catSearch], getCategories, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: statusLoading,
    error: statusError,
    data: statusList,
  } = useQuery("statusCodes", getStatus, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (catList && catList.success) {
      businessCategories?.map((Item) => {
        let cuurentList = catList?.data?.records?.find(
          (el) => el.id == Item.id
        );
        if (!cuurentList) {
          catList?.data?.records?.push({ id: Item.id, name: Item.name });
        }
      });
    }
  }, [catList]);

  const onFinish = (values) => {
    setDeliveryRangeDistance(values.deliveryRangeDistance);
    setStep(step + 1);
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        businessCategoryIDS: businessCategories
          ? businessCategories.map((el) => el.id)
          : [],
        rank: vendorData?.rank,
        deliveryRangeDistance: deliveryRangeDistance,
        statusCodeID: vendorData?.statusCodeID,
        delieveryFeeID: vendorData?.delieveryFeeID,
        deliveryDistanceID: vendorData?.deliveryDistanceID,
        commission: vendorData?.commission,
        commissionType: vendorData?.commissionType,
        maxamount: vendorData?.maxamount,
        serviceFeeID: vendorData?.serviceFeeID,
        minamount: vendorData?.minamount,
      });
    } else {
      form.resetFields();
    }
  }, [vendorData]);

  return (
    <div className="app-flex-column">
      <br />
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        // initialValues={{
        //   remember: true,
        //   businessCategoryIDS,
        //   rank,
        //   deliveryRangeDistance,
        //   statusCodeID,
        //   delieveryFeeID,
        //   deliveryDistanceID,
        //   commission,
        //   serviceFeeID,
        //   commissionType,
        //   maxamount,
        //   minamount,
        // }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Vendor Type"
              name="businessCategoryIDS"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => {
                  setBusinessCategoryIDS(val.map((el) => el.value));
                }}
                mode="multiple"
                showArrow
                maxTagCount="responsive"
                loading={catLoading}
                error={catError}
                placeholder="Choose Vendor type"
                showSearch
                labelInValue
                filterOption={false}
                onSearch={(val) => {
                  setCatSearch(val);
                }}
              >
                {catList
                  ? catList?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el?.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item
              label="Vendor State"
              name="statusCodeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setStatusCodeID(val)}
                loading={statusLoading}
                error={statusError}
                placeholder="Choose Status"
              >
                {statusList && statusList.success
                  ? statusList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Delivery Range Distance"
              name="deliveryRangeDistance"
              rules={[{ required: true }]}
            >
              <InputNumber
                // formatter={(value) => inputNumberFormatter(value)}
                // parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                onChange={(val) => setDeliveryRangeDistance(val)}
                style={{ width: "100%" }}
                addonAfter={"meters"}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Delivery Type"
              name="delieveryFeeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setdDelieveryfeeid(val)}
                loading={feeLoading}
                error={feeError}
                placeholder="Choose Delivery Type"
                onSearch={(v) => {
                  setFeeSearch(v);
                }}
                showSearch
                searchValue={feeSearch}
                allowClear
                filterOption={false}
              >
                {feeList
                  ? feeList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Service Fee"
              name="serviceFeeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setServiceFeeID(val)}
                loading={feeLoading}
                error={feeError}
                placeholder="Choose Service Fee"
                onSearch={(v) => {
                  setServiceFeeSearch(v);
                }}
                showSearch
                searchValue={servicFeeSearch}
                allowClear
                filterOption={false}
              >
                {ServiceFeeList
                  ? ServiceFeeList?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el?.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Rank" name="rank" rules={[{ required: true }]}>
              <Select
                onChange={(val) => setRank(val)}
                placeholder="Choose Vendor type"
              >
                <Option value="A">A</Option>
                <Option value="B">B</Option>
                <Option value="C">C</Option>
                <Option value="D">D</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              label="Delivery Distance"
              name="deliveryDistanceID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setDeliveryDistanceID(val)}
                loading={DistanceLoading}
                error={DistanceError}
                placeholder="Choose Delivery Distance"
              >
                {DistanceList
                  ? DistanceList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Max Amount"
              name="maxamount"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                onChange={(val) => setMaxamount(val)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Min Amount"
              name="minamount"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                onChange={(val) => setMinamount(val)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24} className="resturant-constroll-form">
            <Divider />
            <Space>
              <Button onClick={() => setStep(step - 1)}>Back</Button>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
