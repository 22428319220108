import create from "zustand";

export const useDeliveryDistance = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  domains: null,
  name: null,
  description: null,
  id: null,


  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setDomains: (domains) => set({ domains }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
