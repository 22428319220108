import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Upload,
  InputNumber,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { PureMapDrow } from "./mapDrow";
import { useQuery, useMutation } from "react-query";
import {
  createBanner,
  editeBanner,
  getVendors,
  getAppBannerEdit,
} from "../../api";
import moment from "moment";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { CustomeCalender } from "../../comonents/global/customeCalender";
import handleDataMutation from "../../helper/dataMutation";
import { inputNumberFormatter } from "../../helper/inputNumberFormatter";
const { Title } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

const SortableItem = SortableElement(({ value }) => <li>{value}</li>);
const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </ul>
  );
});

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    polygon,
    setPolygon,
    setPriorty,
    setQueryKey,
    setId,
    setName,
    setType,
    setImg,
    id,
    setStartDate,
    setEndDate,
    setAppBannerProduct,
    setDescription,
    workingSchedule,
    setSchedule,
    setVendorID,
  } = useAppBanner();


  const [form] = Form.useForm();

  const [vendorSearch, setvendorSearch] = useState("");

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const resetState = () => {
    setPolygon(null);
    setPriorty(null);
    setQueryKey(null);
    setId(null);
    setName(null);
    setType(null);
    setImg(null);
    setStartDate(null);
    setEndDate(null);
    setAppBannerProduct([]);
    setDescription(null);
    setSchedule(null);
    setVendorID(null);
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(createBanner, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "appBanner",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editeBanner,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "appBanner",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { isLoading: isvendorLoading, data: vendors } = useQuery(
    ["vendors", vendorSearch, 1],
    getVendors,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
  
  const { isLoading: isEditData, data: editData } = useQuery(
    [id],
    getAppBannerEdit,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );


  const onFinish = (values) => {
    if (polygon) {
      if (id) {
        let dataForm = {
          id,
          name: values.name,
          priority: values.priority,
          type: values.type,
          vendorID: values.vendorID,
          polygon,
          startDate: values.startDate,
          endDate: values.endDate,
          users: "[]",
          description: values.description,
          workingSchedule: workingSchedule
            ? JSON.stringify(workingSchedule)
            : "[]",
        };
        editMutate(dataForm);
      } else {
        let dataForm = {
          imgFile: values.img.file,
          data: JSON.stringify({
            appBanner_product: [],
            name: values.name,
            priority: values.priority,
            type: values.type,
            vendorID: values.vendorID,
            polygon,
            startDate: values.startDate,
            endDate: values.endDate,
            description: values.description,
            workingSchedule: workingSchedule
              ? JSON.stringify(workingSchedule)
              : "[]",
          }),
        };
        mutate(dataForm);
      }
    } else {
      message.error("Polygon Not found ");
    }
  };

  useEffect(() => {
    if (id) {
      const {
        name,
        description,
        type,
        priority,
        workingSchedule,
        startDate,
        endDate,
        vendorID,
        polygon
      } = editData?.data;

      form.setFieldsValue({
        name,   
        type,
        priority,
        description,
        vendorID,
        startDate: startDate ? moment(startDate) : null,
        endDate: endDate ? moment(endDate) : null,
        schedule: JSON.parse(workingSchedule),
      });
      setPolygon(polygon)
    } else {
      form.resetFields();
      resetState();
    }
  }, [editData?.data]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} App Banner</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={880}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <PureMapDrow />
          </Col>
          <Col span={10}>
            <Row gutter={[20, 20]}>
              <Col span={16}>
                <Form.Item
                  label="Banner Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Banner title" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Priority"
                  name="priority"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Banner Description"
                  name="description"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="Banner Description" />
                </Form.Item>
              </Col>
              {!id && (
                <Col span={24}>
                  <Form.Item
                    label="Banner Type"
                    name="type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Option value={"CAROUSEL"}>CAROUSEL</Option>
                      <Option value={"LIST"}>LIST</Option>
                      <Option value={"SLIDER"}>SLIDER</Option>
                      <Option value={"BANNER"}>BANNER</Option>
                      <Option value={"CATEGORY"}>CATEGORY</Option>
                      <Option value={"NEW"}>NEW</Option>
                      <Option value={"WEEKLY_DISCOUNT"}>WEEKLY_DISCOUNT</Option>
                      <Option value={"BEST_SELLER"}>BEST_SELLER</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="vendor"
                  name="vendorID"
                  rules={[{ required: true }]}
                >
                  <Select
                    filterOption={false}
                    notFoundContent={
                      isvendorLoading ? (
                        <div className="spin-wrapper">
                          <Spin size="small" />
                        </div>
                      ) : null
                    }
                    allowClear
                    showSearch
                    onSearch={(val) => setvendorSearch(val)}
                    placeholder="Select vendor"
                    style={{ width: "100%" }}
                  >
                    {vendors &&
                      vendors.success &&
                      vendors.data.records.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.arName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {!id ? (
                <Col span={24}>
                  <Form.Item
                    label="Image"
                    name="img"
                    rules={[{ required: true }]}
                  >
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>

                      <p className="ant-upload-hint">
                        Click or drag Image to this area
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Schedule"
              name="schedule"
              rules={[{ required: true, message: "schedule is required" }]}
            >
              <CustomeCalender
                value={id ? workingSchedule : null}
                onChange={(val) => setSchedule(val)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
