import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import { useOrderView } from "../../store";
import "leaflet/dist/leaflet.css";
import deviceLogo from "../../assets/images/map-device.png";
import userLogo from "../../assets/images/map-user.png";
import vendorLogo from "../../assets/images/map-vendor.png";
import { Icon } from "leaflet";
export const PureMap = () => {
  const { order } = useOrderView();

  const [CenterPostion, setCenterPostion] = useState([33.312805, 44.361488]);
  const [clientAddress, setClientAddress] = useState(null);
  const [clientLocation, setClientLocation] = useState(null);
  const [vendorLocation, setVendorLocation] = useState(null);

  useEffect(() => {
    if (order && order?.order) {
      setClientAddress([order?.order?.clientLon, order?.order?.clientLat]);
      setClientLocation([
        order?.order?.currenClientLon,
        order?.order?.currenClientLat,
      ]);
      setVendorLocation([order?.order?.vendorLon, order?.order?.vendorLat]);
    }
  }, [order]);

  const reverseCords = (cords) => {
    if (cords != null) {
      return cords.reverse();
    }
  };

  const getCenterCords = (cords1, cords2) => {
    if (cords1 != null && cords2 != null) {
      const [lat1, lng1] = cords1;
      const [lat2, lng2] = cords2;
      return [(lat1 + lat2) / 2, (lng1 + lng2) / 2];
    }
  };

  const MapComponent = () => {
    const map = useMap();
    useEffect(() => {
      if (CenterPostion != null) {
        map.setView(CenterPostion, map.getZoom());
      }
    }, [CenterPostion]);
    return null;
  };

  useEffect(() => {
    if (vendorLocation != null && clientAddress != null) {
      setCenterPostion(getCenterCords(clientAddress, vendorLocation));
    }
  }, [vendorLocation, clientAddress]);

  return (
    <MapContainer
      center={CenterPostion}
      zoomControl={false}
      zoom={15}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "38vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      {clientAddress && (
        <Marker
          position={reverseCords(clientAddress)}
          icon={
            new Icon({
              iconUrl: userLogo,
              iconSize: [50, 50],
            })
          }
        />
      )}
      {clientLocation && (
        <Marker
          position={reverseCords(clientLocation)}
          icon={
            new Icon({
              iconUrl: deviceLogo,
              iconSize: [50, 50],
            })
          }
        />
      )}
      {vendorLocation && (
        <Marker
          position={reverseCords(vendorLocation)}
          icon={
            new Icon({
              iconUrl: vendorLogo,
              iconSize: [50, 50],
            })
          }
        />
      )}

      <MapComponent />
    </MapContainer>
  );
};
