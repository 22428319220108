import { Select ,Card, Input,Breadcrumb } from "antd";
import { PureTable } from "./table";
import { useReviews } from "./store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;
export const ProductsReviewPage = () => {
  const { setQueryKey, queryKey,status,setStatus } = useReviews();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);


  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }if(searchParams.get("status")){
      setStatus(searchParams.get("status"));
    }else{
      setStatus(null);
    }
  }, [searchParams.get("q")],searchParams.get("status"));

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Customers Rate</Breadcrumb.Item>
        <Breadcrumb.Item>Products Review</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div>
          <Search 
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
            }}
            style={{ width: 340 }}
            placeholder="Write To Search..."
          />
           <Select
            allowClear
            onChange={(val) => {
              if (val != undefined) {
                setStatus(val);
                searchParams.set("status", val);
                setSearchParams(searchParams);
              }
            }}
            onClear={() => { 
              setStatus(null);
              searchParams.delete("status");
              setSearchParams(searchParams);
            }}
            placeholder="Select Status"
            style={{ width: 340 }}
            value={status}
          >
            <Option value={"PENDING"}>Pending</Option>
            <Option value={"REJECTED"}>Rejected</Option>
            <Option value={"ACCEPTED"}>Accepted</Option>
          </Select>
        </div>
        <br />
        <PureTable />
      </Card>
    </div>
  );
};
