import { Card, Input, Space, Breadcrumb } from "antd";
import { useReferalCodes } from "./store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PuerTable } from "./table";
import ReferalCodesFilters from "../../comonents/Filters/referalCodesFilters";

const { Search } = Input;

export const ReferalCodesPage = () => {
  const { setQueryKey, queryKey } = useReferalCodes();

  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Referal Codes List</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 250 }}
              placeholder="Search ..."
            />
            <ReferalCodesFilters />
          </Space>
        </div>
        <br />
        <PuerTable />
      </Card>
    </div>
  );
};
