import { useMemo } from "react";
import { Table } from "antd";
let url =
  process.env.REACT_APP_IS_DEV === "true" ? "app.baly.dev" : "app.food.baly.iq";

const dataKeys = [
  {
    id: 1,
    key: "VoucherCode",
    description: "رمز التخفيض",
  },
  {
    id: 2,
    key: "FirstName",
    description: "الاسم الاول",
  },
  {
    id: 3,
    key: "LastName",
    description: "الاسم الاخير",
  },
  {
    id: 4,
    key: "Phone",
    description: "رقم الهاتف",
  },
  {
    id: 5,
    key: "EndDate",
    description: "تاريخ الانتهاء",
  },
  {
    id: 6,
    key: "DeepLink",
    description: `baly://open/services/50?${url}?voucher_code=%v&token=$TOKEN&location=true`,
  },
];

export const KeysTable = () => {
  const columns = useMemo(
    () => [
      {
        title: "Key",
        dataIndex: "key",
      },
      {
        title: "Description",
        dataIndex: "description",
      },
    ],
    []
  );

  return (
    <Table
      size={"small"}
      columns={columns}
      dataSource={dataKeys}
      bordered
      pagination={false}
      scroll={{
        y: 430,
      }}
    />
  );
};
