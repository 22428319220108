import { useState, useEffect, useMemo } from "react";
import { Table, Typography, TimePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = TimePicker;
const initialData = [
  {
    key: 1,
    day: "Sunday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
  {
    key: 2,
    day: "Monday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
  {
    key: 3,
    day: "Tuesday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
  {
    key: 4,
    day: "Wednesday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
  {
    key: 5,
    day: "Thursday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
  {
    key: 6,
    day: "Friday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
  {
    key: 7,
    day: "Saturday",
    startTime: dayjs().set("hour", 0).set("minute", 0).toDate(),
    endTime: dayjs().set("hour", 23).set("minute", 59).toDate(),
  },
];

const { Text } = Typography;
export const CustomeCalender = ({ value = [], onChange }) => {
  const [selected, setSelected] = useState([]);
  const [dataList, setDataList] = useState(initialData);

  const parseList = (list) => {
    if (!list) return [];
    return list.filter(
      (el) => el.startTime !== "00:00" && el.endTime !== "00:00"
    );
  };

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (value && parseList(value).length > 0) {
      setSelected(value);
      let newList = [];
      dataList?.map((el) => {
        let selectedItem = parseList(value)?.find(
          (item) => item.key === el.key
        );
        if (selectedItem) {
          newList.push(selectedItem);
        } else {
          newList.push(el);
        }
      });
      setDataList(newList);
    }
  }, [value]);

  const handleChange = (row, val) => {
    row.startTime = val[0]
      ? dayjs(val[0]).toDate()
      : dayjs().set("hour", 0).set("minute", 0).toDate();
    row.endTime = val[1]
      ? dayjs(val[1]).toDate()
      : dayjs().set("hour", 0).set("minute", 0).toDate();
    let newData = [...dataList.map((el) => (el.key == row.key ? row : el))];
    setDataList(newData);
  };

  const columns = useMemo(() => [
    {
      title: "Day",
      dataIndex: "day",
      render: (text) => (
        <Text style={{ textTransform: "uppercase" }}>{text}</Text>
      ),
    },
    {
      title: "Time",
      key: "time",
      render: (row) => (
        <RangePicker
          value={
            row.startTime !== null
              ? [dayjs(row.startTime), dayjs(row.endTime)]
              : []
          }
          onChange={(val) => handleChange(row, val)}
          bordered={false}
          format={"hh:mm A"}
          size="small"
          allowClear={false}
        />
      ),
    },
  ]);

  const rowSelection = {
    selectedRowKeys: parseList(selected)?.map((el) => el.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
  };

  return (
    <div className="custome-calender">
      <Table
        size={"small"}
        columns={columns}
        dataSource={dataList}
        bordered
        pagination={false}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    </div>
  );
};
