import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
  Select,
  Input,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useVendorProductVariation } from "./store";
import { useMutation, useQuery } from "react-query";

import {
  createVendorProductVariation,
  editVendorProductVariation,
  getProductVariation,
} from "../../api";
import { useParams } from "react-router-dom";
import { useState } from "react";
import handleDataMutation from "../../helper/dataMutation";
import { inputNumberFormatter } from "../../helper/inputNumberFormatter";
const { Option } = Select;

const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    price,
    setPrice,
    availableQty,
    setAvailableQty,
    priority,
    setPriority,
    productVariationID,
    setProductVariationID,
    barcode,
    setBarcode,
    limit,
    setLimit
  } = useVendorProductVariation();

  const { vendorID } = useParams();
  const [productSearch, setProductSearch] = useState("");

  const resetState = () => {
    setId(null);
    setPrice(null);
    setAvailableQty(null);
    setPriority(null);
    setProductVariationID(null);
    setBarcode(null);
    setLimit(null);
  };

  const { mutate, isLoading } = useMutation(createVendorProductVariation, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendorProductVariation",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorProductVariation,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendorProductVariation",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );
  const { isLoading: productLoading, data: productVariation } = useQuery(
    ["productVariation", productSearch, 1, "", 1000],
    getProductVariation,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      values.vendorID = Number(vendorID);
     
      editMutate(values);
    } else {
      values.vendorID = Number(vendorID);
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Vendor Product</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          price,
          availableQty,
          priority,
          productVariationID,
          barcode,
          limit
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Product"
              name="productVariationID"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={false}
                onSearch={setProductSearch}
                placeholder="Choose a Product"
                loading={productLoading}
              >
                {productVariation && productVariation.success
                  ? productVariation?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {`${el?.product} ${
                          el?.name || ` ${el?.size} ${el?.unit || ""}`
                        }`}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Available Qty"
              name="availableQty"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="product variation Qty"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Limit"
              name="limit"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="product variation priority"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Price" name="price" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                placeholder="product variation price"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="barcode"
              name="barcode"
              rules={[{ required: true }]}
            >
              <Input
                min={0}
                style={{ width: "100%" }}
                placeholder="product variation barcode"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
