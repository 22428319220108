import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { createVendorUser, editVendorUser, getVendors } from "../../api";
import { useVendorUser } from "./store";
import { phoneValidate } from "../../helper/phoneValidation";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const [filter, setFilter] = useState("");
  const [vendorList, setvendorList] = useState([]);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phoneState, setPhoneState] = useState(null);

  const {
    isCreateModal,
    setIsCreateModal,
    name,
    username,
    id,
    email,
    phone,
    vendorID,
    roleID,
    setEmail,
    setName,
    setPassword,
    setPhone,
    setUsername,
    setId,
    setVendorName,
    vendorName,
  } = useVendorUser();
  const [form] = Form.useForm();

  const {
    isLoading: vendorLoading,
    data: vendors,
    refetch,
  } = useQuery(["vendors", filter], getVendors, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (vendors && vendors?.success) {
      let cuurentList = vendors?.data?.records.find((el) => el.id === vendorID);
      if (cuurentList) {
        setvendorList(vendors?.data?.records);
      } else {
        setvendorList([
          ...vendors.data.records,
          { id: vendorID, arName: vendorName },
        ]);
      }
    }
  }, [vendors, vendorID]);

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const { mutate, isLoading } = useMutation(createVendorUser, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendorUsers",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorUser,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendorUsers",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });

      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.status = true;
    values.roleID = 1;
    values.authGroupID = 1;
    if (
      id &&
      phoneValidate(phoneState) &&
      phoneValidate(phoneState)?.index === 0
    ) {
      values.phone = phoneState;
      values.id = id;
      editMutate(values);
    } else if (
      phoneValidate(phoneState) &&
      phoneValidate(phoneState)?.index === 0
    ) {
      values.phone = phoneState;
      mutate(values);
    } else setPhoneValidation(true);
  };

  let resetState = () => {
    setEmail(null);
    setName(null);
    setPassword(null);
    setPhone(null);
    setUsername(null);
    setId(null);
    setVendorName(null);
    setFilter("");
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        username,
        email,
        phone,
        roleID,
        vendorID,
      });
    } else {
      form.resetFields();
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Vendor User</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
        setFilter("");
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Baly Food" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true }]}
            >
              <Input placeholder={"baly001"} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={"07**** ****"}
                status={phoneValidation ? "error" : ""}
                maxLength="11"
                minLength="11"
                onChange={(e) => setPhoneState(e.target.value)}
                value={phoneState}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
              <Input placeholder={"example@baly.iq"} />
            </Form.Item>
          </Col>

          {!id ? (
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input placeholder="******" />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <Form.Item
              label="vendor"
              name="vendorID"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                loading={vendorLoading}
                optionFilterProp="children"
                onSearch={(val) => setFilter(val)}
                style={{ width: "100%" }}
                placeholder="Find vendor"
              >
                {vendorList.map((el) => (
                  <Option key={el.id} id={el.id} value={el.id}>
                    {el.arName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
