import create from "zustand";

export const useMsgTemplate = create((set) => ({
  isCreateModal: false,
  msg: null,
  config: null,
  statusCodeID: null,
  deepLink: null,
  id: null,
  queryKey: "",
  
  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setMsg: (msg) => set({ msg }),
  setConfig: (config) => set({ config }),
  setStatusCodeID: (statusCodeID) => set({ statusCodeID }),
  setDeepLink: (deepLink) => set({ deepLink }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
