import create from "zustand";

export const useChain = create((set) => ({
    isCreateModal: false,
    isLinkModal: false,
    id: null,
    arName: null,
    description: "",
    vendors: null,
    queryKey: "",
    sync: false,
    vendorID: null,

    setQueryKey: (queryKey) => set({ queryKey }),
    setId: (id) => set({ id }),
    setArName: (arName) => set({ arName }),
    setDescription: (description) => set({ description }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
    setVendors: (vendors) => set({ vendors }),
    setVendorID: (vendorID) => set({ vendorID }),
    setSync: (sync) => set({ sync }),
    setIsLinkModal: (isLinkModal) => set({ isLinkModal }),
}));
