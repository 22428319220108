import { Excel } from "antd-table-saveas-excel";

export const exportExcel = (excelData, columns, { sheet, fileName }) => {
  const excel = new Excel();
  excel
    .addSheet(sheet)
    .addColumns(columns)
    .addDataSource(
      excelData && excelData?.success ? excelData?.data?.records : [],
      {
        str2Percent: true,
      }
    )
    .saveAs(`${fileName}.xlsx`);
};
