import { message } from "antd";

const copy = require("clipboard-copy");

export const copyLink = (val) => {
  let successPromise = copy(val);
  if (successPromise) {
    message.success("Copy to clipboard");
  } else {
    message.error("Faild Copy !");
  }
};
