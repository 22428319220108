import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import PasswordStrengthBar from "react-password-strength-bar";
import { useEffect, useState } from "react";
import { useApp } from "../../store";
import { resetPassword } from "../../api";
import { useLocation } from "react-router-dom";
const { Title } = Typography;

export const ResetPassword = () => {
  const { isReset, setIsReset, user, setUser, setIsLogin, setIsQrModal } =
    useApp();
  const [passScore, setPassScore] = useState(0);
  const [confirmErr, setConfirmErr] = useState("validating");
  const [pass, setPass] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(0);
  const location = useLocation();

  const handleReset = (data) => {
    setLoading(true);
    resetPassword(data, (res, err) => {
      if (!err) {
        if (res.success) {
          setIsReset(false)
          setLoading(false);
          setIsLogin(false);
          setUser(null);
          resetFileds();
          localStorage.clear("groceryBO_token");
          setIsQrModal(false);
        } else {
          setLoading(false);
          message.error(res.err);
        }
      } else {
        setLoading(false);
        message.error(err.message);
      }
    });
  };

  const resetFileds = () => {
    if (location?.pathname !== "/login") {
      setConfirmErr("validating");
      setPassScore(0);
      setPass("");
      setIsReset(false);
      form.resetFields();
    }
  };

  const onFinish = (values) => {
    if (form.getFieldValue("newPassword") !== form.getFieldValue("conPassword"))
      setConfirmErr("error");
    else {
      handleReset(values);
    }
  };

  const checkPassword = (password) => {
    return /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])(?=.*\W)(?=.*\d))(?=.*[@#$%^&+=])|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/.test(
      password
    )
      ? password
      : "";
  };

  useEffect(() => {
    if (isReset && user) {
      form.setFieldsValue({ username: user.username });
    }
  }, [isReset]);

  return (
    <Modal
      title={<Title level={5}>Reset Password</Title>}
      className="app-modal"
      centered
      visible={isReset}
      onCancel={() => {
        resetFileds();
      }}
      footer={false}
      destroyOnClose={location?.pathname !== "/login" ? true : false}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "required" }]}
              style={{ marginBottom: 10 }}
            >
              <Input placeholder="*****" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[{ required: true, message: "required" }]}
              style={{ marginBottom: 10 }}
            >
              <Input placeholder="*****" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "required" }]}
              style={{ marginBottom: 10 }}
            >
              <Input
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder="*****"
              />
            </Form.Item>
            <PasswordStrengthBar
              password={checkPassword(pass)}
              onChangeScore={setPassScore}
            />
          </Col>

          <Col span={24}>
            <Form.Item
              label="Confirm Password"
              name="conPassword"
              validateStatus={confirmErr}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (value == form.getFieldValue("newPassword")) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Passowrd uncorrect!");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="*****" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Otp"
              name="otp"
              rules={[{ required: true, message: "required" }]}
            >
              <Input placeholder="otp code" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                disabled={passScore < 3}
                loading={loading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
