import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Space } from "antd";
import { useAppBanner } from "./store";

export const PureMap = () => {
  const { setLat, setLon, lat, lon } = useAppBanner();
  const [markerLocation, setMarkerLocation] = useState(null);

  const MapComponent = () => {
    useMapEvents({
      click: (e) => {
        setLat(e.latlng.lat);
        setLon(e.latlng.lng);
      },
    });

    return null;
  };
  useEffect(() => {
    if (lat != null && lon != null) {
      setMarkerLocation([lat, lon]);
    }
  }, [lat, lon]);

  return (
    <div style={{ position: "relative" }}>
      <Space style={{ position: "absolute", zIndex: 100, padding: 15 }}></Space>
      <MapContainer
        center={lon != null && lat != null ? [lat, lon] : [33.322847, 44.32272]}
        zoomControl={false}
        zoom={15}
        scrollWheelZoom={true}
        attributionControl={false}
        style={{ height: "85vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
        />
        {markerLocation && (
          <Marker
            position={markerLocation}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          />
        )}
        <MapComponent />
      </MapContainer>
    </div>
  );
};
