import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  message,
  Select,
  Card,
  Space,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import {
  categoryToBanner,
  getBannerCategory,
  getAppBannerEdit,
} from "../../api";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Avatar from "antd/lib/avatar/avatar";
import { arrayMoveImmutable } from "array-move";
const { Title, Text } = Typography;
const { Option } = Select;

export const AddCategories = () => {
  const { id, setIsAddCat, isAddCat, categories, type, setId, setCategories } =
    useAppBanner();
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("");

  const CategoryItem = SortableElement(({ value }) => (
    <Card
      bordered={false}
      bodyStyle={{ padding: "10px 16px", backgroundColor: "#f6f6f6" }}
      style={{ marginBottom: 10 }}
    >
      <div className="app-space-between">
        <Space size={16}>
          <Avatar size="large" src={value.image} />
          <Space direction="vertical" size={0}>
            <Text>{value.name}</Text>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {value.description}
            </Text>
          </Space>
        </Space>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setList(list?.filter((el) => el.id != value.id));
          }}
          icon={<DeleteOutlined />}
          size="small"
          type="text"
          danger
        />
      </div>
    </Card>
  ));

  const SortableList = SortableContainer(({ items = [] }) => {
    return (
      <div>
        {items.map((value, index) => (
          <CategoryItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const {
    isLoading: categorieLoading,
    data,
    refetch,
  } = useQuery(["categories", filter, 1, 10, "", type], getBannerCategory, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!type,
  });

  useEffect(() => {
    if (isAddCat) {
      refetch();
    }
  }, [id]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    categoryToBanner,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "appBanner",
          setterList: [() => setIsAddCat(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  let onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMoveImmutable(list, oldIndex, newIndex));
  };

  const isRepeated = (id) => (list?.find((el) => el.id == id) ? true : false);

  const handleSave = () => {
    let dataForm = {
      category: list?.map((el, index) => {
        return { id: el.id, priority: index + 1 };
      }),
      id,
    };

    editMutate(dataForm);
  };

  const resetState = () => {
    setId(null);
    setCategories([]);
  };

  const { isLoading: isEditData, data: editData } = useQuery(
    [id],
    getAppBannerEdit,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (id) {
      setList(editData?.data?.category || []);
    }
  }, [id, editData?.data]);

  
  return (
    <Modal
      title={<Title level={5}>Category to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddCat}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddCat(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Select
            showSearch
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            onChange={(val) => {
              if (val) {
                if (type == "BANNER") {
                  setList([data?.data?.find((el) => el.id == val)]);
                } else {
                  setList([...list, data?.data?.find((el) => el.id == val)]);
                }
              }
            }}
            style={{ width: "100%" }}
            placeholder="Find categorie"
            allowClear
          >
            {!categorieLoading ? (
              data?.success ? (
                data?.data?.map((el) => (
                  <Option
                    key={el.id}
                    id={el.id}
                    value={el.id}
                    disabled={isRepeated(el.id)}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>

        <Col span={24}>
          <div style={{ minHeight: 400, maxHeight: "70vh", overflow: "auto" }}>
            <SortableList distance={1} items={list} onSortEnd={onSortEnd} />
          </div>
        </Col>
        <Col span={24}>
          <Button
            loading={editLoading}
            type="primary"
            onClick={handleSave}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
