import React from "react";
import { FrownOutlined } from "@ant-design/icons";
import "./style.css";

export class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleClick = this.handleClick.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  handleClick() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundry">
          <div>
            <FrownOutlined />
          </div>
          <b>نأسف لهذا الخلل الفني!</b>
          <small>
            قد تكون مشكله تقنية من المصدر سوف نعمل على حلها بأسرع وقت ممكن.
          </small>
          <a onClick={this.handleClick}>اعادة المحاولة</a>
        </div>
      );
    }

    return this.props.children;
  }
}
