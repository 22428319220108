import { useEffect, useMemo } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Menu,
  Dropdown,
  Pagination,
  Switch,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  SwapRightOutlined,
  DownOutlined,
  AppstoreOutlined,
  UserOutlined,
  EnvironmentOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import {
  getVouchers,
  removeVoucher,
  editVoucherStatus,
  pageSize,
} from "../../api";
import dayjs from "dayjs";
import moment from "moment";
import { useDiscount } from "../../store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

import { copyLink } from "../../helper/copyLink";

const { Text } = Typography;
export const PureTable = () => {
  const {
    queryKey,
    setEndDate,
    setStartDate,
    setVoucherTitle,
    setValueType,
    setTotalQty,
    setPlatform,
    setMaxAmount,
    setNoOfUsage,
    setDayOfWeek,
    setMinAmount,
    setId,
    setFisrtOrderOnly,
    setCode,
    setNewUsersOnly,
    setUDID,
    setApplyOverDiscount,
    setIsCreateModal,
    setIsVendorsModal,
    setIsUsersModal,
    setIsCitiesModal,
    setIsCategoriesModal,
    setSchedule,
    setTotalValue,
    setBalyValue,
    setVendorValue,
    setAvToCustomers,
    queryFilter,
    setPage,
    page,
    setCustomerDesc,
    active,
    from,
    to,
    setVoucherType,
  } = useDiscount();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handlEdit = (row) => {
    setEndDate(moment(row.endDate));
    setStartDate(moment(row.startDate));
    setVoucherTitle(row.voucherTitle);
    setValueType(row.valueType);
    setTotalQty(row.totalQty);
    setPlatform(JSON.parse(row.platform));
    setDayOfWeek(row.dayOfWeek);
    setMinAmount(row.minAmount);
    setMaxAmount(row.maxAmount);
    setNoOfUsage(row.noOfUsage);
    setId(row.id);
    setFisrtOrderOnly(row.fisrtOrderOnly);
    setNewUsersOnly(row.newUsersOnly);
    setUDID(row.UDID);
    setAvToCustomers(row.avToCustomers);
    setApplyOverDiscount(row.applyOverDiscount);
    setCode(row.code);
    setBalyValue(row.balyValue);
    setTotalValue(row.totalValue);
    setVendorValue(row.vendorValue);
    setCustomerDesc(row.customerDesc);
    setIsCreateModal(true);
    setVoucherType(row.voucherType);
  };

  const handleModal = (e, row) => {
    setId(row.id);

    switch (e.key) {
      case "vendors":
        setIsVendorsModal(true);
        break;
      case "users":
        setIsUsersModal(true);
        break;
      case "cities":
        setIsCitiesModal(true);
        break;
      case "categories":
        setIsCategoriesModal(true);
        break;

      default:
        break;
    }
  };

  const menu = (row) => (
    <Menu onClick={(e) => handleModal(e, row)}>
      {authFunc("USER_UPDATE") && (
        <Menu.Item key="users" icon={<UserOutlined />}>
          <Tooltip placement="left" title="add users for this discount">
            Users
          </Tooltip>
        </Menu.Item>
      )}
      {authFunc("VENDOR_USER_UPDATE") && (
        <Menu.Item key="vendors" icon={<ShopOutlined />}>
          <Tooltip placement="left" title="add Vendors for this discount">
            Vendors
          </Tooltip>
        </Menu.Item>
      )}

      {authFunc("AREA_UPDATE") && (
        <Menu.Item key="cities" icon={<EnvironmentOutlined />}>
          <Tooltip placement="left" title="add Cities for this discount">
            Cities
          </Tooltip>
        </Menu.Item>
      )}
      {authFunc("CATEGORY_UPDATE") && (
        <Menu.Item key="categories" icon={<AppstoreOutlined />}>
          <Tooltip placement="left" title="add Categories for this discount">
            Categories
          </Tooltip>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Title",
        dataIndex: "voucherTitle",
        key: "voucherTitle",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },
      {
        title: "Customer Description",
        dataIndex: "customerDesc",
        key: "customerDesc",
        render: (text) => <small>{text}</small>,
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (text) => (
          <Space>
            <Text style={{ fontStyle: "italic", fontSize: 16 }}>{text}</Text>
            <Tooltip placement="top" title="copy this code">
              <Button
                onClick={() => copyLink(text)}
                size="small"
                type="text"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
      {
        title: "No. Usage",
        dataIndex: "noOfUsage",
        key: "noOfUsage",
        render: (noOfUsage) => Number(noOfUsage).toLocaleString(),
      },
      {
        title: "Qt",
        dataIndex: "totalQty",
        key: "totalQty",
        render: (totalQty) => Number(totalQty).toLocaleString(),
      },
      {
        title: "Range Value",
        key: "id",
        render: (row, val) => (
          <Tag>
            {Number(row.minAmount).toLocaleString()} <SwapRightOutlined />{" "}
            {Number(row.maxAmount).toLocaleString()} <b>IQD</b>
          </Tag>
        ),
      },
      {
        title: "Total Value",
        dataIndex: "totalValue",
        key: "totalValue",
        render: (val, row) => (
          <Tag>
            <b>
              {row.valueType == "AMOUNT"
                ? Number(val).toLocaleString() + " IQD"
                : val + " %"}{" "}
            </b>
          </Tag>
        ),
      },
      {
        title: "Voucher Type",
        dataIndex: "voucherType",
        key: "valueType",
        render: (val) => <Tag color={"purple"}>{val}</Tag>,
      },
      {
        title: "Platforms",
        dataIndex: "platform",
        key: "platform",
        render: (val) =>
          val
            ? Array.isArray(JSON.parse(val))
              ? JSON.parse(val).map((el) => (
                  <Tag key={el} color="geekblue">
                    {el}
                  </Tag>
                ))
              : val
            : "...",
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        render: (text) => (
          <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
        ),
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        render: (text) => (
          <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            loading={editLoading}
            checked={row.active}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },

      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id, row) => (
          <Space size="small">
            <Dropdown overlay={menu(row)}>
              <Button size="small" type="link">
                Add To <DownOutlined />
              </Button>
            </Dropdown>
            {authFunc("VOUCHER_UPDATE") && (
              <Button
                onClick={() => handlEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("VOUCHER_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((val) => removeVoucher(val), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vouchers",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["vouchers", queryKey, page, active, from, to], getVouchers, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVoucherStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vouchers",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      editMutate({
        id: row.id,
        status: !row.active,
      });
    }
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
