import { useState, useEffect, useMemo } from "react";
import {
  Table,
  Switch,
  Space,
  Button,
  Typography,
  message,
  Alert,
  Popconfirm,
  Pagination,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import { getUsers, removeUser, editUser, pageSize } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useEmployee } from "./store";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setUsername,
    setPhone,
    setStatus,
    setIsCreateModal,
    setName,
    setRoleID,
    setEmail,
    setVendorID,
    queryKey,
    setIsReset,
    setAuthGroupID,
  } = useEmployee();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setUsername(row.username);
    setPhone(row.phone);
    setStatus(row.status);
    setName(row.name);
    setRoleID(row.name);
    setEmail(row.email);
    setVendorID(row.vendorID);
    setAuthGroupID(row.authGroupID);
    setIsCreateModal(true);
  };
  const handleReset = (row) => {
    setId(row.id);
    setIsReset(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Auth Group",
        dataIndex: "AuthGroup",
        key: "AuthGroup",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },

      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (val) => (
          <Text type="secondary">{dayjs(val).format("MMM D, YYYY")}</Text>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            disabled={authFunc("ORDER_UPDATE") ? false : true}
            loading={editLoading}
            checked={row.status}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id, row) => (
          <Space size="small">
            {authFunc("USER_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("USER_UPDATE") && (
              <Tooltip placement="top" title="Reset Password">
                <Button
                  onClick={() => handleReset(row)}
                  size="small"
                  icon={<HistoryOutlined />}
                />
              </Tooltip>
            )}
            {authFunc("USER_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((val) => removeUser(val), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "users",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["users", queryKey, page], getUsers, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(editUser, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "users",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleChangeActive = (row) => {
    {
      row.status = !row.status;
      editMutate(row);
    }
  };

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
