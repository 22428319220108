import {
  EnvironmentOutlined,
  UserOutlined,
  PhoneOutlined,
  ShopOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Space,
  Avatar,
  Select,
  Badge,
  Input,
  Divider,
  Popover,
  Button,
} from "antd";
import { BikerIcon } from "../../comonents/global";
import { useOrderView } from "./store";
import { useEffect } from "react";
import dayjs from "dayjs";

const { Text } = Typography;
const { Option } = Select;

export const OrderInfo = () => {
  const {
    order,
    setClientAddressID,
    clientAddressID,
    setClientComment,
    clientComment,
    orderStatusHistory,
  } = useOrderView();

  useEffect(() => {
    if (order && order?.order) {
      setClientAddressID(order?.order?.clientAddressID);
      setClientComment(order?.order?.clientComment);
    }
  }, [order]);

  const isStatus = (status) => {
    return orderStatusHistory.find((el) => el.status === status) ? true : false;
  };

  return (
    <>
      <Text>Order Info</Text>
      <Badge.Ribbon
        color="red"
        style={{ display: order?.order?.preOrder ? "inherit" : "none" }}
        text={
          <Space>
            <FieldTimeOutlined />
            Pre-Order
          </Space>
        }
      >
        <div className="order-info">
          <section style={{ width: "100%", overflow: "hidden" }}>
            {/* <Divider /> */}
            <Space size={16} align="start">
              <Avatar
                size="large"
                style={{ background: "#0000ff", fontSize: 20 }}
              >
                <UserOutlined />
              </Avatar>
              <Space size={0} direction="vertical">
                <Text
                  style={{ margin: 0, lineHeight: 1.4, fontWeight: "bold" }}
                >
                  {order?.order?.client || "No client name ! ..."}{" "}
                  <Button
                    className="chat-btn"
                    type="text"
                    icon={<CommentOutlined />}
                  />
                </Text>

                <Space>
                  <Text style={{ fontSize: 12 }}>
                    <Space size={4}>
                      <PhoneOutlined />
                      {order?.client?.phone || "...."}
                    </Space>
                  </Text>
                  |
                  <Text
                    style={{ fontSize: 12, lineHeight: 0 }}
                    type="secondary"
                  >
                    <Space size={0}>
                      <EnvironmentOutlined />
                      <Select
                        disabled={
                          isStatus("BIKER_ACCEPTED") ||
                          isStatus("REJECTED") ||
                          isStatus("CANCELLED")
                        }
                        dropdownMatchSelectWidth={false}
                        style={{ color: "#0000ff", maxWidth: "8vw" }}
                        value={clientAddressID}
                        onChange={(val) => setClientAddressID(val)}
                        bordered={false}
                      >
                        {order?.order?.clientAddressList?.map((el) => (
                          <Option key={el.id} value={el.id}>
                            {el.address}
                          </Option>
                        ))}
                      </Select>
                    </Space>
                  </Text>
                </Space>
              </Space>
            </Space>
            <Divider style={{ margin: "10px 0" }} />
            <Input.TextArea
              disabled={
                isStatus("VENDOR_ACCEPTED") ||
                isStatus("PICKED_UP") ||
                isStatus("REJECTED") ||
                isStatus("CANCELLED")
              }
              rows={2}
              value={clientComment}
              onChange={(e) => setClientComment(e.target.value)}
              placeholder="Client Comment..."
            />
          </section>

          <section style={{ width: "100%" }}>
            <Space size={16} align="start">
              <Avatar
                size="large"
                style={{ background: "#0000ff", fontSize: 20 }}
              >
                <ShopOutlined style={{ paddingTop: 10 }} />
              </Avatar>
              <Space size={0} direction="vertical">
                <Text
                  style={{ margin: 0, lineHeight: 1.4, fontWeight: "bold" }}
                  level={5}
                >
                  {order?.vendor?.arName || "No vendor name ! ..."}{" "}
                  <Button
                    className="chat-btn"
                    type="text"
                    icon={<CommentOutlined />}
                  />
                </Text>
                <Space>
                  <Text style={{ fontSize: 12 }}>
                    <Space size={4}>
                      <PhoneOutlined />
                      {order?.vendor?.resturantPhone || "..."}
                    </Space>
                  </Text>
                  |
                  <Text
                    style={{ fontSize: 12, lineHeight: 0 }}
                    type="secondary"
                  >
                    <Space size={4}>
                      <EnvironmentOutlined />
                      <Popover
                        content={
                          <span className="sub-title">
                            {order?.vendor?.address}
                          </span>
                        }
                      >
                        <span className="sub-title">
                          {order?.vendor?.address?.length > 20
                            ? order?.vendor?.address.substr(0, 20) + "..."
                            : order?.vendor?.address || "..."}
                        </span>
                      </Popover>
                    </Space>
                  </Text>
                </Space>
              </Space>
            </Space>
          </section>
          {order?.order?.bikerOrderID && (
            <section style={{ width: "100%" }}>
              <Space size={16} align="start">
                <Avatar
                  size="large"
                  style={{ background: "#0000ff", fontSize: 20 }}
                >
                  <BikerIcon style={{ paddingTop: 10 }} />
                </Avatar>
                <Space size={0} direction="vertical">
                  <Text
                    style={{ margin: 0, lineHeight: 1.4, fontWeight: "bold" }}
                    level={5}
                  >
                    {order?.order?.biker_name || "No biker name ! ..."}{" "}
                    <Button
                      className="chat-btn"
                      type="text"
                      icon={<CommentOutlined />}
                    />
                  </Text>
                  <Space>
                    <Text style={{ fontSize: 12 }}>
                      <Space size={4}>
                        <PhoneOutlined />
                        {order?.order?.biker_phone || "..."}
                      </Space>
                    </Text>
                    |
                    <Text
                      style={{ fontSize: 12, lineHeight: 0 }}
                      type="secondary"
                    >
                      <Space size={4}>
                        <ClockCircleOutlined />
                        <span className="sub-title">
                          {dayjs(order?.order?.bikerPickupDateTime).format(
                            "YYYY-MM-DD hh:mm A"
                          ) || "..."}
                        </span>
                      </Space>
                    </Text>
                  </Space>
                </Space>
              </Space>
            </section>
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
};
