import { useEffect , useMemo } from "react";
import {
  Table,
  Typography,
  Alert,
  Pagination,
} from "antd";
import { useQuery } from "react-query";
import { getReferralCodes, pageSize } from "../../api";
import { useReferalCodes } from "./store";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PuerTable = () => {
  const { queryKey, setPage, page, clientID, from, to } = useReferalCodes();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Created At",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (el) => <Text>{dayjs(el).format("YYYY-MM-DD hh:mm A")}</Text>,
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Client Name",
      dataIndex: "client",
      key: "client",
      render: (el) =>
        el &&
        el.map((val) => (
          <Text key={val.id} style={{ fontWeight: "bold" }}>
            {val.firstName + " " + val.lastName}{" "}
          </Text>
        )),
    },
    {
      title: "Client Username",
      dataIndex: "client",
      key: "client",
      render: (el) =>
        el && el.map((val) => <Text key={val.id}>{val.username}</Text>),
    },
    {
      title: "Client Phone",
      dataIndex: "client",
      key: "client",
      render: (el) =>
        el && el.map((val) => <Text key={val.id}>{val.phone}</Text>),
    },
    {
      title: "Client email",
      dataIndex: "client",
      key: "client",
      render: (el) =>
        el && el.map((val) => <Text key={val.id}>{val.email}</Text>),
    },
  ],[])

  

  const { isLoading: loading, error, data } = useQuery(
    ["referralCodes", page, queryKey,clientID , from , to],
    getReferralCodes,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
