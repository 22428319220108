import React from "react";
import { Select, Space, Spin } from "antd";
import { useProduct } from "../../store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getCategory, getCategoryLeaf, getBrand } from "../../api";
import { useQuery } from "react-query";

const { Option } = Select;

export default function ProductFilters() {
  const {
    setQueryFilter,
    queryKey,
    setPage,
    categoryID,
    setCategoryID,
    subCategoryID,
    setSubCategoryID,
    brandID,
    setBrandID,
    categorySearch,
    setCategorySearch,
    subCategorySearch,
    setSubCategorySearch,
    brandSearch,
    setBrandSearch,
  } = useProduct();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: catLoading,
    data: category,
    refetch: catRefetch,
  } = useQuery(["category", categorySearch, 1, 1000], getCategory, {
    enabled: !!categorySearch,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: subCatLoading,
    data: subCategory,
    refetch: subCatRefetch,
  } = useQuery(["subCategory", subCategorySearch, 1, 1000], getCategoryLeaf, {
    enabled: !!subCategorySearch,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: brandLoading,
    data: brand,
    refetch: brandRefetch,
  } = useQuery(["brand", brandSearch, 1, 1000], getBrand, {
    enabled: !!brandSearch,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    let obj = {
      categoryID: searchParams.get("categoryID") || null,
      subCategoryID: searchParams.get("subCategoryID") || null,
      brandID: searchParams.get("brandID") || null,
    };
    setCategoryID(obj.categoryID);
    setSubCategoryID(obj.subCategoryID);
    setBrandID(obj.brandID);
  }, [searchParams]);

  return (
    <Space>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          catLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val != undefined) {
            setCategoryID(val);
            setPage(1);
            searchParams.set("categoryID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setCategoryID(null);
          searchParams.delete("categoryID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCategorySearch(val)}
        placeholder="Select Category"
        style={{ width: "100%", maxWidth: 160 }}
        value={categoryID}
        onClick={() => catRefetch()}
      >
        {category &&
          category?.success &&
          category?.data?.categories?.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          subCatLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val != undefined) {
            setSubCategoryID(val);
            setPage(1);
            searchParams.set("subCategoryID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setSubCategoryID(null);
          searchParams.delete("subCategoryID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setSubCategorySearch(val)}
        placeholder="Select Sub Category"
        style={{ width: "100%", maxWidth: 160 }}
        value={subCategoryID}
        onClick={() => subCatRefetch()}
      >
        {subCategory &&
          subCategory?.success &&
          subCategory?.data?.categories?.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          brandLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val != undefined) {
            setBrandID(val);
            setPage(1);
            searchParams.set("brandID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setBrandID(null);
          searchParams.delete("brandID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setBrandSearch(val)}
        placeholder="Select Brand"
        style={{ width: "100%", maxWidth: 160 }}
        value={brandID}
        onClick={() => brandRefetch()}
      >
        {brand &&
          brand?.success &&
          brand?.data?.brands?.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>
    </Space>
  );
}
