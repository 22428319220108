import {
    Form,
    Button,
    Typography,
    Modal,
    Row,
    Col,
    Divider,
    message,
    Select,
    Tag,
    Space,
    Tooltip,
  } from "antd";
  import { SaveOutlined, PlusOutlined } from "@ant-design/icons";
  import Input from "antd/lib/input/Input";
  import { useMutation } from "react-query";

  import { creatToken, updateToken } from "../../api";
  import { useTokens } from "./store";
  import { useEffect, useState, useRef } from "react";
  import handleDataMutation from "../../helper/dataMutation";
  const { Title } = Typography;
  const { Option } = Select;
  
  export const PureCreate = () => {
    const {
      isCreateModal,
      setIsCreateModal,
      serviceName,
      serviceType,
      setServiceType,
      id,
      setServiceName,
      setId,
      roles,
      setRoles,
    } = useTokens();
    const [form] = Form.useForm();
  
    const resetState = () => {
      setServiceName(null);
      setId(null);
      setServiceType(null);
      setRoles([]);
      form.resetFields();
    };
  

  
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState("");
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
  
    useEffect(() => {
      if (inputVisible) {
        inputRef.current?.focus();
      }
    }, [inputVisible]);
    useEffect(() => {
      editInputRef.current?.focus();
    }, [editInputValue]);
    const handleClose = (removedTag) => {
      const newTags = roles?.filter((tag) => tag !== removedTag);
      setRoles(newTags);
    };
    const showInput = () => {
      setInputVisible(true);
    };
    const handleInputChange = (e) => {
      setInputValue(e.target.value.toUpperCase());
    };
    const handleInputConfirm = () => {
      if (inputValue && roles?.indexOf(inputValue) === -1) {
        setRoles([...roles, inputValue]);
      }
      setInputVisible(false);
      setInputValue("");
    };
    const handleEditInputChange = (e) => {
      setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
      const newTags = [...roles];
      newTags[editInputIndex] = editInputValue;
      setRoles(newTags);
      setEditInputIndex(-1);
      setEditInputValue("");
    };
    const tagInputStyle = {
      width: "100%",
      height: 31.6,
      verticalAlign: "top",
    };
  
    const { mutate, isLoading } = useMutation(creatToken, {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "accessTokens",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
  
    const { mutate: editMutate, isLoading: editLoading } = useMutation(
      updateToken,
      {
        onSuccess: (data) => {
          handleDataMutation({
            mutatedData: data,
            queryClientKey: "accessTokens",
            setterList: [() => setIsCreateModal(false)],
            resetFunc: resetState,
          });
        },
        onError: (e) => {
          message.error(e.message);
        },
      }
    );
  
    const onFinish = (values) => {
      values.roles = roles;
      if (id) {
        values.id = id;
        editMutate(values);
      } else {
        mutate(values);
      }
    };
  
    useEffect(() => {
      if (id) {
        form.setFieldsValue({
          serviceName,
          serviceType,
          roles,
        });
      } else {
        resetState();
        form.setFieldsValue({
          serviceType: "CLIENT",
        });
      }
    }, [id]);
  
    return (
      <Modal
        title={<Title level={5}>{id ? "Edit" : "New"} Token</Title>}
        className="app-modal"
        centered
        visible={isCreateModal}
        onCancel={() => {
          resetState();
          setIsCreateModal(false);
        }}
        footer={false}
        destroyOnClose={true}
        width={460}
      >
        <Form
          requiredMark={"optional"}
          name="basic"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col span={10}>
              <Form.Item
                label="Service Name"
                name="serviceName"
                rules={[{ required: true }]}
              >
                <Input placeholder={"baly001"} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="Service Type"
                name="serviceType"
                rules={[{ required: true }]}
              >
                <Select placeholder="Choose Type">
                  <Option value={"CLIENT"}>CLIENT</Option>
                  <Option value={"VENDOR"}>VENDOR</Option>
                  <Option value={"BACKOFFICE_USER"}>BO USER</Option>
                  <Option value={"BOX"}>BOX</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Roles" name="roles" rules={[{ required: true }]}>
                <Space size={[0, 8]} wrap>
                  <Space size={[0, 8]} wrap>
                    {roles?.length > 0 &&
                      roles?.map((tag, index) => {
                        if (editInputIndex === index) {
                          return (
                            <Input
                              // style={{padding: "4px 11px"}}
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          );
                        }
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                          <Tag
                            key={tag}
                            closable={true}
                            style={{
                              userSelect: "none",
                            }}
                            onClose={() => handleClose(tag)}
                          >
                            <span
                              onDoubleClick={(e) => {
                                if (index !== 0) {
                                  setEditInputIndex(index);
                                  setEditInputValue(tag);
                                  e.preventDefault();
                                }
                              }}
                            >
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </span>
                          </Tag>
                        );
                        return isLongTag ? (
                          <Tooltip title={tag} key={tag}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })}
                    {inputVisible ? (
                      <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={tagInputStyle}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                      />
                    ) : (
                      <Tag
                        style={{
                          height: 22,
                          borderStyle: "dashed",
                        }}
                        onClick={showInput}
                      >
                        <PlusOutlined /> New Role
                      </Tag>
                    )}
                  </Space>
                </Space>
              </Form.Item>
            </Col>
  
            <Divider />
            <Col span={1}>
              <Form.Item>
                <Tooltip title={roles?.length < 1 ? "add roles first" : ""}>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    disabled={roles?.length < 1}
                  >
                    Save
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
  