import {
  Card,
  Button,
  Breadcrumb,
  Select,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import {PureCreate} from "./create"
import {PureReset} from "./reset"
import { useTokens } from "./store";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


const { Option } = Select;

export const TokensPage = () => {
  const { setIsCreateModal, setQueryKey, queryKey } = useTokens();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);


  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);
     
  return (
      <div className="app-page">
          <Breadcrumb style={{ marginBottom: 10 }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>

              <Breadcrumb.Item>Users</Breadcrumb.Item>
              <Breadcrumb.Item>Tokens List</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
              <div className="app-space-between">
                <p></p>
                  {/* <Select
                      style={{ width: 200 }}
                      placeholder="Choose Type"
                      onChange={(val) =>{ 
                        setQueryKey(val);
                        searchParams.set("q", val);
                        setSearchParams(searchParams);
                        }}
                        defaultValue={""}
                        value={search}
                  >
                      <Option value={""}>All</Option>
                      <Option value={"CLIENT"}>CLIENT</Option>
                      <Option value={"VENDOR"}>VENDOR</Option>
                      <Option value={"BACKOFFICE_USER"}>BACKOFFICE_USER</Option>
                      <Option value={"BOX"}>BOX</Option>
                  </Select> */}
                  {authFunc("TOKEN_CREATE") && <Button
                      onClick={() => setIsCreateModal(true)}
                      type="primary"
                      icon={<PlusOutlined />}
                  >
                      Create New
                  </Button>}
              </div>
              <br />
              <PureTable />
              <PureCreate />
              <PureReset />
          </Card>
      </div>
  );
};
