import create from "zustand";

export const useFreeDelivery = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  active: true,
  value: null,
  maxamount: null,
  minamount: null,
  dayOfWeek: null,
  startDate: null,
  endDate: null,
  vendorIDs: null,
  id: null,
  schedule: null,
  totalValue: null,
  isUsersModal: false,
  page: 1,
  vendorValue: null,
  balyValue: null,
  status: null,
  from: null,
  to: null,
  search: "",

  setSearch: (search) => set({ search }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setActive: (active) => set({ active }),
  setStatus: (status) => set({ status }),

  setVendorValue: (vendorValue) => set({ vendorValue }),
  setBalyValue: (balyValue) => set({ balyValue }),
  setTotalValue: (totalValue) => set({ totalValue }),
  setSchedule: (schedule) => set({ schedule }),
  setMaxAmount: (maxamount) => set({ maxamount }),
  setMinAmount: (minamount) => set({ minamount }),
  setDayOfWeek: (dayOfWeek) => set({ dayOfWeek }),
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setVendorIDs: (vendorIDs) => set({ vendorIDs }),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPage: (page) => set({ page }),

  status: false,
  discountType: "PERCENTAGE",
  vendorID: null,
  vendor: "",

  setStatus: (status) => set({ status }),
  setDiscountType: (discountType) => set({ discountType }),
  setVendorID: (vendorID) => set({ vendorID }),
  setVendor: (vendor) => set({ vendor }),
}));
