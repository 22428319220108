import create from "zustand";

export const useReferalCodes = create((set) => ({
  queryKey: "",
  queryFilter: "",
  page: 1,
  clientID : null,
  from : null,
  to : null,

  setClientID: (clientID) => set({ clientID }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setPage: (page) => set({ page }),
}));
