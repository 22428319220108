import { useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  DatePicker,
  InputNumber,
} from "antd";
import { SafetyOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useVendorPayment } from "./store";
import { useQuery } from "react-query";

import { getVendors } from "../../api";

import { ConfirmPayment } from "./confirmModal";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";

const { Title } = Typography;

export const CreatePayment = () => {
  const {
    isPayModal,
    setIsPayModal,
    vendorID,
    totalValue,
    setTotalValue,
    from,
    to,
    setIsConfirmModal,
    setCurrentFrom,

    setCurrentTo,

    setReceiverName,
    setPayedAmount,
  } = useVendorPayment();

  useEffect(() => {
    setCurrentFrom(from);
    setCurrentTo(to);
  }, [isPayModal]);

  const { data: vendors } = useQuery(["vendors", "", 1, "", 1000], getVendors, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  let restname = vendors?.data?.records?.find(
    (el) => el.id == vendorID
  )?.arName;

  const onFinish = (values) => {
    setIsConfirmModal(true);
    setPayedAmount(values.amount);
    setReceiverName(values.receiverName);
  };

  return (
    <>
      <Modal
        title={<Title level={5}>Pay To {restname}</Title>}
        className="app-modal"
        centered
        visible={isPayModal}
        onCancel={() => {
          setIsPayModal(false);
          setTotalValue(null);
        }}
        footer={false}
        destroyOnClose={true}
        width={500}
      >
        <Form
          requiredMark={"optional"}
          name="basic"
          initialValues={{
            remember: true,
            amount: totalValue && totalValue,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item
                label="Receiver Name"
                name="receiverName"
                rules={[{ required: true }]}
              >
                <Input placeholder="Receiver Name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true }]}
              >
                <InputNumber
                  formatter={(value) => inputNumberFormatter(value)}
                  parser={(value) => inputNumberParser(value)}
                  placeholder={"Amount"}
                  addonAfter="IQD"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SafetyOutlined />}
                >
                  Submit Payment
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <ConfirmPayment />
    </>
  );
};
