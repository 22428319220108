import React from "react";
import { Select, DatePicker, Space, Input } from "antd";
import { useDiscountCap } from "../../store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
export default function DicountCapFilters() {
  const {
    queryKey,
    setQueryKey,
    setPage,
    setFrom,
    setTo,
    status,
    setStatus,
    SelectTimeDate,
    from,
    to,
    search,
    setSearch,
  } = useDiscountCap();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      status: searchParams.get("status") || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
      q: searchParams.get("q") || null,
    };
    setStatus(obj.status);
    setFrom(obj.from);
    setTo(obj.to);
    setQueryKey(obj.q);
    setSearch(obj.q);
  }, [searchParams]);

  return (
    <div className="filter-wrapper">
      <Space>
        <Search
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={(val) => {
            searchParams.set("q", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
            setQueryKey(val);
          }}
          style={{ width: 340 }}
          placeholder="Write To Search..."
        />
      </Space>
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setStatus(val);
              searchParams.set("status", val);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setStatus(null);
            searchParams.delete("status");
            setSearchParams(searchParams);
          }}
          placeholder="Select Status"
          style={{ width: "100%" }}
          value={status}
        >
          <Option value={"true"}>Active</Option>
          <Option value={"false"}>InActive</Option>
        </Select>
      </div>
      <div className="item">
        <Space direction="vertical" size={5}>
          <RangePicker
            value={
              from && to
                ? [
                    moment(from, "YYYY-MM-DD HH:mm"),
                    moment(to, "YYYY-MM-DD HH:mm"),
                  ]
                : []
            }
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={(dates, dateStrings) => {
              if (dates != null) {
                setFrom(dateStrings[0]);
                setTo(dateStrings[1]);
                searchParams.set("from", dateStrings[0]);
                searchParams.set("to", dateStrings[1]);
                setSearchParams(searchParams);
              } else {
                setFrom(null);
                setTo(null);
                searchParams.delete("from");
                searchParams.delete("to");
                setSearchParams(searchParams);
              }
            }}
          />
        </Space>
      </div>
    </div>
  );
}
