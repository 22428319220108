import readXlsxFile from "read-excel-file";
import { fileDialog } from "file-select-dialog";

export const openFile = async (editMutate, id) => {
  const fileList = await fileDialog({ accept: [".xlsx", ".csv"] });
  readXlsxFile(fileList[0]).then((rows) => {
    let indexID;

    rows.map((el, index) => {
      let isID = el.findIndex((el2) => el2 == "Id");
      if (isID != -1) {
        indexID = [isID, index];
      }
    });

    let users = [];
    rows.map((el, index) => {
      if (index > indexID[1]) {
        users.push(el[indexID[0]]);
      }
    });
    let uniqueUsers = users.filter((item, i, arr) => arr.indexOf(item) === i);

    let dataForm = {
      id,
      userIDs: uniqueUsers,
    };

    editMutate(dataForm);
  });
};
