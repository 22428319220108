import {
    Typography,
    Modal,
    Table,
  } from "antd";
  import { useProduct } from "./store";
  import dayjs from "dayjs";
import { useMemo } from "react";

  const { Title, Text } = Typography;
  
  export const HistoryModal = () => {
    const {
        statusHistory,
        isHistoryModal,
        setIsHistoryModal,
    } = useProduct();

    let data = JSON.parse(statusHistory)
    const columns = useMemo(
    () => [ 
        {
            title: "User ID",
            dataIndex: "userID",
            key: "userID",
        },
        {
            title: "User Name",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Action",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "At",
            dataIndex: "time",
            key: "time",
            render: (time) => <Text type="secondary">
            {dayjs(time).format("YYYY-MM-DD hh:mm A")}
        </Text>
        },
    ],[])
  
    return (
      <Modal
        forceRender
        title={<Title level={5}> Status History</Title>}
        className="app-modal"
        centered
        visible={isHistoryModal}
        onCancel={() => {
            setIsHistoryModal(false)
        }}
        footer={false}
        destroyOnClose={true}
        width={800}
      >
    <Table
        columns={columns}
        dataSource={data ? data : []}
        size="small"
        pagination={false}
        scroll={{
            y: 400,
          }}
      />

      </Modal>
    );
  };
  