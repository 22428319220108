import { useState, useEffect } from "react";
import { Select, Spin, DatePicker, Space } from "antd";
import { useReferalCodes } from "../../store";
import { getClients } from "../../api";
import { useQuery } from "react-query";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function ReferalCodesFilters() {
  const {
    setQueryFilter,
    queryKey,
    setPage,
    clientID,
    setClientID,
    from,
    setFrom,
    to,
    setTo,
  } = useReferalCodes();

  // SEARCH STATES
  const [clientSearch, setClientSearch] = useState("");

  // GENERAL STATES
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: isClientsLoading,
    data: clients,
    refetch: clientsRefetch,
  } = useQuery(["clients", clientSearch, 1], getClients, {
    enabled: !!clientSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let obj ={
      clientID: searchParams.get("clientID") || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null, 
    }
    setClientID(obj.clientID);
    setFrom(obj.from);
    setTo(obj.to);
  }, [
    searchParams.get("vendorID"),
    searchParams.get("from"),
    searchParams.get("to"),
  ]);

  return (
    <Space>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isClientsLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val != undefined) {
            setClientID(val);
            setPage(1);
            searchParams.set("clientID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setClientID(null);
          searchParams.delete("clientID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setClientSearch(val)}
        placeholder="Select Client"
        style={{ width: "100%", maxWidth: 160 }}
        value={clientID}
        onClick={() => clientsRefetch()}
      >
        {clients &&
          clients.success &&
          clients.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {!el.firstName ? el.phone : el.firstName + " " + el.lastName}
            </Option>
          ))}
      </Select>

      <Space direction="vertical" size={5}>
        <RangePicker
          value={
            from && to
              ? [
                  moment(from, "YYYY-MM-DD HH:mm"),
                  moment(to, "YYYY-MM-DD HH:mm"),
                ]
              : []
          }
          showTime={{
            format: "HH:mm",
          }}
          format="YYYY-MM-DD HH:mm"
          onChange={(dates, dateStrings) => {
            if (dates != null) {
              setFrom(dateStrings[0]);
              setTo(dateStrings[1]);
              searchParams.set("from", dateStrings[0]);
              searchParams.set("to", dateStrings[1]);
              setSearchParams(searchParams);
            } else {
              setFrom(null);
              setTo(null);
              searchParams.delete("from");
              searchParams.delete("to");
              setSearchParams(searchParams);
            }
          }}
        />
      </Space>
    </Space>
  );
}
