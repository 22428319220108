import create from "zustand";

export const useVendorProductVariation = create((set) => ({
  isCreateModal: false,
  id: null,
  price: null,
  availableQty: null,
  queryKey: "",
  productVariationID: null,

  priority: null,
  barcode: null,
  status: "",
  limit:null,

  setLimit: (limit) => set({ limit }),
  setStatus: (status) => set({ status }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setBarcode: (barcode) => set({ barcode }),

  setId: (id) => set({ id }),
  setPriority: (priority) => set({ priority }),
  setProductVariationID: (productVariationID) => set({ productVariationID }),

  setPrice: (price) => set({ price }),
  setAvailableQty: (availableQty) => set({ availableQty }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
