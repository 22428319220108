import { Select, Card, Input, Breadcrumb } from "antd";
import { PureTable } from "./table";
import { useReviews } from "../../store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

export const VendorsReviewPage = () => {
  const {
    setQueryKey,
    queryKey,
    search,
    setSearch,
    phoneNo,
    setPhoneNo,
    setQuerySearch,
    status,
    setStatus,
  } = useReviews();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      const query = searchParams.get("q");
      setSearch(query);
      setQueryKey(query);
    } else {
      setSearch("");
      setQueryKey("");
    }

    if (searchParams.get("phone")) {
      const phoneQuery = searchParams.get("phone");
      setPhoneNo(phoneQuery);
      setQuerySearch(phoneQuery);
    } else {
      setPhoneNo("");
      setQuerySearch("");
    } 
    if(searchParams.get("status")){
      setStatus(searchParams.get("status"));
    }else{
      setStatus(null);
    }
  }, [searchParams.get("q"), searchParams.get("phone"), searchParams.get("status")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Customers Rate</Breadcrumb.Item>
        <Breadcrumb.Item>Vendors Review</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div>
          <Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={(val) => {
              searchParams.set("q", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
              setQueryKey(val);
            }}
            style={{ width: 340 }}
            placeholder="Search By Vendor Name ..."
          />
          <Search
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            onSearch={(val) => {
             searchParams.set("phone", val);
              setSearchParams(searchParams);
              setQuerySearch(val);
            }}
            style={{ width: 340 }}
            placeholder="Search By Customer PhoneNo ..."
          />
          <Select
            allowClear
            onChange={(val) => {
              if (val != undefined) {
                setStatus(val);
                searchParams.set("status", val);
                setSearchParams(searchParams);
              }
            }}
            onClear={() => {
              setStatus(null);
              searchParams.delete("status");
              setSearchParams(searchParams);
            }}
            placeholder="Select Status"
            style={{ width: 340 }}
            value={status}
          >
            <Option value={"PENDING"}>Pending</Option>
            <Option value={"REJECTED"}>Rejected</Option>
            <Option value={"ACCEPTED"}>Accepted</Option>
          </Select>
        </div>
        <br />
        <PureTable />
      </Card>
    </div>
  );
};
