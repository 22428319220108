import { useEffect, useState, useMemo } from "react";
import {
  Form,
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Tooltip,
  Switch,
} from "antd";
import { DeleteOutlined, CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import { deleteTokens, getTokens, updateTokenStatus } from "../../api";
import moment from "moment";
import handleDataMutation from "../../helper/dataMutation";
import { copyLink } from "../../helper/copyLink";
import { useTokens } from "./store";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PureTable = () => {
  const {
    queryKey,
    setId,
    setServiceType,
    setServiceName,
    setIsCreateModal,
    setStatus,
    setRoles,
  } = useTokens();
  const [userList, setUserList] = useState([]);

  const [form] = Form.useForm();

  const resetState = () => {
    setServiceName(null);
    setId(null);
    setServiceType(null);
    setStatus(null);
    form.resetFields();
    setRoles([]);
  };
  const handleEdit = (row) => {
    setId(row.id);
    setServiceType(row.serviceType);
    setServiceName(row.serviceName);
    setStatus(row.status);
    setIsCreateModal(true);
    setRoles(JSON.parse(row.roles));
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Service Name",
        dataIndex: "serviceName",
        key: "serviceName",
      },
      {
        title: "Token",
        dataIndex: "token",
        key: "token",
        render: (text) => (
          <Space className="table-link">
            <Text
              className="app-media-link"
              style={{ textDecoration: "underline" }}
            >
              {text}
            </Text>
            {text !== " " && (
              <Tooltip placement="top" title="copy token">
                <Button
                  onClick={() => copyLink(text)}
                  size="small"
                  type="text"
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        ),
      },

      {
        title: "Service Type",
        dataIndex: "serviceType",
        key: "serviceType",
        render: (type) => (
          <Tag
            color={
              type === "CLIENT"
                ? "geekblue"
                : type === "VENDOR"
                ? "magenta"
                : "orange"
            }
          >
            {type}
          </Tag>
        ),
      },

      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => (
          <Text type="secondary">{moment(date).format("ll")}</Text>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            disabled={authFunc("TOKEN_UPDATE") ? false : true}
            loading={editLoading}
            checked={row.status}
            onChange={(status) => handleChangeActive(row)}
          />
        ),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id, row) => (
          <Space size="small">
            {authFunc("TOKEN_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("TOKEN_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate, isLoading: deleteLoading } = useMutation(
    (val) => deleteTokens(val),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "accessTokens",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery("accessTokens", getTokens, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    updateTokenStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "accessTokens",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const initData = async () => {
    if (data && data?.success) {
      let newList = data?.data?.records;
      setUserList(
        queryKey ? newList.filter((el) => el.serviceType === queryKey) : newList
      );
    }
  };

  const handleChangeActive = (row) => {
    row.status = !row.status;
    editMutate({
      id: row.id,
      status: row.status,
    });
  };
  useEffect(() => {
    if (data && data.err !== "unauthorized") {
      initData();
    }
  }, [data]);

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading || deleteLoading}
        columns={columns}
        dataSource={userList}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </>
  );
};
