import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  Select,
  Tag,
  Space,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useMutation, useQuery } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { AddToBanners, getClients } from "../../api";
import parseName from "../../helper/parseName";
const { Option } = Select;

export const PureGetUsers = () => {
  const { setIsAddUsers, id } = useAppBanner();
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState(null);
  const [list, setList] = useState([]);

  const {
    isLoading: clientLoading,
    error,
    data: clients,
    refetch,
  } = useQuery(["clients", filter], getClients, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
    }
  }, []);

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  useEffect(() => {
    if (selected && clients.success) {
      setList([...list, clients.data.records.find((el) => el.id == selected)]);
      setSelected(null);
    }
  }, [selected]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToBanners("user", values),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["appBanner", "clientsAppbanner"],
          setterList: [() => setList([])],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = () => {
    let dataForm = {
      id,
      userIDs: list.map((el) => el.id),
    };

    editMutate(dataForm);
  };

  return (
    <div style={{ width: 460 }}>
      <Row gutter={[0, 10]}>
        <Col flex={"100%"}>
          <Select
            showSearch
            value={selected}
            onChange={(val) => setSelected(val)}
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            style={{ width: "100%" }}
            placeholder="Find Clients"
            allowClear
          >
            {clients?.data?.records?.map((el) => (
              <Option
                disabled={isRepeated(el.id)}
                key={el.id}
                id={el.id}
                value={el.id}
              >
                {parseName(el)}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <Space size={10} wrap>
            {list.length > 0
              ? list.map((el) => (
                  <Tag
                    key={el.id}
                    closable
                    onClose={() => {
                      setList(list.filter((item) => item.id != el.id));
                    }}
                    color="purple"
                  >
                    {parseName(el)}
                  </Tag>
                ))
              : null}
          </Space>
        </Col>
        <Col span={24}>
          <Divider />
          <Button
            loading={editLoading}
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            onClick={onFinish}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};
