import create from "zustand";

export const useStatusCode = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,
  description: null,
  queryKey: "",
  color: null,
  
  setQueryKey: (queryKey) => set({ queryKey }),


  setId: (id) => set({ id }),
  setColor: (color) => set({ color }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
