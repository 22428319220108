import { useEffect, useState } from "react";
import { DatePicker, Space } from "antd";
import { useVendorLog } from "../../store";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const date = new Date();

export default function VendorLogFilters() {
  const { setPage, setFrom, setTo, from, to } = useVendorLog();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
    };
    setFrom(obj.from);
    setTo(obj.to);
  }, [searchParams.get("from"), searchParams.get("to")]);

  return (
    <Space wrap>
      <Space direction="vertical" size={5}>
        <RangePicker
          value={
            from && to
              ? [
                  moment(from, "YYYY-MM-DD HH:mm"),
                  moment(to, "YYYY-MM-DD HH:mm"),
                ]
              : []
          }
          showTime={{
            format: "HH:mm",
          }}
          format="YYYY-MM-DD HH:mm"
          onChange={(dates, dateStrings) => {
            if (dates != null) {
              setFrom(dateStrings[0]);
              setTo(dateStrings[1]);
              searchParams.set("from", dateStrings[0]);
              searchParams.set("to", dateStrings[1]);
              setSearchParams(searchParams);
            } else {
              setFrom(null);
              setTo(null);
              searchParams.delete("from");
              searchParams.delete("to");
              setSearchParams(searchParams);
            }
          }}
        />
      </Space>
    </Space>
  );
}
