import create from "zustand";

export const useAuthGroup = create((set) => ({
    isCreateModal: false,
    authName: null,
    id: null,
    queryKey: "",
    authGroups: [],
    name:null,
    roles:[],
    page: 1,
    
    setQueryKey: (queryKey) => set({ queryKey }),
    setId: (id) => set({ id }),
    setAuthName: (authName) => set({ authName }),
    setAuthGroups: (authGroups) => set({ authGroups }),
    setName: (name) => set({ name }),
    setRoles: (roles) => set({ roles }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
    setPage: (page) => set({ page }),
}));
