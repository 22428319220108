import { useEffect, useState, useMemo } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Pagination,
} from "antd";
import { useQuery, useMutation } from "react-query";

import { getReviews, editReviews, pageSize } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useReviews } from "./store";
import { Rating } from "react-simple-star-rating";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;
const statusColor = {
  ACCEPTED: "success",
  REJECTED: "error",
  PENDING: "orange",
};

export const PureTable = () => {
  const { queryKey, status } = useReviews();
  const [page, setPage] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["productsReveiw", "productreview", queryKey, page, "", "", status],
    getReviews,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate, isLoading: editLoading } = useMutation(
    (val) => editReviews("productreview", val),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "productsReveiw",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleEdit = (row, status) => {
    row.status = status;
    mutate(row);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Product Name",
        dataIndex: "product",
        key: "product",
        render: (name) => <Text style={{ fontWeight: "bold" }}>{name}</Text>,
      },
      {
        title: "Vendor Name",
        dataIndex: "arName",
        key: "arName",
      },
      {
        title: "Order ID",
        dataIndex: "orderID",
        key: "orderID",
      },
      {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
        render: (value, row) => (
          <Rating
            allowHalfIcon={true}
            transition={true}
            rtl={true}
            size={18}
            readonly
            emptyColor="#ddd"
            initialValue={value}
          />
        ),
      },
      {
        title: "Customer Name",
        dataIndex: "client",
        key: "client",
        render: (text, row) =>
          text ? (
            <Text style={{ fontWeight: "bold" }}>{text}</Text>
          ) : (
            <small>No Customer yet..</small>
          ),
      },
      {
        title: "Customer reviews",
        dataIndex: "comment",
        key: "comment",
        render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
      },
      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => (
          <Text type="secondary">{moment(date).format(" DD/ MM/ YYYY")}</Text>
        ),
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        render: (reason) => {
          let reasons = JSON.parse(reason);
          return (
            <Text style={{ fontSize: 12 }}>
              {reasons.length > 0
                ? reasons.map((el, index) => (
                    <span
                      style={{
                        padding: 4,
                        borderRadius: 4,
                        background: "#f6f6f6",
                        margin: 2,
                      }}
                      key={index}
                    >
                      {el.title}
                    </span>
                  ))
                : "..."}
            </Text>
          );
        },
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (val) => <Tag color={statusColor[val]}>{val}</Tag>,
      },

      {
        title: "Action",
        key: "id",
        render: (row) =>
          row.status == "PENDING"
            ? authFunc("PRODUCT_REVIEW_READ") && (
                <Space>
                  <Button
                    loading={editLoading}
                    onClick={() => handleEdit(row, "ACCEPTED")}
                    type="primary"
                    size="small"
                    color="red"
                  >
                    Accept
                  </Button>
                  <Button
                    loading={editLoading}
                    onClick={() => handleEdit(row, "REJECTED")}
                    danger
                    type="primary"
                    size="small"
                  >
                    Reject
                  </Button>
                </Space>
              )
            : row.status == "REJECTED"
            ? authFunc("PRODUCT_REVIEW_READ") && (
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "ACCEPTED")}
                  type="primary"
                  size="small"
                >
                  Accept
                </Button>
              )
            : authFunc("PRODUCT_REVIEW_READ") && (
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "REJECTED")}
                  danger
                  type="primary"
                  size="small"
                >
                  Reject
                </Button>
              ),
      },
    ],
    []
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record?.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
