import { Card, Input, Button, Breadcrumb, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PuerTable } from "./table";
import { PureCreate } from "./create";
import { useVendorProductVariation } from "./store";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;

export const VendorProductVariationPage = () => {
  const { setIsCreateModal, setQueryKey, queryKey, setStatus, status } =
    useVendorProductVariation();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  const handleStatusChange = (value) => {
    setStatus(value);
    searchParams.set("status", value);
    searchParams.set("page_num", 1);
    setSearchParams(searchParams);
  };
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Vendors</Breadcrumb.Item>
        <Breadcrumb.Item>Vendor List</Breadcrumb.Item>
        <Breadcrumb.Item>Vendor Products</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <div>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Segarch..."
            />
            <Select
              style={{ width: 140, marginLeft: 10 }}
              onChange={handleStatusChange}
              allowClear
              onClear={() => {
                searchParams.delete("status");
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
              }}
              placeholder="Filter State"
            >
              <Select.Option value="true">Active</Select.Option>
              <Select.Option value="false">Inactive</Select.Option>
            </Select>
          </div>

          <Button
            onClick={() => setIsCreateModal(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create New
          </Button>
        </div>
        <br />
        <PuerTable />
        <PureCreate />
      </Card>
    </div>
  );
};
