import { Typography, Modal, Space, Form } from "antd";
import { useVendor } from "./store";
import { VendorInfo, GetLocation, GetImages, OtherInfo } from "./forms";
import { useEffect } from "react";
import { getVendorEdit } from "../../api";
import { useQuery } from "react-query";
import "./styles.css";
import { useDataStore } from "./dataStore";
const { Title, Text } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    step,
    id,
    setId,
    setLat,
    setLon,
    setOwnerName,
    setProvinceID,
    setCityID,
    setCity,
    setZoneID,
    setZone,
    setStatusCodeID,
    setDeliveryRangeDistance,
    setPhone,
    setMgmtPhone,
    setDescription,
    setBusinessCategoryIDS,
    setAddress,
    setArName,
    setRank,
    setdDelieveryfeeid,
    setDeliveryDistanceID,
    setMaxamount,
    setMinamount,
    setBusinessCategories,
    setServiceFeeID,
    setStep,
    cityID,
  } = useVendor();

  const { setVendorData } = useDataStore();

  let renderSteps = id
    ? [<VendorInfo />, <OtherInfo />, <GetLocation />]
    : [<VendorInfo />, <OtherInfo />, <GetLocation />, <GetImages />];

  const stepStyle = id
    ? [{ width: "25%" }, { width: "50%" }, { width: "100%" }]
    : [{ width: "25%" }, { width: "50%" }, { width: "75%" }, { width: "100%" }];

  const {
    isLoading: vandorEditLoading,
    error: vandorEditError,
    data: editData,
  } = useQuery([id], getVendorEdit, {
    refetchOnWindowFocus: false,
    retry: 2,
    endabled: isCreateModal,
  });
  const stateReset = () => {
    setVendorData(null);
    setOwnerName(null);
    setProvinceID(null);
    setCityID(null);
    setCity(null);
    setZoneID(null);
    setZone(null);
    setStatusCodeID(null);
    setDeliveryRangeDistance(null);
    setPhone(null);
    setMgmtPhone(null);
    setDescription(null);
    setBusinessCategoryIDS(null);
    setAddress(null);
    setArName(null);
    setRank(null);
    setLat(null);
    setLon(null);
    setServiceFeeID(null);
    setdDelieveryfeeid(null);
    setDeliveryDistanceID(null);
    setMaxamount(null);
    setMinamount(null);
    setBusinessCategories(null);
    setStep(0);
  };
  useEffect(() => {
    if (editData?.data) {
      setVendorData(editData?.data);
      setOwnerName(editData?.data?.ownerName);
      setProvinceID(editData?.data?.provinceID);
      setCityID(editData?.data?.cityID);
      setCity(editData?.data?.city);
      setZoneID(editData?.data?.zoneID);
      setZone(editData?.data?.zone);
      setStatusCodeID(editData?.data?.statusCodeID);
      setDeliveryRangeDistance(editData?.data?.deliveryRangeDistance * 1000);
      setPhone(editData?.data?.phone);
      setMgmtPhone(editData?.data?.mgmtPhone);
      setDescription(editData?.data?.description);
      setAddress(editData?.data?.address);
      setArName(editData?.data?.arName);
      setRank(editData?.data?.rank);
      setLat(editData?.data?.lat);
      setLon(editData?.data?.lon);
      setServiceFeeID(editData?.data?.serviceFeeID);
      setdDelieveryfeeid(editData?.data?.delieveryFeeID);
      setDeliveryDistanceID(editData?.data?.deliveryDistanceID);
      setMaxamount(editData?.data?.maxamount);
      setMinamount(editData?.data?.minamount);
      setBusinessCategories(editData?.data?.businessCategories);
      setBusinessCategoryIDS(
        editData?.data?.businessCategories?.map((e) => e.id)
      );
    }
  }, [editData?.data]);

  return (
    <Modal
      title={
        <Space style={{ width: "100%" }} direction="vertical">
          {" "}
          <Title style={{ marginBottom: 0 }} level={5}>
            {" "}
            {id ? "Edit" : "Create"} Vendor{" "}
          </Title>{" "}
          <Text type="secondary">
            {" "}
            {id
              ? "Edit a vendor information and click finish"
              : "Create a vendor for a costumer directly from reception"}{" "}
          </Text>{" "}
        </Space>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        setId(null);
        setIsCreateModal(false);
        stateReset();
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
      bodyStyle={{ minHeight: 600, paddingTop: 10 }}
      maskClosable={false}
    >
      {" "}
      <div
        className="step-line"
        style={{ display: step == 0 ? "block" : "flex" }}
      >
        {" "}
        <span
          className={"step-line-select"}
          style={stepStyle[step]}
        ></span>{" "}
      </div>{" "}
      <br /> {renderSteps[step]}{" "}
    </Modal>
  );
};
