import { useState, useEffect, useMemo } from "react";
import {
  Table,
  Tag,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Badge,
  Pagination,
  Switch,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import {
  getOrderStatus,
  editOrderStatus,
  removeData,
  pageSize,
} from "../../api";

import { useOrderStatus } from "./store";

const { Text } = Typography;

const userTypeColor = {
  VENDOR: "green",
  CLIENT: "magenta",
  BIKER: "purple",
  BACKOFFICE_USER: "geekblue",
};

export const PureTable = () => {
  const [page, setPage] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const {
    queryKey,
    setIsCreateModal,
    setId,
    setEnTitle,
    setArTitle,
    setKuTitle,
    setVmsTitle,
    setEnDescription,
    setArDescription,
    setKuDescription,
    setCode,
    setUserType,
    setColor,
  } = useOrderStatus();

  const handleEdit = (row) => {
    setId(row.id);
    setEnTitle(row.enTitle);
    setArTitle(row.arTitle);
    setKuTitle(row.kuTitle);
    setVmsTitle(row.vmsTitle);
    setEnDescription(row.enDescription);
    setArDescription(row.arDescription);
    setKuDescription(row.kuDescription);
    setCode(row.code);
    setColor(row.color);
    setUserType(row.userType);
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        width: 60,
        fixed: "left",
      },
      {
        title: "En Title",
        dataIndex: "enTitle",
        key: "enTitle",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },
      {
        title: "Ar Title",
        dataIndex: "arTitle",
        key: "arTitle",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },
      {
        title: "Ku Title",
        dataIndex: "kuTitle",
        key: "kuTitle",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },
      {
        title: "En Description",
        dataIndex: "enDescription",
        key: "enDescription",
      },
      {
        title: "Ar Description",
        dataIndex: "arDescription",
        key: "arDescription",
      },
      {
        title: "Ku Description",
        dataIndex: "kuDescription",
        key: "kuDescription",
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "User Type",
        dataIndex: "userType",
        key: "userType",
        render: (type) => <Tag color={userTypeColor[type]}>{type}</Tag>,
      },
      {
        title: "Color",
        dataIndex: "color",
        key: "color",
        render: (color) => <Badge color={color} text={color} />,
      },

      {
        title: "Creation Date",
        dataIndex: "CreatedAt",
        key: "CreatedAt",
        render: (date) => (
          <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
        ),
      },
      {
        title: "VMS Title",
        dataIndex: "vmsTitle",
        key: "vmsTitle",
        render: (text) => <Text>{text ? text : "..."}</Text>,
      },
      {
        title: "VMS Status",
        key: "id",
        render: (row) => (
          <Switch
            disabled={authFunc("ORDER_STATUS_UPDATE") ? false : true}
            loading={editLoading}
            checked={row.vmsStatus}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },
      {
        title: "Actions",
        key: "id",
        fixed: "right",
        width: 100,
        dataIndex: "id",
        render: (id, row) => (
          <Space>
            {authFunc("ORDER_STATUS_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("ORDER_STATUS_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                disabled={row.mandatory == "true" ? true : false}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={row.mandatory == "true" ? true : false}
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((data) => removeData("orderstatus", data), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "orderStatus",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editOrderStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "orderStatus",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["orderStatus", queryKey, page], getOrderStatus, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const handleChangeActive = (row) => {
    {
      row.vmsStatus = !row.vmsStatus;
      editMutate(row);
    }
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{
          x: 1800,
        }}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
