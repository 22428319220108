import { useEffect, useState, useMemo } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  message,
  Popconfirm,
  Pagination,
  Switch,
  Tooltip,
  Alert,
} from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import {
  getMsgTemplate,
  editMsgTemplateStatus,
  removeMsgTemplate,
  pageSize,
} from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useMsgTemplate } from "./store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

import { copyLink } from "../../helper/copyLink";
const { Text, Link } = Typography;

export const PureTable = () => {
  const {
    id,
    setId,
    setMsg,
    setStatusCodeID,
    setIsCreateModal,
    setDeepLink,
    setConfig,
    queryKey,
  } = useMsgTemplate();

  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setMsg(row.msg);
    setStatusCodeID(row.statusCodeID);
    setIsCreateModal(true);
    setDeepLink(row.deepLink);
    setConfig(row.config);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Order Status",
        dataIndex: "statusCode",
        key: "statusCode",
      },
      {
        title: "Content",
        dataIndex: "msg",
        key: "msg",
        render: (msg) => <Text>{msg && JSON.parse(msg).content}</Text>,
      },

      {
        title: "Send By",
        dataIndex: "msg",
        key: "msg",
        render: (msg) =>
          msg && JSON.parse(msg).sendBy.map((el) => <Tag key={el}>{el}</Tag>),
      },
      {
        title: "Campaign URL",
        dataIndex: "config",
        key: "config",
        render: (config) => (
          <Space className="table-link">
            <Link
              className="app-media-link"
              style={{ textDecoration: "underline" }}
            >
              {config && JSON.parse(config).campaignURL}
            </Link>
            <Tooltip placement="top" title="copy link">
              <Button
                onClick={() =>
                  copyLink(config && JSON.parse(config).campaignURL)
                }
                size="small"
                type="text"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
      {
        title: "API Key",
        dataIndex: "config",
        key: "config",
        render: (config) => <Text>{config && JSON.parse(config).apiKey}</Text>,
      },
      {
        title: "Deep Link",
        dataIndex: "deepLink",
        key: "deepLink",
        render: (text) => (
          <Space className="table-link">
            <Link
              className="app-media-link"
              style={{ textDecoration: "underline" }}
            >
              {text}
            </Link>
            <Tooltip placement="top" title="copy image link">
              <Button
                onClick={() => copyLink(text)}
                size="small"
                type="text"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            disabled={authFunc("MSG_TEMPLATE_UPDATE") ? false : true}
            loading={editLoading}
            checked={row.status}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        render: (id, row) => (
          <Space>
            {authFunc("MSG_TEMPLATE_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("MSG_TEMPLATE_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                disabled={row.mandatory == "true" ? true : false}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={row.mandatory == "true" ? true : false}
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((val) => removeMsgTemplate(val), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "msgTemplate",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    data,
    error,
  } = useQuery(["msgTemplate", page, queryKey], getMsgTemplate, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editMsgTemplateStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "msgTemplate",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    editMutate({
      id: row.id,
      status: !row.status,
    });
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
