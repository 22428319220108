import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useGovernorates } from "./store";
import Input from "antd/lib/input/Input";
import { findGov, createGovs } from "../../api";
import { useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const { isCreateModal, setIsCreateModal } = useGovernorates();
  const [govsList, setGovsList] = useState([]);
  const [info, setInfo] = useState();
  const [form] = Form.useForm();

  const handleSearch = (query) => {
    setInfo("");
    findGov(query, (res, err) => {
      if (!err) {
        setGovsList(res);
      }
    });
  };

  const { mutate, isLoading } = useMutation(createGovs, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "governorates",
        setterList: [
          () => setIsCreateModal(false),
          () => setInfo(""),
          () =>
            form.setFieldsValue({
              lon: null,
              lat: null,
              name: null,
            }),
        ],
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    values.name = JSON.parse(values.name).display_name;
    mutate(values);
  };
  const onFinishFailed = (error) => {};

  useEffect(() => {
    if (info) {
      let _info = JSON.parse(info);
      form.setFieldsValue({
        lon: parseFloat(_info.lon.replace(",", ".")),
        lat: parseFloat(_info.lat.replace(",", ".")),
      });
    }
  }, [info]);

  return (
    <Modal
      title={<Title level={5}>New Governorates</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        setIsCreateModal(false);
        form.resetFields();
        setGovsList([]);
      }}
      footer={false}
      destroyOnClose={true}
      width={450}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Select
                allowClear
                value={info}
                showSearch
                filterOption={false}
                onSearch={handleSearch}
                placeholder="Write Name"
                onChange={(val) => {
                  if (val) {
                    setInfo(val);
                  } else {
                    form.setFieldsValue({
                      lon: null,
                      lat: null,
                      name: null,
                    });
                  }
                }}
              >
                {govsList &&
                  govsList.length > 0 &&
                  govsList.map((el) => (
                    <Option key={el.id} value={JSON.stringify(el)}>
                      {el.display_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Latitude" name="lat" rules={[{ required: true }]}>
              <Input placeholder={"Example: 44.3661"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Longitude"
              name="lon"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Example: 33.3152"} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
