import {
  ArrowLeftOutlined,
  SaveOutlined,
  MailOutlined,
  StopOutlined,
  ClockCircleOutlined,
  ProfileOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import { useOrderView } from "./store";
import authFunc from "../../helper/auth";
import { CreateApologyMsg } from "./createApologyMsg";
import { CreateCancelReason } from "./createCancelReason";

import {
  Button,
  Typography,
  Space,
  Row,
  Col,
  Divider,
  Spin,
  Alert,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { SubProductModal } from "./subProductModal";
import { BikerIcon } from "../../comonents/global";
import { PureMap } from "./map";
import { InvoiceInfo } from "./invoice";
import { StatusHistory } from "./statusHistory";
import { MoreInfo } from "./more";
import Countdown from "react-countdown";
import { useQuery, useMutation } from "react-query";
import {
  getOrderInfo,
  editOrder,
  sendToBiker,
  zeroBikerTotal,
} from "../../api";
import { useEffect, useState } from "react";

import { OrderInfo } from "./info";
import { EditTime } from "./editTime";
import handleDataMutation from "../../helper/dataMutation";
const { Text, Title } = Typography;

export const OrderViewPage = () => {
  const {
    setOrder,
    clientAddressID,
    clientComment,
    totalValue,
    basket,
    voucher,
    orderStatusHistory,
    setOrderStatusHistory,
    setIsCreateModal,
    setIsCreateCancelModal,
    setIsEditTimeModal,
    order,
  } = useOrderView();

  const navigate = useNavigate();
  const { id } = useParams();
  const [isOverTime, setIsOverTime] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [declineReasonMSG, setDeclineReasonMSG] = useState("");

  const { mutate, isLoading } = useMutation(editOrder, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "orderInfo",
      });
      navigate(-1);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: zeroBikerTotalMutate, isLoading: zeroBikerTotalLoading } =
    useMutation(zeroBikerTotal, {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "orderInfo",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const {
    isLoading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(["orderInfo", id], getOrderInfo, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  useEffect(() => {
    if (orderData && orderData?.success && orderData?.data) {
      setOrder(orderData?.data);
      let jsonStatusHis = JSON.parse(orderData?.data?.order?.status);
      setOrderStatusHistory(jsonStatusHis);
      let jsonStatus = JSON.parse(orderData?.data?.order?.status);
      setOrderStatus(jsonStatus?.pop()?.status);
      let getDeclineReason = JSON.parse(orderData?.data?.order?.declineReason);

      setDeclineReasonMSG(getDeclineReason?.arTitle);
    }
  }, [orderData]);

  let statusJson;

  if (orderData && orderData?.success && orderData?.data) {
    statusJson = JSON.parse(orderData?.data?.order?.status);
  }

  const handleSave = () => {
    let orderInfo = orderData?.data;
    let _order = {
      id: orderInfo?.order?.id,

      orderDetail: basket.map((el) => {
        return {
          productID: el.product.id,
          qty: el.qty,
          subProductGroup: el?.product?.subProductGroup?.map((group) => {
            return {
              id: group.id,
              multiSelect: group.multiSelect,
              subProductsIDS: group?.subProductsIDS?.map((item) => {
                return {
                  id: item.id,
                  price: item.price,
                  name: item.name,
                };
              }),
            };
          }),
        };
      }),
      client: {
        id: orderInfo?.client?.id,
      },
      clientAddress: {
        id: clientAddressID,
      },
      vendor: {
        id: orderInfo?.vendor?.id,
      },
      CityID: orderInfo?.order?.cityID,
      paymentTypeID: orderInfo?.order?.paymentTypeID,
      voucherID: voucher ? voucher.id : 0,
      voucherCode: voucher ? voucher.code : "",
      discountCapID: orderInfo?.vendor?.discountCap?.id || 0,
      declineReason: "{}",
      clientComment,
      clientCommentAddress: "",
      freeGift: false,
      platform: "IOS",
      preOrder: false,
      preOrderDeliveryTime: "2016-06-22 19:10:25-07",
      expectedDeliveryTime: "2016-06-22 19:10:25-07",
      bikerPickupDateTime: "2016-06-22 19:10:25-07",
    };
    mutate(_order);
  };

  const isSaveVisibale = () => {
    if (!clientAddressID && !clientComment && !totalValue) {
      return false;
    } else {
      let order = orderData?.data?.order;
      let orderClientAddressID = order?.clientAddressID;
      let orderClientComment = order?.clientComment;
      let orderValue =
        order?.subTotal - order?.discountTotalValue + order?.deliveryFee;
      let orderVoucher = order?.voucherTotalValue;
      let orderTotalValue = orderValue - orderVoucher;

      return orderClientAddressID !== clientAddressID ||
        orderClientComment !== clientComment ||
        totalValue !== orderTotalValue
        ? true
        : false;
    }
  };

  const { mutate: sendToBikerMutate, isLoading: sendToBikerLoading } =
    useMutation(sendToBiker, {
      onSuccess: (data) => {
        handleDataMutation({ mutatedData: data });
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const isStatus = (status) => {
    return orderStatusHistory.find((el) => el.status === status) ? true : false;
  };

  const handleBikerPanel = () => {
    window.open(
      `https://apanel.aws.snpb.app/panel/orders/${order?.order?.bikerOrderID}`
    );
  };

  const handleZeroBikerTotal = () => {
    zeroBikerTotalMutate({
      id: Number(order?.order?.id),
    });
  };

  if (orderError)
    return <Alert message={orderError.message} type="error" showIcon />;

  if (!orderLoading && !orderData.success)
    return <Alert message={orderData.msg} type="error" showIcon />;

  return orderLoading ? (
    <Spin tip="Loading . . ." spinning={true} style={{ marginTop: "40vh" }} />
  ) : (
    <div className="app-page order-view-page" style={{ marginTop: 0 }}>
      <div className="app-space-between">
        <Space>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/")}
          />

          <div>
            <Space>
              <Button
                size="small"
                type="text"
                icon={
                  <ClockCircleOutlined
                    style={{
                      fontSize: 18,
                      color:
                        orderStatus === "DELIVERED" ||
                        orderStatus === "REJECTED" ||
                        orderStatus === "BACKOFFICE_REJECTED"
                          ? "#ccc"
                          : isOverTime
                          ? "#f81866"
                          : "#000",
                    }}
                  />
                }
                onClick={() => setIsEditTimeModal(true)}
              />
              <Title
                style={{
                  margin: 0,
                  lineHeight: 1,
                  marginTop: 4,
                  color:
                    orderStatus === "DELIVERED" ||
                    orderStatus === "REJECTED" ||
                    orderStatus === "BACKOFFICE_REJECTED"
                      ? "#ccc"
                      : isOverTime
                      ? "#f81866"
                      : "#000",
                }}
                level={4}
              >
                <Countdown
                  overtime={
                    orderStatus !== "DELIVERED" &&
                    orderStatus !== "REJECTED" &&
                    orderStatus !== "BACKOFFICE_REJECTED"
                      ? true
                      : false
                  }
                  onTick={({ total }) => total < 0 && setIsOverTime(true)}
                  daysInHours={true}
                  date={orderData?.data?.order?.expectedDeliveryTime}
                />
              </Title>
              <Text>|</Text>
              <Title
                style={{
                  margin: 0,
                  lineHeight: 1,
                  marginTop: 4,

                  opacity: 0.6,
                }}
                level={4}
              >
                #{orderData?.data?.order?.trackNo}
              </Title>
            </Space>

            <br />
            <Text type="secondary" style={{ fontSize: 14 }}>
              View and edit Order information directly from this page.
            </Text>
          </div>
        </Space>
        <Space align="end">
          <Divider type="vertical" />
          <Button
            className="biker-btn"
            icon={<BikerIcon style={{ width: 20 }} />}
            disabled={!isStatus("MANUAL")}
            loading={sendToBikerLoading}
            onClick={() =>
              sendToBikerMutate({ id: orderData?.data?.order?.id })
            }
          >
            Send to biker
          </Button>
          <Button
            onClick={handleBikerPanel}
            icon={<ProfileOutlined />}
            disabled={!order?.order?.bikerOrderID}
          >
            Biker Panel Order
          </Button>
          <Popconfirm
            placement="topRight"
            title="Confirm Zero Biker Total?"
            disabled={!order?.order?.bikerOrderID}
            onConfirm={handleZeroBikerTotal}
          >
            <Button
              loading={zeroBikerTotalLoading}
              icon={<MinusCircleOutlined />}
              disabled={
                statusJson.slice(-1)[0].status === "BACKOFFICE_REJECTED" &&
                order?.order?.bikerOrderID
                  ? false
                  : true
              }
            >
              zero biker total
            </Button>
          </Popconfirm>
          <Button
            onClick={() => setIsCreateModal(true)}
            icon={<MailOutlined />}
            disabled={!authFunc("SEND_APOLOGY_VOUCHER")}
          >
            Send Apology message
          </Button>
          {basket.length > 0 &&
            orderStatus !== "ACCEPTED" &&
            orderStatus !== "DELIVERED" &&
            isSaveVisibale() && (
              <Popconfirm
                placement="topRight"
                title="Confirm Save!"
                onConfirm={handleSave}
              >
                <Button
                  loading={isLoading}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Save & Close
                </Button>
              </Popconfirm>
            )}

          {authFunc("ORDER_DELETE") && (
            <Tooltip
              placement="bottom"
              title={!isStatus("DELIVERED") ? declineReasonMSG : "DELIVERED"}
            >
              <Button
                disabled={
                  isStatus("DELIVERED") ||
                  isStatus("BACKOFFICE_REJECTED") ||
                  isStatus("REJECTED")
                }
                onClick={() => setIsCreateCancelModal(true)}
                icon={<StopOutlined />}
                type="primary"
                danger
              >
                Cancel Order
              </Button>
            </Tooltip>
          )}
        </Space>
      </div>
      <Divider />

      <Row gutter={[16, 30]}>
        <Col span={6}>
          <OrderInfo />
          <PureMap mapHeight={"18vw"} />
        </Col>

        <Col span={13}>
          <InvoiceInfo />
        </Col>

        <Col span={5}>
          <MoreInfo />
          <StatusHistory />
        </Col>
      </Row>
      {authFunc("SEND_APOLOGY_VOUCHER") && <CreateApologyMsg />}
      <CreateCancelReason />
      <SubProductModal />
      <EditTime />
    </div>
  );
};
