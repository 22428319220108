import { queryClient } from "../queryClient";
import { message } from "antd";

/**
 * @param {Object} mutatedData - the data returned from the backend
 * @param {String | Array} queryClientKey - the key of the useQuery
 * @param {Array | null} setterList - A list of setter functions to handle after request success
 * @param {Object | null} resetFunc - A callback function to reset the data
 */

const handleDataMutation = ({
  mutatedData,
  queryClientKey = null,
  setterList = null,
  resetFunc = null,
}) => {
  if (mutatedData.success) {
    message.success(mutatedData.msg);
    if (queryClientKey !== null) {
      if (typeof queryClientKey === "string") {
        queryClient.invalidateQueries(queryClientKey);
      } else if (queryClientKey.constructor === Array) {
        queryClientKey.forEach((qc) => {
          queryClient.invalidateQueries(qc);
        });
      }
    }

    if (setterList !== null) {
      setterList.forEach((setter) => {
        setter();
      });
    }
    if (resetFunc !== null) {
      resetFunc();
    }
  } else {
    message.error(mutatedData.msg);
  }
};

export default handleDataMutation;
