import { Select, Spin } from "antd";
import { useRegion } from "../../store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getGovs } from "../../api";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function RegionsFilters() {
  const {
    setQueryFilter,
    queryKey,
    setPage,
    provinceID,
    setProvinceID,
    setGovsSearch,
    govsSearch,
  } = useRegion();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: isGovsLoading,
    data: govs,
    refetch: govsRefetch,
  } = useQuery(["govs", govsSearch, 1], getGovs, {
    enabled: !!govsSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    let obj = {
      provinceID: searchParams.get("provinceID") || null,
    };
    setProvinceID(obj.provinceID);
  }, [searchParams]);
  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          notFoundContent={
            isGovsLoading ? (
              <div className="spin-wrapper">
                <Spin size="small" />
              </div>
            ) : null
          }
          allowClear
          showSearch
          onChange={(val) => {
            if (val != undefined) {
              setProvinceID(val);
              setPage(1);
              searchParams.set("provinceID", val);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setProvinceID(null);
            searchParams.delete("provinceID");
            setSearchParams(searchParams);
          }}
          onSearch={(val) => setGovsSearch(val)}
          placeholder="Select Province"
          style={{ width: "100%" }}
          value={provinceID}
          onClick={() => govsRefetch()}
        >
          {govs &&
            govs.success &&
            govs.data.records.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}
