import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { useState } from "react";
import Dragger from "antd/lib/upload/Dragger";
import { useProduct } from "./store";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import readXlsxFile from "read-excel-file";
import { useMutation, useQuery } from "react-query";

import { createProductsByFile, getVendors } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Option } = Select;

export const FileModal = () => {
  const { setIsFileModal, isFileModal } = useProduct();
  const [form] = Form.useForm();
  const [filter, setFilter] = useState("");
  const [isXlsx, setIsXlsx] = useState(true);

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        setIsXlsx(false);
        message.error(`${file.name} is not an excel file`);
      } else {
        setIsXlsx(true);
      }
      return false;
    },
  };

  const error = (msg) => {
    let errorMsg = msg.split("\n")[1].split(",");

    Modal.error({
      title: "Failed To Upload File",
      content: (
        <div style={{ overflow: "scroll", height: "400px" }}>
          {errorMsg.map((el, index) => (
            <p key={index}>{el}</p>
          ))}
        </div>
      ),
    });
  };

  const { isLoading: vendorLoading, data: vendors } = useQuery(
    ["vendors", filter],
    getVendors,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate, isLoading } = useMutation(createProductsByFile, {
    onSuccess: (data) => {
      console.log(data);
      if (data.success) {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "products",
          setterList: [() => setIsFileModal(false), () => form.resetFields()],
        });
      } else if (data.err.includes("Duplicate")) {
        message.error(
          data.err.match(/Duplicate product data found on line \d+ and \d+/)
        );
      } else {
        message.error(data.err);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    let apiData = [];
    let apiObj = {};
    readXlsxFile(values.file.file).then((rows) => {
      rows.map((el2, index) => {
        if (index !== 0) {
          apiObj = {
            barcode: String(el2[0]) || "",
            price: el2[1] || "",
            brand: el2[2] || "",
            category: el2[3] || "",
            subcategory: el2[4] || "",
            itemName: el2[5] || "",
            itemDescription: el2[6] || "",
            variationName: el2[7] || "",
            size: String(el2[8]) || "",
            unit: el2[9] || "",
            quantity: el2[10] || 0,
            limit: el2[11] || 0,
          };
          apiData.push(apiObj);
        }
      });
      let dataForm = {
        data: apiData,
        vendorID: values.vendorID,
      };
      mutate(dataForm);
    });
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}> Add Products From File</Title>}
      className="app-modal"
      centered
      visible={isFileModal}
      onCancel={() => {
        setIsFileModal(false);
        form.resetFields();
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="vendor"
              name="vendorID"
              rules={[{ required: true, message: "required" }]}
            >
              <Select
                loading={vendorLoading}
                showSearch
                filterOption={true}
                optionFilterProp="children"
                onSearch={(val) => setFilter(val)}
                style={{ width: "100%" }}
                placeholder="Find vendor"
                allowClear
              >
                {vendors?.success
                  ? vendors?.data?.records?.map((el) => (
                    <Option key={el.id} id={el.id} value={el.id}>
                      {el.arName}
                    </Option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="file"
              rules={[{ required: true }]}
              validateStatus={isXlsx ? "success" : "error"}
              help={isXlsx ? null : "Should be an excel file"}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag File to this area to upload
                </p>
                <p className="ant-upload-hint">select an excel file</p>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                disabled={!isXlsx}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
