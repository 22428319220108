import { Typography, Modal, Button, message } from "antd";
import { useVendor } from "./store";
import { PureMap } from "./map";
import { useMutation } from "react-query";
import { updateData } from "../../api";

import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const PureMapModal = ({ data }) => {
  const { isMapModal, setIsMapModal, lat, lon, id } = useVendor();

  const { mutate, isLoading } = useMutation(
    (e) => updateData("vendor/location", e),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendors",
          setterList: [() => setIsMapModal(false)],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleSave = () => {
    mutate({
      id,
      lat,
      lon,
    });
  };

  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={5}>
          {data && data?.arName}
        </Title>
      }
      className="app-modal"
      centered
      visible={isMapModal}
      onCancel={() => setIsMapModal(false)}
      footer={false}
      destroyOnClose={true}
      width={600}
      bodyStyle={{ paddingTop: 10 }}
    >
      {lon && lat ? (
        <>
          <PureMap location={[lon, lat]} />
          <br />
          {lon && lat && (
            <Button loading={isLoading} type="primary" onClick={handleSave}>
              Save Change
            </Button>
          )}
        </>
      ) : null}
    </Modal>
  );
};
