import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useTokens } from "./store";
import { useMutation } from "react-query";
import { resetClient } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const PureReset = () => {
  const { id, isReset, setIsReset } = useTokens();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(resetClient, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        setterList: [() => setIsReset(false)],
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    values.id = id;

    mutate(values);
  };

  return (
    <Modal
      title={<Title level={5}>Reset Password</Title>}
      className="app-modal"
      centered
      visible={isReset}
      onCancel={() => {
        setIsReset(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{ remember: true, price: 0 }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[{ required: true }]}
            >
              <Input placeholder="*****" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
