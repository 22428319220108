import create from "zustand";

export const useVendorPayment = create((set) => ({
  isPayModal: false,
  id: null,
  name: null,
  description: null,
  color: null,
  queryFilter: "",
  page: 1,
  vendorID: null,
  totalValue: null,
  from: null,
  to: null,
  isConfirmModal: false,

  currentFrom: null,
  currentTo: null,
  receiverName: null,
  payedAmount: null,

  setCurrentFrom: (currentFrom) => set({ currentFrom }),
  setCurrentTo: (currentTo) => set({ currentTo }),
  setReceiverName: (receiverName) => set({ receiverName }),
  setPayedAmount: (payedAmount) => set({ payedAmount }),

  setId: (id) => set({ id }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setVendorID: (vendorID) => set({ vendorID }),
  setTotalValue: (totalValue) => set({ totalValue }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setColor: (color) => set({ color }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setIsPayModal: (isPayModal) => set({ isPayModal }),
  setIsConfirmModal: (isConfirmModal) => set({ isConfirmModal }),
  setPage: (page) => set({ page }),
}));
