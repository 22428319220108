import {
  Modal,
  Typography,
  Space,
  Radio,
  Checkbox,
  Divider,
  Button,
} from "antd";
import { useApp } from "../../store";
import { useState, useEffect } from "react";

const { Title, Text } = Typography;

export const parseSubGroup = (subProductGroup) => {
  let groupsNames = subProductGroup
    ?.map((gruop) =>
      gruop?.subProductsIDS?.map((item) => item.name).join(" - ")
    )
    .join(" - ");
  let priceList = subProductGroup
    ?.map((gruop) => gruop?.subProductsIDS?.map((item) => item.price))
    .join()
    .split(",");
  let totalPrice = 0;
  priceList?.map((price) => {
    totalPrice = totalPrice + Number(price);
  });
  return { groupsNames, totalPrice };
};

export const SubProductModal = () => {
  const {
    isSubProduct,
    setIsSubProduct,
    setSubProductSelected,
    setSubProducstInit,
    subProducstInit,
    setIsSubProductEdit,
    setSubProductEditData,
    subProductEditData,
  } = useApp();

  const [localSelecte, setLocalSelecte] = useState([]);

  const handleSelected = (val, group) => {
    let newArr = [];
    let groupIndex = localSelecte.findIndex((el) => el.id === group.id);
    if (groupIndex === -1) {
      newArr = [...localSelecte, { ...group, subProductsIDS: val }];
    } else {
      localSelecte[groupIndex].subProductsIDS = val;
      newArr = [...localSelecte];
    }

    setLocalSelecte(newArr);
  };

  useEffect(() => {
    if (subProductEditData) {
      setLocalSelecte(
        subProductEditData?.product.subProductGroup.map((el) => el)
      );
    } else {
      setLocalSelecte([]);
    }
  }, [subProductEditData]);

  return (
    <Modal
      title={<Title level={5}>Topping Info</Title>}
      className="app-modal"
      centered
      visible={isSubProduct}
      onCancel={() => {
        setIsSubProduct(false);
        setSubProducstInit(null);
        setSubProductSelected(null);
        setSubProductEditData(null);
        setIsSubProductEdit(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
      bodyStyle={{ paddingTop: 6 }}
    >
      <br />
      {subProducstInit?.product?.subProductGroup
        ?.filter((group) => group?.SubProducts?.length > 0)
        .map((group) => (
          <div className="sub-products-items">
            {
              <>
                <Text>{group.name}</Text>
                <div className="sub-content">
                  {group.multiSelect ? (
                    <Checkbox.Group
                      onChange={(ids) => {
                        let items = group?.SubProducts?.filter((el) =>
                          ids.find((id) => id === el.id)
                        );
                        handleSelected(items, group);
                      }}
                      value={localSelecte
                        ?.find((el) => el.id === group.id)
                        ?.subProductsIDS?.map((el) => el.id)}
                    >
                      <Space wrap>
                        {group?.SubProducts?.map((el) => (
                          <Checkbox value={el.id}>
                            <Space>
                              <Text>
                                <b>
                                  (
                                  {el?.price
                                    ? Number(el?.price).toLocaleString() + "IQD"
                                    : "free"}
                                  )
                                </b>
                                {" " + el.name}
                              </Text>
                            </Space>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  ) : (
                    <Radio.Group
                      onChange={(e) => {
                        let id = e.target.value;
                        let items = group?.SubProducts?.filter(
                          (el) => id === el.id
                        );

                        handleSelected(items, group);
                      }}
                      value={
                        localSelecte.find((el) => el.id === group.id)
                          ?.subProductsIDS[0]?.id
                      }
                    >
                      <Space wrap>
                        {group?.SubProducts?.map((el) => (
                          <Radio value={el.id}>
                            <Space>
                              <Text>
                                <b>
                                  (
                                  {el?.price
                                    ? Number(el?.price).toLocaleString() + "IQD"
                                    : "free"}
                                  )
                                </b>
                                {" " + el.name}
                              </Text>
                            </Space>
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  )}
                </div>
              </>
            }
          </div>
        ))}
      <Divider />

      <Button
        disabled={localSelecte.length === 0}
        type="primary"
        onClick={() => {
          let data = {
            qty: subProducstInit.qty,
            product: {
              ...subProducstInit.product,
              subProductGroup: localSelecte,
            },
          };
          setSubProductSelected(data);
          setIsSubProduct(false);
        }}
      >
        Confirm{" "}
        {localSelecte.length > 0 &&
          "( " + parseSubGroup(localSelecte).totalPrice + " IQD )"}
      </Button>
    </Modal>
  );
};
