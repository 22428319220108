import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useCategory } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createCategory, editCategory } from "../../api";
import Dragger from "antd/lib/upload/Dragger";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    name,
    setName,
    priority,
    setPriority,
  } = useCategory();

  const resetState = () => {
    setName(null);
    setId(null);
    setPriority(null);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const { mutate, isLoading } = useMutation(createCategory, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "category",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editCategory,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "category",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      values.parentID = 0;
      editMutate(values);
    } else {
      values.img = values.img.file;
      values.parentID = 0;
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Category</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, name, priority }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Category Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Category name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                placeholder="Category Display Priority"
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
          {!id ? (
            <Col span={24}>
              <Form.Item name="img" rules={[{ required: true }]}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag Image to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    This Image will view in category details
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
