import create from "zustand";

export const useVoucherSettings = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  id: null,
  voucherTitle: null,
  message: null,
  balyValue: null,
  vendorValue: null,
  type: null,
  valueType: null,
  maxAmount: null,
  minAmount: null,
  numberOfDays: null,
  applyOverDiscount:false,
  avToCustomers: false,
  customerDesc:null, 
  platform :[],


  setPlatform: (platform) => set({platform}),
  setQueryKey: (queryKey) => set({ queryKey }),

  setId: (id) => set({ id }),
  setVoucherTitle: (voucherTitle) => set({ voucherTitle }),
  setMessage: (message) => set({ message }),
  setBalyValue: (balyValue) => set({ balyValue }),
  setVendorValue: (vendorValue) => set({ vendorValue }),
  setType: (type) => set({ type }),
  setValueType: (valueType) => set({ valueType }),
  setMaxAmount: (maxAmount) => set({ maxAmount }),
  setMinAmount: (minAmount) => set({ minAmount }),
  setNumberOfDays: (numberOfDays) => set({ numberOfDays }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setApplyOverDiscount: (applyOverDiscount) => set({ applyOverDiscount }),
  setAvToCustomers: (avToCustomers) => set({ avToCustomers }),
  setCustomerDesc: (customerDesc) => set({ customerDesc })
}));
