import create from "zustand";

export const useBlockReason = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,

  queryKey: "",

  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),

  setName: (name) => set({ name }),

  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
