import { Card, Input, Space, Button, Breadcrumb } from "antd";

import { UploadOutlined, FilterOutlined } from "@ant-design/icons";
import { PureTable } from "./table";

const { Search } = Input;

export const RolesPage = () => {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>

        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>User Roles</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search style={{ width: 340 }} placeholder="Write To Search..." />
            <Button icon={<FilterOutlined />} />
          </Space>
          <Space>
            <Button icon={<UploadOutlined />}>Export</Button>
          </Space>
        </div>
        <br />
        <PureTable />
      </Card>
    </div>
  );
};
