import { Button, Modal, Typography } from "antd";

import { useVendorLog } from "./store";
import { CustomeSchedule } from "../../comonents/global";
import { SaveOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const WorkingSchedule = () => {
  const { isSchedual, setIsSchedule, workingSchedule } = useVendorLog();

  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={5}>
          Working Days
        </Title>
      }
      className="app-modal"
      centered
      visible={isSchedual}
      onCancel={() => setIsSchedule(false)}
      footer={false}
      destroyOnClose={true}
      width={"90vw"}
      bodyStyle={{ paddingTop: 10, minHeight: "75vh" }}
    >
      <CustomeSchedule value={workingSchedule} fromInit={12} />
      <Button
        style={{ position: "absolute", bottom: 35, left: 35 }}
        type="primary"
        icon={<SaveOutlined />}
        disabled={true}
      >
        Save
      </Button>
    </Modal>
  );
};
