import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { createZone, editZone } from "../../api";
import { PureMapDrow } from "./mapDrow";
import { useMutation } from "react-query";

import { useZone } from "./store";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const PureCreate = () => {
  let {
    isCreateModal,
    setIsCreateModal,
    name,
    id,
    area,
    setId,
    setArea,
    setName,
  } = useZone();

  const [form] = Form.useForm();

  let resetState = () => {
    setId(null);
    setArea(null);
    setName(null);
  };

  const { mutate, isLoading: addLoagin } = useMutation(createZone, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "zones",
        setterList: [
          () => setIsCreateModal(false),
          () =>
            form.setFieldsValue({
              name: null,
            }),
        ],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editeMutate, isLoading: editLoading } = useMutation(
    editZone,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "zones",
          setterList: [
            () => setIsCreateModal(false),
            () =>
              form.setFieldsValue({
                name: null,
              }),
          ],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.area = JSON.stringify(area);

    if (id) {
      values.id = id;
      editeMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edite" : "Create"} Zone</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={700}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, name, area }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <Form.Item
              label="Zone Name"
              name="name"
              value={name}
              onChange={(val) => setName(val)}
              rules={[{ required: true }]}
            >
              <Input placeholder="Write City Name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <PureMapDrow />
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={addLoagin || editLoading}
                disabled={name && area ? false : true}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
