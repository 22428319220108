import { Layout, Dropdown, Space, Typography, Avatar, Menu } from "antd";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  FieldTimeOutlined,
  NodeIndexOutlined,
  LockOutlined,
  MessageOutlined,
  FileTextOutlined,
  BookOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useApp } from "../store";
import { useState, useEffect } from "react";
import { EditAccount } from "../pages/account";
import balyLogo from "../assets/images/baly-logo.png";
import authFunc from "../helper/auth";

const { Header } = Layout;
const { Title, Text } = Typography;
const { SubMenu } = Menu;

export const AppHeader = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const { setIsLogin, setIsEditAccount, setIsReset, setUser, user } = useApp();
  const [current, setCurrent] = useState("orders");

  useEffect(() => {
    setCurrent(location.pathname.substr(1));
  }, [location]);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsEditAccount(true)}>
        <Space>
          <UserOutlined />
          Edit Account
        </Space>
      </Menu.Item>
      <Menu.Item onClick={() => setIsReset(true)}>
        <Space>
          <LockOutlined />
          Change Password
        </Space>
      </Menu.Item>
      <Menu.Item
        danger
        onClick={() => {
          localStorage.clear("groceryBO_token");
          setUser(null);
          setIsLogin(false);
        }}
      >
        <Space>
          <LogoutOutlined />
          Sign Out
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="app-header">
      <div className="app-space-between app-container">
        <Space align="center" style={{ flex: 1 }}>
          <img alt="baly logo" width={"70px"} src={balyLogo} />
          <Title style={{ margin: 0, marginTop: 13, fontSize: 14 }} level={5}>
            Grocery Dashboard
          </Title>
        </Space>

        <Menu
          onClick={(e) => {
            navigate(`/${e.key}`);
            setCurrent(e.key);
          }}
          selectedKeys={[current]}
          mode="horizontal"
          className="app-menu"
          style={{ flex: 3 }}
        >
          <SubMenu key="orders" icon={<ShoppingCartOutlined />} title="Orders">
            <Menu.Item key="">Order List</Menu.Item>
            <Menu.Item key="delayed_orders">Delayed Orders</Menu.Item>
            <Menu.Item
              key="order_status"
              disabled={!authFunc("ORDER_STATUS_READ")}
            >
              Order Status
            </Menu.Item>
            <Menu.Item
              key="payment_typ"
              disabled={!authFunc("PAYMENT_TYPE_READ")}
            >
              Payment Type
            </Menu.Item>
            <Menu.Item
              key="review_reasons"
              disabled={!authFunc("REVIEW_REASON_READ")}
            >
              Review Reasons
            </Menu.Item>
            <Menu.Item
              key="decline_reason"
              disabled={!authFunc("DECLINE_READ")}
            >
              Decline Reason
            </Menu.Item>
          </SubMenu>

          <SubMenu key="vendor" icon={<ShopOutlined />} title="Vendor">
            <Menu.Item key="vendors" disabled={!authFunc("VENDOR_READ")}>
              vendor List
            </Menu.Item>
            <Menu.Item key="products" disabled={!authFunc("PRODUCT_READ")}>
              product List
            </Menu.Item>
            <Menu.Item
              key="vendor_payment"
              disabled={!authFunc("VENDOR_PAYMENT_READ")}
            >
              Vendor Payment
            </Menu.Item>
          </SubMenu>

          <SubMenu key="users" icon={<UsergroupAddOutlined />} title="Users">
            <Menu.Item key="clients" disabled={!authFunc("CLIENT_READ")}>
              Clients
            </Menu.Item>
            <Menu.Item
              key="vendor_user"
              disabled={!authFunc("VENDOR_USER_READ")}
            >
              Vendors Users
            </Menu.Item>
            <Menu.Item key="employees" disabled={!authFunc("USER_READ")}>
              Employees
            </Menu.Item>
            <Menu.Item key="tokens" disabled={!authFunc("TOKEN_READ")}>
              Access Tokens
            </Menu.Item>
            <Menu.Item key="authGroup" disabled={!authFunc("AUTH_GROUP_READ")}>
              Auth Group
            </Menu.Item>
            <Menu.Item
              key="block_reason"
              disabled={!authFunc("BLOCK_REASON_READ")}
            >
              Block Reason
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="marketing"
            icon={<LineChartOutlined />}
            title="Marketing"
          >
            <Menu.Item
              key="vouchers"
              icon={<DollarCircleOutlined />}
              disabled={!authFunc("VOUCHER_READ")}
            >
              Vouchers
            </Menu.Item>
            <Menu.Item
              key="compound_vouchers"
              icon={<DollarCircleOutlined />}
              disabled={!authFunc("VOUCHER_READ")}
            >
              Compound Voucher
            </Menu.Item>
            <Menu.Item
              key="bulk_voucher"
              icon={<BookOutlined />}
              disabled={!authFunc("VOUCHER_READ")}
            >
              Bulk Voucher
            </Menu.Item>
            <Menu.Item
              key="discounts"
              icon={<ShopOutlined />}
              disabled={!authFunc("VENDOR_DISCOUNT_READ")}
            >
              Discounts
            </Menu.Item>
            <Menu.Item
              key="delivery_discounts"
              icon={<NodeIndexOutlined />}
              disabled={!authFunc("DELIVERY_DISCOUNT_READ")}
            >
              Delivery Discounts
            </Menu.Item>
            <Menu.Item
              key="delivery_fee"
              icon={<NodeIndexOutlined />}
              disabled={!authFunc("FEE_READ")}
            >
              Delivery Distance Fee
            </Menu.Item>
            <Menu.Item
              key="delivery_time"
              icon={<FieldTimeOutlined />}
              disabled={!authFunc("DISTANCE_READ")}
            >
              Delivery Time
            </Menu.Item>
            {/* <Menu.Item
              key="discountCap"
              icon={<ShopOutlined />}
              disabled={!authFunc("DISCOUNT_CAP_READ")}
            >
              Discount Cap
            </Menu.Item> */}
          </SubMenu>
          <SubMenu key="fees" icon={<DollarOutlined />} title="Fees">
            <Menu.Item key="service_fee" disabled={!authFunc("FEE_READ")}>
              Service Fee
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="ratings"
            icon={<MessageOutlined />}
            title="Customers Rates"
          >
            <Menu.Item
              key="products_review"
              disabled={!authFunc("PRODUCT_REVIEW_READ")}
            >
              Products Review
            </Menu.Item>
            <Menu.Item
              key="vendors_review"
              disabled={!authFunc("VENDOR_REVIEW_READ")}
            >
              Vendors Review
            </Menu.Item>
            <Menu.Item
              key="biker_review"
              disabled={!authFunc("VENDOR_REVIEW_READ")}
            >
              Biker Review
            </Menu.Item>
          </SubMenu>

          <SubMenu key="logs" icon={<FileTextOutlined />} title="Logs">
            <Menu.Item key="vendor_log" disabled={!authFunc("VENDOR_LOG_READ")}>
              Vendor Log
            </Menu.Item>
          </SubMenu>

          <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
            <Menu.Item key="categories" disabled={!authFunc("CATEGORY_READ")}>
              Category
            </Menu.Item>
            <Menu.Item key="brand" disabled={!authFunc("BRAND_READ")}>
              Brand
            </Menu.Item>
            <Menu.Item
              key="vendor_categories"
              disabled={!authFunc("PRODUCT_READ")}
            >
              vendor categories
            </Menu.Item>
            <Menu.Item key="governorates" disabled={!authFunc("AREA_READ")}>
              Province
            </Menu.Item>
            <Menu.Item key="regions" disabled={!authFunc("AREA_READ")}>
              Cities
            </Menu.Item>
            <Menu.Item key="zones" disabled={!authFunc("AREA_READ")}>
              Zones
            </Menu.Item>
            <Menu.Item key="app_banners" disabled={!authFunc("APPBANNER_READ")}>
              App Banners
            </Menu.Item>
            <Menu.Item
              key="status_codes"
              disabled={!authFunc("STATUS_CODE_READ")}
            >
              Status Codes
            </Menu.Item>
            <Menu.Item
              key="referal_codes"
              disabled={!authFunc("REFERRAL_CODE_READ")}
            >
              Referal Codes
            </Menu.Item>
            <Menu.Item key="PWA_settings" disabled={!authFunc("SETTING_READ")}>
              PWA settings
            </Menu.Item>
            <Menu.Item
              key="VMS_settings"
              disabled={!authFunc("VMS_SETTING_READ")}
            >
              VMS settings
            </Menu.Item>
            <Menu.Item
              key="voucher_settings"
              disabled={!authFunc("VOUCHER_SETTING_READ")}
            >
              Voucher Settings
            </Menu.Item>

            <Menu.Item
              key="msg_template"
              disabled={!authFunc("MSG_TEMPLATE_READ")}
            >
              MSG Template
            </Menu.Item>
            <Menu.Item
              key="referral_settings"
              disabled={!authFunc("REFERRAL_SETTING_READ")}
            >
              Referral Settings
            </Menu.Item>
            <Menu.Item
              key="apology_settings"
              disabled={!authFunc("APOLOGY_SETTING_READ")}
            >
              Apology Settings
            </Menu.Item>
          </SubMenu>
        </Menu>
        <Space style={{ flex: 1 }} className="app-justify-right">
          <Dropdown overlay={menu}>
            <Space
              size={5}
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <DownOutlined />
              <Text>{user ? user.name : "..."}</Text>
            </Space>
          </Dropdown>
          <Avatar size="small" icon={<UserOutlined />} />
        </Space>
      </div>
      <EditAccount />
      {/* <ChangePassword /> */}
    </Header>
  );
};
