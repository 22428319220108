import { useState, useEffect ,useMemo } from "react";
import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Switch,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import { getPwaSettings, removePwaSettings, editPwaSettings } from "../../api";
import { usePwaSettings } from "./store";
import numberForamt from "../../helper/numberFormat";

import handleDataMutation from "../../helper/dataMutation";

export const PuerTable = () => {

  const [page, setPage] = useState(1);
  const {
    setId,
    setAllowedVersion,
    setBackendVersion,
    setMessage,
    setIsCreateModal,
    queryKey,
    setSuspendPrice,
    setOrderCancelTime,
  } = usePwaSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setAllowedVersion(row.allowedVersion);
    setBackendVersion(row.backendVersion);
    setMessage(row.message);
    setSuspendPrice(row.suspendPrice);
    setOrderCancelTime(row.orderCancelTime);
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Allowed Version",
      dataIndex: "allowedVersion",
      key: "allowedVersion",
    },
    {
      title: "Cancellation Time",
      dataIndex: "orderCancelTime",
      key: "orderCancelTime",
    },
    {
      title: "Backend Version",
      dataIndex: "backendVersion",
      key: "backendVersion",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Suspend Price",
      dataIndex: "suspendPrice",
      key: "suspendPrice",
      render: (val) => numberForamt(val),
    },
    {
      title: "Status",
      key: "id",
      render: (id, row, index) => (
        <Switch
          loading={editLoading}
          checked={row.appStatus}
          onChange={(state) => handleChangeActive(row)}
          disabled={index !== 0 || !authFunc("SETTING_UPDATE") ? true : false}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row, index) => (
        <Space>
          {authFunc("SETTING_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              disabled={index !== 0 ? true : false}
              icon={<EditOutlined />}
            />
          )}
          {authFunc("SETTING_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => removeMutate(id)}
              disabled={
                row.mandatory === "true"
                  ? true
                  : false || index !== 0
                  ? true
                  : false
              }
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={
                  row.mandatory === "true"
                    ? true
                    : false || index !== 0
                    ? true
                    : false
                }
                size="small"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editPwaSettings,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "pwaSettings",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      row.appStatus = !row.appStatus;

      editMutate(row);
    }
  };

  const { mutate: removeMutate } = useMutation(removePwaSettings, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "pwaSettings",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["pwaSettings", queryKey], getPwaSettings, {
    refetchOnWindowFocus: false,
    retry: 2,
  });



  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
