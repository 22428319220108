import create from "zustand";

export const useCategory = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,
  queryKey: "",
  priority: 0,

  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPriority: (priority) => set({ priority }),
}));
