import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Tag,
  Space,
  Tooltip,
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  LikeOutlined,
  LikeFilled,
  DislikeFilled,
  DislikeOutlined,
} from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useReviewReason } from "./store";
import { useMutation } from "react-query";

import { createReviewReasons, editReviewReasons } from "../../api";
import { useEffect, useRef, useState } from "react";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    setType,
    setTitle,
    setCategory,
    title,
    category,
    type,
  } = useReviewReason();

  const [titlesArray, setTitlesArray] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [chessenCat, setChessenCat] = useState(null);
  const [inputPriority, setInputPriority] = useState("");
  const inputRef = useRef(null);
  const [liked, setLiked] = useState(null);
  const editInputRef = useRef(null);

  const resetstate = () => {
    setId(null);
    setType(null);
    setTitle(null);
    setCategory(null);
    setChessenCat(null);
    setTitlesArray([]);
    setLiked(null);
  };

  const LikeButton = () => {
    const handleLikeClick = () => {
      setLiked(5);
    };

    return (
      <Button
        type={liked === 5 ? "primary" : "default"}
        shape="round"
        onClick={handleLikeClick}
        icon={liked ? <LikeFilled /> : <LikeOutlined />}
      >
        Like
      </Button>
    );
  };
  const DisLikeButton = () => {
    const handleLikeClick = () => {
      setLiked(1);
    };

    return (
      <Button
        type={liked === 1 ? "primary" : "default"}
        shape="round"
        onClick={handleLikeClick}
        icon={liked === 1 ? <DislikeFilled /> : <DislikeOutlined />}
      >
        DisLike
      </Button>
    );
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputIndex]);

  const handleClose = (removedTag) => {
    const newTags = titlesArray?.filter(
      (tagObj) => tagObj.title !== removedTag
    );
    setTitlesArray(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue.trim() !== "") {
      setInputVisible(false);
      setInputValue("");
      setInputPriority("");
      setTitlesArray([
        ...titlesArray,
        { priority: Number(inputPriority), title: inputValue },
      ]);
    }
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...titlesArray];
    newTags[editInputIndex].title = editInputValue;
    setTitlesArray(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const tagInputStyle = {
    width: "100%",
    verticalAlign: "top",
  };
  const { mutate, isLoading } = useMutation(createReviewReasons, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "reviewReasons",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetstate,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editReviewReasons,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "reviewReasons",
          setterList: [() => setIsCreateModal(false), () => setId(null)],
          resetFunc: resetstate,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  //'VENDOR','CLIENT','BIKER','BACKOFFICE_USER'

  const onFinish = (values) => {
    values.type = chessenCat === "VENDOR" ? values.type : String(liked);
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      values.title = titlesArray;
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Review Reasons</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetstate();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          title,
          category,
          type,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
              {id ? (
                <Input placeholder="Write a title" />
              ) : (
                <Space size={[0, 8]} wrap>
                  <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                    <Space size={[0, 8]} wrap>
                      {titlesArray.map((tagObj, index) => {
                        const { priority, title } = tagObj;
                        if (editInputIndex === index) {
                          return (
                            <Input
                              ref={editInputRef}
                              key={title}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                              placeholder="Edit title"
                            />
                          );
                        }
                        const isLongTag = title.length > 20;
                        const tagElem = (
                          <Tag
                            key={title}
                            closable={true}
                            style={{
                              userSelect: "none",
                            }}
                            onClose={() => handleClose(title)}
                            onDoubleClick={(e) => {
                              setEditInputIndex(index);
                              setEditInputValue(`${priority}: ${title}`);
                              e.preventDefault();
                            }}
                          >
                            <span>
                              {`${priority}: ${
                                isLongTag ? `${title.slice(0, 20)}...` : title
                              }`}
                            </span>
                          </Tag>
                        );
                        return isLongTag ? (
                          <Tooltip title={`${priority}: ${title}`} key={title}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })}
                    </Space>
                  </div>

                  {inputVisible ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        type="number"
                        size="medium"
                        style={{ ...tagInputStyle, width: 70 }}
                        value={inputPriority}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (!isNaN(value) && Number(value) >= 1) {
                            setInputPriority(value);
                          }
                        }}
                        onPressEnter={handleInputConfirm}
                        placeholder="Prio"
                      />
                      <Input
                        ref={inputRef}
                        type="text"
                        size="medium"
                        style={tagInputStyle}
                        value={inputValue}
                        onChange={handleInputChange}
                        onPressEnter={handleInputConfirm}
                        placeholder="New Title"
                      />
                    </div>
                  ) : (
                    <Tag style={{ borderStyle: "dashed" }} onClick={showInput}>
                      <PlusOutlined /> New Title
                    </Tag>
                  )}
                </Space>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Choose Category"
                onChange={(val) => setChessenCat(val)}
              >
                <Option key="VENDOR" values={"VENDOR"}>
                  VENDOR
                </Option>
                <Option key="PRODUCT" values={"PRODUCT"}>
                  PRODUCT
                </Option>
                <Option key="BIKER" values={"BIKER"}>
                  BIKER
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            {chessenCat === "VENDOR" ? (
              <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                <Select
                  placeholder="Choose Type"
                  disabled={chessenCat === null}
                >
                  <Option key="1" values={"1"}>
                    1
                  </Option>
                  <Option key="2" values={"2"}>
                    2
                  </Option>
                  <Option key="3" values={"3"}>
                    3
                  </Option>
                  <Option key="4" values={"4"}>
                    4
                  </Option>
                  <Option key="5" values={"5"}>
                    5
                  </Option>
                </Select>
              </Form.Item>
            ) : (
              <Space direction="horizontal">
                <LikeButton />
                <DisLikeButton />
              </Space>
            )}
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Tooltip
                title={
                  !id && titlesArray?.length === 0
                    ? "add titles first"
                    : chessenCat !== "VENDOR" && liked === null
                    ? "choose like or dislike first"
                    : ""
                }
              >
                <Button
                  loading={isLoading || editLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  disabled={
                    (!id && titlesArray?.length === 0) ||
                    (chessenCat !== "VENDOR" && liked === null)
                  }
                >
                  Save
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
