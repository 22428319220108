import { useEffect, useState } from "react";
import { Row, Col } from "antd";


export const ColorPicker = ({value , onChange}) => {
  const colors = ["#d9d9d9","#53c519", "#f50","#ff4d4e" , "#2db7f5", "#eb2f96", "#722ed1", "#faad13"];
  const [select, setSelect] = useState(value || colors[0]);

  useEffect(()=>{
    onChange(select)
  }, [select])

  return (
    <Row gutter={[6, 6]}>
      {colors.map((color) => (
        <Col key={color} span={3} onClick={()=> setSelect(color)}>
          <div style={{ background: color , borderColor: select == color ? "#0000ffba": "transparent"}} className={"color-item"}>
            <span className={select == color ? "active" : ""}></span>  </div>
        </Col>
      ))}
    </Row>
  );
};
