import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Upload,
  notification,
} from "antd";
import {
  SaveOutlined,
  PictureOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useProductVariation } from "./store";
import { useMutation } from "react-query";

import { createProductVariation, editProductVariation } from "../../api";
import { useParams } from "react-router-dom";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    name,
    setName,
    dimension,
    setDimension,
    unit,
    setUnit,
    size,
    setSize,
    barcode,
    setBarcode,
    img,
  } = useProductVariation();

  const images = JSON.parse(img);
  const { productID } = useParams();

  const resetState = () => {
    setName(null);
    setId(null);
    setDimension(null);
    setUnit(null);
    setSize(null);
    setBarcode(null);
  };

  const bannerProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };
  const fileList1 = {
    uid: "1",
    name: images && images.length > 0 ? "Image1" : "no Image",
    status: "done",
    url: images && images.length > 0 ? images[0] : "",
    thumbUrl: images && images.length > 0 ? images[0] : "",
  };
  const fileList2 = {
    uid: "2",
    name: images && images.length > 1 ? "Image2" : "no Image",
    status: "done",
    url: images && images.length > 0 ? images[1] : "",
    thumbUrl: images && images.length > 0 ? images[1] : "",
  };
  const fileList3 = {
    uid: "3",
    name: images && images.length > 2 ? "Image3" : "no Image",
    status: "done",
    url: images && images.length > 0 ? images[2] : "",
    thumbUrl: images && images.length > 0 ? images[2] : "",
  };

  const { mutate, isLoading } = useMutation(createProductVariation, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "productVariation",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editProductVariation,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "productVariation",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });

      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (!values.name && !values.unit && !values.size) {
      notification.warn({
        title: "Warning: Required Fields Missing.",
        description: "Please, Either Name or Size or Unit must be filled",
      });

      return;
    }

    if (id) {
      let deleteImages = [];
      if (values?.imgFile2?.file?.status === "removed") {
        deleteImages.push(1);
      }
      if (values?.imgFile3?.file?.status === "removed") {
        deleteImages.push(2);
      }

      let _data = {
        id: id,
        name: values.name ? values.name : "",
        size: values.size || "",
        unit: values.unit ? values.unit : "",
        dimension: values.dimension,
        productID: productID,
        barcode: values.barcode ? values.barcode : "",
        imgFile0: values.imgFile1.file ? values.imgFile1.file : values.imgFile1,
        imgFile1: values.imgFile2.file
          ? values?.imgFile2?.file?.status === "removed"
            ? null
            : values.imgFile2.file
          : values.imgFile2,
        imgFile2: values.imgFile3.file
          ? values?.imgFile3?.file?.status === "removed"
            ? null
            : values.imgFile3.file
          : values.imgFile3,
        deleteImages: JSON.stringify(deleteImages),
      };
      editMutate(_data);
    } else {
      let _data = {
        name: values.name ? values.name : "",
        size: values.size || "",
        unit: values.unit ? values.unit : "",
        dimension: values.dimension,
        productID: productID,
        barcode: values.barcode ? values.barcode : "",
        imgFile0: values.imgFile1.file,
        imgFile1: values.imgFile2 ? values.imgFile2.file : null,
        imgFile2: values.imgFile3 ? values.imgFile3.file : null,
      };
      mutate(_data);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Product Variation</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={700}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          name,
          dimension,
          unit,
          size,
          barcode,
          imgFile1: (images && images[0]) || "",
          imgFile2: (images && images[1]) || "",
          imgFile3: (images && images[2]) || "",
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Product Variation Name" name="name">
              <Input placeholder="product variation name" />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item
              label="Dimension"
              name="dimension"
              rules={[{ required: true }]}
            >
              <Input rows={4} placeholder={"ex: 10*5"} />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="Size" name="size">
              <Input rows={4} placeholder={"product variation size"} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Unit" name="unit">
              <Input placeholder="Unit" />
            </Form.Item>
          </Col>
          {!id ? (
            <Col span={8}>
              <Form.Item
                label="Image 1"
                name="imgFile1"
                rules={[{ required: true }]}
              >
                <Dragger {...bannerProps}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Product Images</p>
                  <p className="ant-upload-hint">
                    Drag Images to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : (
            <Col span={8}>
              <Form.Item
                label="Image 1"
                name="imgFile1"
                rules={[{ required: true }]}
              >
                <Upload
                  listType="picture"
                  defaultFileList={[fileList1]}
                  showUploadList={{ showRemoveIcon: false }}
                  {...bannerProps}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          )}
          {!id ? (
            <Col span={8}>
              <Form.Item label="Image 2" name="imgFile2">
                <Dragger {...bannerProps}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Product Images</p>
                  <p className="ant-upload-hint">
                    Drag Images to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : (
            <Col span={8}>
              <Form.Item label="Image 2" name="imgFile2">
                <Upload
                  listType="picture"
                  defaultFileList={[fileList2]}
                  showUploadList={
                    images && images.length > 1
                      ? { showRemoveIcon: true }
                      : { showRemoveIcon: false }
                  }
                  {...bannerProps}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          )}
          {!id ? (
            <Col span={8}>
              <Form.Item label="Image 3" name="imgFile3">
                <Dragger {...bannerProps}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Product Images</p>
                  <p className="ant-upload-hint">
                    Drag Images to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : (
            <Col span={8}>
              <Form.Item label="Image 3" name="imgFile3">
                <Upload
                  listType="picture"
                  defaultFileList={[fileList3]}
                  showUploadList={
                    images && images.length > 2
                      ? { showRemoveIcon: true }
                      : { showRemoveIcon: false }
                  }
                  {...bannerProps}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
