import { useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  Input,
  message,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDeliveryDistance } from "./store";
import { useMutation } from "react-query";
import { editDistance, createDistance } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";

const { Title, Link } = Typography;
const { TextArea } = Input;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    name,
    description,
    domains,
    setName,
    setDescription,
    setDomains,
  } = useDeliveryDistance();

  const [form] = Form.useForm();

  let resetState = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setDomains(null);
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        description,
        domains,
      });
    } else {
      resetState();
    }
  }, [id]);

  const { mutate, isLoading: addLoading } = useMutation(createDistance, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "deliveryDistance",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editDistance,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "deliveryDistance",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (valuse) => {
    if (id) {
      valuse.id = id;
      editMutate(valuse);
    } else {
      mutate(valuse);
    }
  };

  return (
    <Modal
      forceRender
      title={
        <Title level={5}>{id ? "Edit" : "Create"} Delivery Distance</Title>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={450}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder={"Baly Express"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <TextArea placeholder={"Note"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.List name="domains">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      className="distanc-list"
                      label={index === 0 ? "Distance (m)" : ""}
                      required={true}
                      key={field.key}
                    >
                      <Row gutter={[10, 20]} style={{ marginTop: 10 }}>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "from"]}
                            rules={[{ required: true, message: "" }]}
                          >
                            <InputNumber
                              formatter={(value) => inputNumberFormatter(value)}
                              parser={(value) =>
                                value.replace(/\\s?|(,*)/g, "")
                              }
                              placeholder="From"
                              style={{ width: "100%" }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "to"]}
                            rules={[{ required: true, message: "" }]}
                          >
                            <InputNumber
                              formatter={(value) => inputNumberFormatter(value)}
                              parser={(value) =>
                                value.replace(/\\s?|(,*)/g, "")
                              }
                              placeholder="To"
                              style={{ width: "100%" }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item
                            name={[field.name, "time"]}
                            rules={[{ required: true, message: "" }]}
                          >
                            <InputNumber
                              formatter={(value) => inputNumberFormatter(value)}
                              parser={(value) =>
                                value.replace(/\\s?|(,*)/g, "")
                              }
                              placeholder="Time"
                              style={{ width: "100%" }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          span={3}
                          style={{
                            textAlign: "end",
                          }}
                        >
                          <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <br />
                    <Link onClick={() => add()}>+ New Distance</Link>
                    <Form.ErrorList />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={addLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
