import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Space,
  Divider,
} from "antd";

import { useApp } from "../../store";

import { useMutation, useQuery } from "react-query";
import { SaveOutlined } from "@ant-design/icons";
import { getQrCode, saveQrCode } from "../../api";
import { useState } from "react";
import QRCode from "react-qr-code";

const { Title, Text } = Typography;

export const QrModal = ({
  isLoginPage = false,
  uri,
  onSave = () => {},
  loading,
}) => {
  const { setIsQrModal, isQrModal, setUser, setIsLogin } = useApp();
  const [otp, setOtp] = useState(null);

  const { isLoading, data } = useQuery(["qrCode"], getQrCode, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: isQrModal && !isLoginPage,
  });

  const { mutate, isLoading: isEditLoading } = useMutation(saveQrCode, {
    onSuccess: (data) => {
      if (data.success) {
        message.success("Save Successfull!.");
        setIsQrModal(false);
        setOtp(null);
        localStorage.clear("bo_token");
        setUser(null);
        setIsLogin(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  return (
    <Modal
      title={<Title level={5}>Qr Generate</Title>}
      className="app-modal"
      centered
      visible={isQrModal}
      onCancel={() => {
        setOtp(null);
        setIsQrModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
      bodyStyle={{
        paddingTop: 10,
      }}
    >
      <Space align="baseline" size={2}>
        <Title level={5}>1 -</Title>{" "}
        <Text type="secondary">Scan The Qr Code</Text>
      </Space>
      <div
        style={{
          height: "auto",
          margin: "0 auto",
          width: "100%",
          padding: 80,
          border: "1px solid #eee",
          borderRadius: 8,
          background: "#eee",
        }}
      >
        {isLoading && <p>Loading...</p>}

        {(data?.data || uri) && (
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={data?.data || uri}
          />
        )}
      </div>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Space align="baseline" size={2}>
        <Title level={5}>2 -</Title>{" "}
        <Text type="secondary">Then enter your otp code</Text>
      </Space>
      <Input
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Otp Code"
      />
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Button
        icon={<SaveOutlined />}
        disabled={!otp}
        loading={isEditLoading || loading}
        onClick={() =>
          isLoginPage
            ? onSave(otp)
            : mutate({
                otp,
                twofaEnabled: true,
              })
        }
        block
      >
        Save
      </Button>
    </Modal>
  );
};
