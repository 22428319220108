import { useState, useEffect, useMemo } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Tooltip,
} from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import authFunc from "../../helper/auth";
import { copyLink } from "../../helper/copyLink";
import { getVmsSettings, removeVmsSettings, pageSize } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useVmsSettings } from "./store";
import { useSearchParams } from "react-router-dom";

const { Link } = Typography;

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,

    setLink,
    setVersion,
    setVendorList,

    queryKey,
  } = useVmsSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setLink(row.link);
    setVersion(row.version);
    setVendorList(row.vendors);

    setIsCreateModal(true);
  };


 const  columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        render: (text) => (
          <Space>
            <Link style={{ textDecoration: "underline" }}>{text}</Link>
            <Tooltip placement="top" title="copy image link">
              <Button
                onClick={() => copyLink(text)}
                size="small"
                type="text"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        render: (id, row) => (
          <Space>
            {authFunc("VMS_SETTING_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("VMS_SETTING_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                disabled={row.mandatory == "true" ? true : false}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={row.mandatory == "true" ? true : false}
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation(removeVmsSettings, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "VmsSettings",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["VmsSettings", queryKey, page], getVmsSettings, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
