import { useState, useEffect } from "react";
import { Select, Spin, DatePicker, Space } from "antd";
import { getVendors } from "../../api";
import { useVendorPayment } from "../../store";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
const date = new Date();

export default function PaymentFilters() {
  const {
    setQueryFilter,
    setPage,
    vendorID,
    setVendorID,
    setTotalValue,
    setTo,
    setFrom,
    to,
    from,
  } = useVendorPayment();

  const [vendorSearch, setvendorSearch] = useState("");

  const { isLoading: isvendorLoading, data: vendors } = useQuery(
    ["vendors", vendorSearch, 1, "", 1000],
    getVendors,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj ={
      vendorID: searchParams.get("vendorID") || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
    }
    setVendorID(obj.vendorID);
    setFrom(obj.from);
    setTo(obj.to);
  }, [
    searchParams.get("vendorID"),
    searchParams.get("from"),
    searchParams.get("to"),
  ]);

  return (
    <Space wrap>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isvendorLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val != undefined) {
            setVendorID(val);
            setPage(1);
            searchParams.set("vendorID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setVendorID(null);
          searchParams.delete("vendorID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setvendorSearch(val)}
        placeholder="Select Vendors"
        style={{ width: "100%", maxWidth: 300 }}
        value={vendorID}
      >
        {vendors &&
          vendors.success &&
          vendors.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.arName}
            </Option>
          ))}
      </Select>
      <Space direction="vertical" size={5}>
        <RangePicker
          value={
            from && to
              ? [
                  moment(from, "YYYY-MM-DD HH:mm"),
                  moment(to, "YYYY-MM-DD HH:mm"),
                ]
              : []
          }
          showTime={{
            format: "HH:mm",
          }}
          format="YYYY-MM-DD HH:mm"
          onChange={(dates, dateStrings) => {
            if (dates != null) {
              setFrom(dateStrings[0]);
              setTo(dateStrings[1]);
              searchParams.set("from", dateStrings[0]);
              searchParams.set("to", dateStrings[1]);
              setSearchParams(searchParams);
            } else {
              setFrom(null);
              setTo(null);
              searchParams.delete("from");
              searchParams.delete("to");
              setSearchParams(searchParams);
            }
          }}
        />
      </Space>
    </Space>
  );
}
