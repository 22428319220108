import create from "zustand";

export const useOrderStatus = create((set) => ({
  queryKey: "",
  id: null,
  isCreateModal: false,
  dataRow: null,
  enTitle: null,
  arTitle: null,
  kuTitle: null,
  vmsTitle: null,
  enDescription: null,
  arDescription: null,
  kuDescription: null,
  code: null,
  userType: null,
  color: null,

  setId: (id) => set({ id }),
  setColor: (color) => set({ color }),
  setDataRow: (data) => set({ data }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setEnTitle: (enTitle) => set({ enTitle }),
  setArTitle: (arTitle) => set({ arTitle }),
  setKuTitle: (kuTitle) => set({ kuTitle }),
  setVmsTitle: (vmsTitle) => set({ vmsTitle }),
  setEnDescription: (enDescription) => set({ enDescription }),
  setArDescription: (arDescription) => set({ arDescription }),
  setKuDescription: (kuDescription) => set({ kuDescription }),
  setCode: (code) => set({ code }),
  setUserType: (userType) => set({ userType }),
}));
