import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  DatePicker,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useOrderView } from "./store";
import { useMutation, useQuery } from "react-query";
import { editOrder, getOrderInfo } from "../../api";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import moment from "moment";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const EditTime = () => {
  const {
    setIsEditTimeModal,
    isEditTimeModal,
    order,
    voucher,
    basket,
    clientAddressID,
    clientComment,
  } = useOrderView();

  const { id } = useParams();

  const { data: orderData } = useQuery(["orderInfo", id], getOrderInfo, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const { mutate, isLoading } = useMutation(editOrder, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "orderInfo",
        setterList: [() => setIsEditTimeModal(false)],
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    let orderInfo = orderData?.data;
    let _order = {
      id: orderInfo?.order?.id,
      orderDetail: basket.map((el) => {
        return {
          productID: el?.product?.id,
          qty: el.qty,
          subProductsIDS: el?.product?.subProduct
            ? JSON.stringify([el?.product?.subProduct?.id])
            : "[]",
        };
      }),
      clientID: orderInfo?.client?.id,
      clientAddressID,
      vendorID: orderInfo?.vendor?.id,
      CityID: orderInfo?.order?.cityID,
      paymentTypeID: orderInfo?.order?.paymentTypeID,
      voucherID: voucher ? voucher.id : 0,
      declineReason: "{}",
      clientComment,
      clientCommentAddress: "",
      freeGift: false,
      platform: "IOS",
      preOrder: false,
      preCalculatedTime: true,
      preOrderDeliveryTime: dayjs(
        orderInfo?.order?.preOrderDeliveryTime
      ).toISOString(),
      expectedDeliveryTime: dayjs(values.expectedDeliveryTime).toISOString(),
      bikerPickupDateTime: orderInfo?.order?.bikerPickupDateTime,
    };

    mutate(_order);
  };

  return (
    <Modal
      title={<Title level={5}> Edit time </Title>}
      className="app-modal"
      centered
      visible={isEditTimeModal}
      onCancel={() => {
        setIsEditTimeModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          expectedDeliveryTime: moment(order?.order?.expectedDeliveryTime),
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20]}>
          <Col span={24}>
            <Form.Item
              label="Select date & Time"
              name="expectedDeliveryTime"
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime={{
                  defaultValue: dayjs("00:00", "hh:mm A"),
                  format: "hh:mm A",
                }}
                disabledDate={(current) => current.date() !== dayjs().date()}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
              >
                Send
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
