import create from "zustand";

export const usePwaSettings = create((set) => ({
  isCreateModal: false,
  id: null,
  backendVersion: null,
  allowedVersion: null,
  suspendPrice: null,
  queryKey: "",
  message: null,
  orderCancelTime:null,


  setOrderCancelTime : (orderCancelTime) => set({orderCancelTime}),
  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setMessage: (message) => set({ message }),
  setBackendVersion: (backendVersion) => set({ backendVersion }),
  setAllowedVersion: (allowedVersion) => set({ allowedVersion }),
  setSuspendPrice: (suspendPrice) => set({ suspendPrice }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
