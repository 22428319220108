import create from "zustand";

export const useReviewReason = create((set) => ({
  queryKey: "",
  id: null,
  isCreateModal: false,
  title: null,
  type:null,
  category : null,
  page: 1,

  setPage : (page) => set({page}),
  setCategory : (category) => set({category}),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setTitle: (title) => set({ title }),
  setType: (type) => set({ type }),
}));
