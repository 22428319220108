import create from "zustand";

export const useContract = create((set) => ({
  id: null,
  isCreateModal: false,
  contractImg: null,
  resturantOwnerName: null,
  resturantOwnerPhoneNo: null,
  resturantOwnerEmail: null,
  startDate: null,
  endDate:null,
  percenage: 4,
  vendorID: null,
  queryKey: "",
  
  setQueryKey: (queryKey) => set({ queryKey }),

  setId: (id) => set({ id }),
  setContractImg: (contractImg) => set({ contractImg }),
  setResturantOwnerName: (resturantOwnerName) => set({ resturantOwnerName }),
  setResturantOwnerPhoneNo: (resturantOwnerPhoneNo) => set({ resturantOwnerPhoneNo }),
  setResturantOwnerEmail: (resturantOwnerEmail) => set({ resturantOwnerEmail }),
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setPercenage: (percenage) => set({ percenage }),
  setVendorID: (vendorID) => set({ vendorID }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
