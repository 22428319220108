import { useEffect, useMemo } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Switch,
  Pagination,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import {
  getVendorDicount,
  removeData,
  editVendorDicountStatus,
  pageSize,
} from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useVendorDiscount } from "./store";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;
export const PureTable = () => {
  const {
    queryKey,
    setId,
    setIsCreateModal,
    queryFilter,
    setPage,
    page,
    setSchedule,
    status,
  } = useVendorDiscount();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handlEdit = (row) => {
    setId(row.id);
    setSchedule(JSON.parse(row.schedule));
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Resturant",
        dataIndex: "vendor",
        key: "vendor",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },

      {
        title: "Total Value",
        dataIndex: "totalValue",
        key: "totalValue",
        render: (val, row) => (
          <Tag>
            <b>
              {row.discountType === "AMOUNT"
                ? Number(val).toLocaleString() + " IQD"
                : val + " %"}{" "}
            </b>
          </Tag>
        ),
      },

      {
        title: "Value Type",
        dataIndex: "discountType",
        key: "discountType",
        render: (val) => <Tag color={"purple"}>{val}</Tag>,
      },

      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        render: (text) => (
          <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
        ),
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        render: (text) => (
          <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            loading={editLoading}
            checked={row.status}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },

      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id, row) => (
          <Space size="small">
            {authFunc("VENDOR_DISCOUNT_UPDATE") && (
              <Button
                onClick={() => handlEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("VENDOR_DISCOUNT_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((val) => removeData("discount", val), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendorDiscounts",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["vendorDiscounts", queryKey, page, status], getVendorDicount, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorDicountStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendorDiscounts",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      let dataForm = {
        id: row.id,
        vendorID: row.vendorID,
        status: !row.status,
      };

      editMutate(dataForm);
    }
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
