import create from "zustand";

export const useReviews = create((set) => ({
  queryKey: "",
  search: "",
  phoneNo: "",
  QuerySearch: "",
  rate : null,
  status: null,

  setRate: (rate) => set({ rate }),
  setStatus: (status) => set({ status }),
  setQuerySearch: (QuerySearch) => set({ QuerySearch }),
  setPhoneNo: (phoneNo) => set({ phoneNo }),
  setSearch: (search) => set({ search }),
  setQueryKey: (queryKey) => set({ queryKey }),
}));
