import {
  EditOutlined,
  PlusOutlined,
  MinusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Typography,
  Space,
  Avatar,
  Divider,
  Popover,
  Popconfirm,
  Card,
  Radio,
  Spin,
  Select,
  message,
  Row,
  Col,
  Tag,
  Tooltip,
} from "antd";
import { useApp } from "../../store";
import { useOrder } from "./store";
import { useEffect, useState, useCallback } from "react";
import {
  getValidVoucher,
  Req,
  getProductsWithDiscount,
  getPaymentType,
  getDeliveryDiscountByVendor,
} from "../../api";
import { useQuery } from "react-query";

import { roundToNearest250 } from "../../helper/roundMath";
import {
  calcTotal,
  calcVoucher,
  calcDiscountFunc,
  calcServiceFee,
  calcSubTotal,
  calcDeliveryFee,
} from "./invoiceCalculations";

const { Text, Title, Link } = Typography;
const { Option } = Select;

export const parseSubGroup = (subProductGroup) => {
  let groupsNames = subProductGroup
    ?.map((gruop) =>
      gruop?.subProductsIDS?.map((item) => item.name).join(" - ")
    )
    .join(" - ");
  let priceList = subProductGroup
    ?.map((gruop) => gruop?.subProductsIDS?.map((item) => item.price))
    .join()
    .split(",");
  let totalPrice = 0;
  priceList?.map((price) => {
    totalPrice = totalPrice + Number(price);
  });
  return { groupsNames, totalPrice };
};

export const InvoiceInfo = () => {
  const {
    setIsLogin,
    subProductSelected,
    setIsSubProduct,
    setSubProducstInit,
    setSubProductEditData,
    setIsSubProductEdit,
    isSubProductEdit,
  } = useApp();
  const { setPayment, payment, vendor, client } = useOrder();

  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [clientID, setClientID] = useState(null);
  const [vendorID, setVendorID] = useState(null);
  const [querySearch, setQuerySearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [products, setProducts] = useState([]);
  const [validVouchers, setValidVouchers] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  const [filterPayment, setFilterPayment] = useState("");

  /// invoice calculaitons states
  const [total, setTotal] = useState(0);
  const [voucherAmount, setVoucherAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [serviceAmount, setServiceAmount] = useState(0);
  const [deliveryDiscount, setDeliveryDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [discountCap, setDiscountCap] = useState(0);
  const [totalDeliveryFee, setTotalDeliveryFee] = useState(0);

  const { setBasket, basket, order, setOrder, voucher, setVoucher } =
    useOrder();

  const { data: deliveryDiscountData } = useQuery(
    ["deliveryDiscount", vendorID],
    getDeliveryDiscountByVendor,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      client: !!client,
    }
  );

  // create  useEffect controller
  useEffect(() => {
    setVoucherAmount(calcVoucher(subTotal, voucher, discount));
  }, [voucher, discount, subTotal, discountCap]);

  useEffect(() => {
    setDiscount(calcDiscountFunc(basket));
  }, [basket]);

  useEffect(() => {
    setServiceAmount(calcServiceFee(subTotal, vendor?.serviceFee));
  }, [subTotal, basket, vendor?.serviceFee]);

  useEffect(() => {
    setDeliveryDiscount(vendor?.delivery - totalDeliveryFee);
  }, [vendor?.delivery, totalDeliveryFee]);

  useEffect(() => {
    setSubTotal(calcSubTotal(basket));
  }, [basket]);

  useEffect(() => {
    setTotalDeliveryFee(
      calcDeliveryFee(
        subTotal,
        vendor?.delivery,
        vendor?.deliveryDiscount,
        voucher,
        discount
      )
    );
  }, [subTotal, vendor?.delivery, vendor?.deliveryDiscount, voucher, discount]);

  useEffect(() => {
    setTotal(
      roundToNearest250(
        calcTotal(
          basket,
          totalDeliveryFee,
          roundToNearest250(serviceAmount),
          voucherAmount,
          discountCap
        )
      )
    );
  }, [
    basket,
    totalDeliveryFee,
    vendor?.deliveryDiscount,
    voucher,
    voucherAmount,
    serviceAmount,
    discountCap,
  ]);

  const { isLoading: paymentLoading, data: payments } = useQuery(
    ["paymentTypes", filterPayment],
    getPaymentType,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!client,
    }
  );

  const { isLoading, data } = useQuery(
    ["validVoucher", clientID],
    getValidVoucher,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!clientID,
    }
  );

  const { isLoading: productLoading, data: productData } = useQuery(
    ["productSearch", vendorID, querySearch],
    getProductsWithDiscount,
    {
      refetchOnWindowFocus: false,
      enabled: !!vendorID,
    }
  );

  let parseID = (voucher) => {
    return (voucher.uniqID = voucher?.id + "-" + voucher?.currentStep);
  };
  let renderValidVoucher = useCallback(async () => {
    let list = await data?.data?.records?.map((el) => {
      return { ...el, uniqID: parseID(el) };
    });

    if (order?.voucher?.id) {
      order.voucher.uniqID = parseID(order.voucher);
      setVoucher({ ...order?.voucher, isView: true });
      setSelectedVoucher(order?.voucher?.uniqID);
      let selected = list?.find((el) => el?.uniqID === order.voucher.uniqID);
      if (!selected) setValidVouchers([order?.voucher, ...list]);
    } else setValidVouchers(list);
  }, [data, order]);

  useEffect(() => {
    if (data) renderValidVoucher();
  }, [renderValidVoucher]);

  useEffect(() => {
    if (productData && productData?.success) {
      let _products = [];
      productData?.data?.records.map((el) => {
        if (el.subProduct) {
          el.subProduct.map((sub) =>
            _products.push({ ...el, subProduct: sub })
          );
        } else {
          _products.push(el);
        }
      });
      setProducts(_products);
    }
  }, [productData, querySearch]);

  const calcDiscount = (price = 0, discount, type = "PERCENTAGE") => {
    let endPrice = price;
    if (discount) {
      let valueWithPercentage = price * (1 - discount / 100);
      endPrice = valueWithPercentage < 0 ? 0 : valueWithPercentage;
    }
    return endPrice;
  };

  const handleSubEdit = async (el) => {
    setEditLoading(true);
    const { data, success } = await Req(
      "GET",
      `v1/productdiscount/info?&id=${el?.product?.id}`
    );
    setEditLoading(false);
    if (success) {
      let _data = {
        product: data,
        qty: el.qty,
      };
      setIsSubProductEdit(true);
      setSubProducstInit(_data);
      setSubProductEditData(el);
      setIsSubProduct(true);
    } else {
      message.error("Requist error!");
    }
  };

  const addNewProductToBasket = (product) => {
    let _product = JSON.parse(product);
    let item = {
      product: {
        ..._product,
      },
      qty: 1,
    };

    if (item?.product?.subProductGroup) {
      if (
        !basket.find(
          (el) =>
            parseSubGroup(el.product?.subProductGroup).groupsNames ===
            parseSubGroup(item?.product?.subProductGroup).groupsNames
        )
      ) {
        setSubProductEditData(null);
        setIsSubProductEdit(false);
        setSubProducstInit(item);
        setIsSubProduct(true);
      }
    } else {
      if (!basket.find((el) => el.product?.id === item?.product?.id)) {
        setBasket([...basket, item]);
      }
    }
  };

  useEffect(() => {
    if (subProductSelected) {
      let sub =
        subProductSelected?.product.subProductGroup?.length !== 0
          ? subProductSelected?.product.subProductGroup
          : null;
      let item = {
        product: {
          ...subProductSelected.product,
          endprice: subProductSelected.product.endprice,
          subProductGroup: sub,
          subProductNames: sub ? parseSubGroup(sub).groupsNames : "",
          subProductPrice: sub ? parseSubGroup(sub).totalPrice : 0,
          subProductEndPrice: sub
            ? calcDiscount(
                parseSubGroup(sub).totalPrice,
                subProductSelected?.product.totalValue,
                subProductSelected.productDiscountType
              )
            : 0,
          discount: subProductSelected?.product.totalValue,
          discountType: subProductSelected.product.productDiscountType,
        },
        qty: subProductSelected.qty,
      };

      if (isSubProductEdit) {
        let newBasket = [...basket];
        let objIndex = newBasket.findIndex(
          (el) => el.product.id === subProductSelected.product.id
        );
        newBasket.splice(objIndex, 1, item);
        setBasket(newBasket);
      } else {
        setBasket([...basket, item]);
      }
    }
  }, [subProductSelected]);

  const handleBasket = (index, qty) => {
    basket[index].qty = qty;
    setBasket(basket.filter((el) => el.qty !== 0));
  };

  useEffect(() => {
    if (data && data.err == "unauthorized") {
      localStorage.clear();
      setIsLogin(false);
    }
  }, [data]);

  //+++++++++++++ create order
  useEffect(() => {
    if (client) {
      setClientID(client.id);
      setVendorID(vendor.id);
      setOrder(null);
      setIsSearch(true);
      setBasket([]);
      setVoucher(null);
      setSelectedVoucher(null);
      setSubProducstInit(null);
      setSubProductEditData(null);
    }
  }, [client, vendor, data]);

  const calcIsReachMinAmount = (minAmount) => {
    return subTotal - (discountCap + discount) >= minAmount ? true : false;
  };

  ///client => order create
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="invoice-list">
        <ul>
          {isSearch && (
            <li style={{ padding: "14px 0px" }}>
              <Row gutter={[30, 30]}>
                <Col span={client ? 14 : 24}>
                  <Select
                    showSearch
                    bordered={false}
                    onChange={(val) => val && addNewProductToBasket(val)}
                    filterOption={false}
                    optionFilterProp="children"
                    onSearch={(val) => setQuerySearch(val)}
                    style={{ width: "100%" }}
                    allowClear={!productLoading}
                    loading={productLoading}
                    placeholder="Write product name"
                  >
                    {products?.map((el, index) => (
                      <Option key={index} value={el && JSON.stringify(el)}>
                        <Space>
                          {el.name}
                          {el.subProduct && (
                            <Text type="secondary">
                              ({el?.subProduct?.name})
                            </Text>
                          )}
                        </Space>
                      </Option>
                    ))}
                  </Select>
                </Col>

                <Col span={10}>
                  <Select
                    showSearch
                    bordered={false}
                    style={{ width: "100%" }}
                    value={payment && payment.id}
                    placeholder="Select Type"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    loading={paymentLoading}
                    onSearch={(val) => setFilterPayment(val)}
                    onChange={(id) => {
                      let _payment = payments.data.records.find(
                        (el) => el.id == id
                      );
                      setPayment(_payment);
                    }}
                    notFoundContent={null}
                  >
                    {payments &&
                      payments.success &&
                      payments.data.records.map((el, index) => (
                        <Option key={index} value={el.id}>
                          {el.name}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            </li>
          )}

          {basket.map((el, index) => (
            <li
              key={index}
              className="app-space-between"
              style={{ padding: "14px 0px" }}
            >
              <Space align="start" size={16}>
                <Popover
                  content={
                    <img
                      src={JSON.parse(el?.product?.img)[0]?.replace(
                        "upload",
                        `upload/w_300/q_40`
                      )}
                    />
                  }
                >
                  <Avatar
                    style={{ width: 50, height: 50 }}
                    shape="square"
                    size="large"
                    src={JSON.parse(el?.product?.img)[0]?.replace(
                      "upload",
                      `upload/w_100/q_50`
                    )}
                  />
                </Popover>

                <Space size={0} direction="vertical">
                  <Space>
                    <Text>{el?.product?.name}</Text>
                    {el?.product?.subProductGroup && (
                      <Text type="secondary">
                        ({" "}
                        {
                          parseSubGroup(el?.product?.subProductGroup)
                            .groupsNames
                        }{" "}
                        )
                      </Text>
                    )}
                  </Space>
                  <Text>
                    {el?.product?.size} {el?.product?.unit}
                  </Text>
                  <Space>
                    {el?.product?.price !== el?.product?.endprice &&
                      (discount || client) && (
                        <Text
                          type="secondary"
                          style={{
                            fontSize: 12,
                            textDecoration: "line-through",
                            fontStyle: "italic",
                          }}
                        >
                          {Number(
                            el?.product?.price * el?.qty || 0
                          ).toLocaleString()}
                          IQD
                        </Text>
                      )}

                    <Text style={{ fontWeight: "bold" }}>
                      {Number(
                        el?.product?.endprice * el?.qty || 0
                      ).toLocaleString()}
                      IQD
                    </Text>
                  </Space>
                  <Space>
                    {el?.product?.subProductGroup && "+"}
                    {el?.product?.subProductPrice !==
                      el?.product?.subProductEndPrice && (
                      <Text
                        type="secondary"
                        style={{
                          fontSize: 12,
                          textDecoration: "line-through",
                          fontStyle: "italic",
                        }}
                      >
                        {Number(
                          el?.product?.subProductPrice * el?.qty || 0
                        ).toLocaleString()}
                        IQD
                      </Text>
                    )}
                    {el?.product?.subProductGroup && (
                      <Text style={{ fontSize: 12 }}>
                        {Number(
                          el?.product?.subProductEndPrice * el?.qty || 0
                        ).toLocaleString()}
                        IQD
                      </Text>
                    )}
                  </Space>
                </Space>
              </Space>
              <Space size={30}>
                <Space size={8}>
                  <Button
                    className="qty-btn"
                    size={"small"}
                    icon={<MinusOutlined />}
                    onClick={() => handleBasket(index, el?.qty - 1)}
                  />
                  <Text>{el?.qty}</Text>
                  <Button
                    className="qty-btn"
                    size={"small"}
                    icon={<PlusOutlined />}
                    onClick={() => handleBasket(index, el?.qty + 1)}
                  />
                </Space>
                <Space>
                  {
                    <Button
                      disabled={!el?.product?.subProductGroup}
                      size={"small"}
                      loading={editLoading}
                      onClick={() => handleSubEdit(el)}
                      icon={<EditOutlined />}
                    />
                  }
                  <Popconfirm
                    placement="topRight"
                    onConfirm={() => handleBasket(index, 0)}
                    title="Confirm Delete!"
                  >
                    <Button
                      type="primary"
                      danger
                      size={"small"}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Space>
              </Space>
            </li>
          ))}

          <li style={{ padding: client ? "14px 0px" : "10px " }}>
            <Space
              align="end"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                alignItems: "flex-end",
              }}
            >
              <Space align="end">
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Total Value
                  </Text>
                  <Title
                    level={2}
                    style={{ margin: 0, lineHeight: 0.9, marginTop: 6 }}
                  >
                    {Number(total).toLocaleString()}
                    <span className="currency">IQD</span>
                  </Title>
                </div>
                <Divider style={{ height: 30 }} type="vertical" />
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Sub Total
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {Number(subTotal).toLocaleString()}
                    <span className="currency">IQD</span>
                  </Title>
                </div>
                <Divider style={{ height: 30 }} type="vertical" />
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Delivery
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {Number(totalDeliveryFee).toLocaleString("en")}
                  </Title>
                </div>

                <Divider style={{ height: 30 }} type="vertical" />
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Delivery Discount
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {Number(deliveryDiscount).toLocaleString("en")}
                  </Title>
                </div>
                <Divider style={{ height: 30 }} type="vertical" />
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Service Fee
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {order !== null
                      ? Number(order?.order?.serviceFee).toLocaleString()
                      : Number(
                          roundToNearest250(serviceAmount)
                        ).toLocaleString()}

                    <span className="currency">IQD</span>
                  </Title>
                </div>
                <Divider style={{ height: 30 }} type="vertical" />
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Discount
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {Number(discount).toLocaleString()}
                    <span className="currency">IQD</span>
                  </Title>
                </div>
                <Divider style={{ height: 30 }} type="vertical" />
                <div className="invoice-value">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Voucher
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {Number(voucherAmount).toLocaleString("en")}
                    <span className="currency">IQD</span>
                  </Title>
                </div>
              </Space>
            </Space>
          </li>
          {voucher && (
            <li
              className="app-space-between"
              style={{ padding: client ? "14px 0px" : "14px 16px" }}
            >
              <Space size={30}>
                <div className="vocher-info">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Voucher Title
                  </Text>
                  <Title
                    level={5}
                    style={{ margin: 0, fontWeight: "normal" }}
                    className="voucher-title"
                  >
                    {voucher?.voucherTitle || "...."}
                  </Title>
                </div>
                <div className="vocher-info">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Code
                  </Text>
                  <Title
                    level={5}
                    style={{
                      margin: 0,
                      fontStyle: "italic",
                      fontWeight: "normal",
                    }}
                  >
                    {voucher?.code || "...."}
                  </Title>
                </div>
                <div className="vocher-info">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Voucher Value
                  </Text>
                  <Title level={5} style={{ margin: 0 }}>
                    {Number(voucher?.totalValue).toLocaleString()}
                    <span className="currency">
                      %
                      <Text style={{ fontWeight: "normal" }} type="secondary">
                        ( max : {Number(voucher?.maxAmount).toLocaleString()}
                        IQD)
                      </Text>
                    </span>
                  </Title>
                </div>
                <div className="vocher-info">
                  <Text style={{ fontSize: 12 }} type="secondary">
                    Voucher Type
                  </Text>
                  <br />
                  <Tag color="red">{voucher?.voucherType}</Tag>
                </div>
              </Space>

              {!order ? (
                <Space>
                  <Popconfirm
                    placement="topRight"
                    onConfirm={() => {
                      setSelectedVoucher(null);
                      setVoucher(null);
                    }}
                    title="Confirm Delete!"
                  >
                    <Button
                      size={"small"}
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Space>
              ) : null}
            </li>
          )}

          {isLoading ? (
            <li style={{ padding: client ? "14px 0px" : "14px 16px" }}>
              <Space size={16} align="center">
                <Spin size="small" spinning={true} />
                <Text type="secondary">Loading available vouchers . . .</Text>
              </Space>
            </li>
          ) : (
            validVouchers?.length !== 0 && (
              <li style={{ padding: client ? "14px 0px" : "14px 16px" }}>
                <Text style={{ fontSize: 12 }} type="secondary">
                  Available Vouchers
                </Text>
                <br />
                <Radio.Group
                  onChange={(e) => {
                    let v = validVouchers?.find(
                      (el) => el?.uniqID === e.target.value
                    );
                    setSelectedVoucher(v.uniqID);
                    setVoucher(v);
                  }}
                  value={selectedVoucher}
                >
                  <Space wrap>
                    {validVouchers?.map(
                      (el) =>
                        el?.code && (
                          <Card
                            bodyStyle={{ padding: 10 }}
                            key={el.id}
                            style={{
                              borderColor:
                                selectedVoucher === el.id ? "#0000ff" : "#eee",
                            }}
                            hoverable
                          >
                            <Tooltip
                              placement="top"
                              title={
                                calcIsReachMinAmount(el?.minAmount)
                                  ? `minimum basket value (after the discount) for this voucher is: ${
                                      el?.minAmount
                                    } IQD,
                                   \n current value is: ${
                                     subTotal - deliveryDiscount
                                   } `
                                  : ""
                              }
                            >
                              <Radio
                                disabled={
                                  !calcIsReachMinAmount(el?.minAmount) ||
                                  (!el.applyOverDiscount &&
                                    order?.order?.discountTotalValue)
                                }
                                value={el?.uniqID}
                              >
                                <Space>
                                  <Text>
                                    {el?.code}
                                    <b>
                                      ({Number(el?.totalValue).toLocaleString()}
                                      % )
                                    </b>
                                  </Text>
                                </Space>
                              </Radio>
                            </Tooltip>
                          </Card>
                        )
                    )}
                  </Space>
                </Radio.Group>
              </li>
            )
          )}
        </ul>
      </div>
    </Space>
  );
};
