import { useEffect, useMemo } from "react";
import {
  Table,
  Space,
  Button,
  message,
  Popconfirm,
  Pagination,
  Tag,
  Popover,
  Alert,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import {
  getAuthGroup,
  removeAuthGroup,
  pageSize,
  updateAuthGroup,
} from "../../api";

import { useAuthGroup } from "./store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";


export const PureTable = () => {
  const {
    setId,
    setName,
    setRoles,
    setIsCreateModal,
    queryKey,
    setPage,
    page,
  } = useAuthGroup();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setRoles(row.roles);
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
        ellipsis: true,
        render: (roles) => (
          <Popover
            content={roles?.map((el, index) => (
              <Tag key={index}>{el.role}</Tag>
            ))}
          >
            {roles?.map((el, index) => (
              <Tag key={index}>{el.role}</Tag>
            ))}
          </Popover>
        ),
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        width: 100,
        render: (id, row) => (
          <Space>
            {authFunc("AUTH_GROUP_UPDATE") && (
              <Button
                disabled={id === 1}
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
                loading={addLoading}
              />
            )}
            {authFunc("AUTH_GROUP_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                disabled={row.mandatory === "true" || id === 1 ? true : false}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={row.mandatory === "true" || id === 1 ? true : false}
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeAuthGroup(val),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "authGroup",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    data,
    error,
  } = useQuery(["authGroup", page, queryKey], getAuthGroup, {
    retry: 2,
  });

  const { isLoading: editLoading } = useMutation(updateAuthGroup, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "authGroup",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading || editLoading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
