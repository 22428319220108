import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Pagination,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";

import { getFee, removeFee, pageSize } from "../../api";
import { useDeliveryFee } from "./store";
import { useEffect, useState, useMemo } from "react";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";

const { Text } = Typography;

export const PureTable = () => {
  const {
    queryKey,
    setName,
    setDescription,
    setId,
    setDomains,
    setIsCreateModal,

    setExtraFeePerKilometer,
    setExtraMinAmount,
    setExtraStartRange
  } = useDeliveryFee();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setName(row.name);
    setDescription(row.description);
    setDomains(row.domains);
    setId(row.id);
    setIsCreateModal(true);

    setExtraFeePerKilometer(row.extraFeePerKilometer);
    setExtraMinAmount(row.extraMinAmount);
    setExtraStartRange(row.extraStartRange);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <Text style={{ fontWeight: "bold", fontStyle: "italic" }}>
            {text}
          </Text>
        ),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => <Text type="secondary">{text}</Text>,
      },
      {
        title: "Distance",
        dataIndex: "domains",
        key: "domains",
        render: (domains) =>
          !domains ? (
            <Tag color="success">Free</Tag>
          ) : (
            <Space size={[10, 10]} wrap>
              {domains.map((el) => (
                <Tag key={el.id} color="geekblue">
                  {Number(el.from).toLocaleString()} {`->`}{" "}
                  {`${Number(el.to).toLocaleString()} m`} ={" "}
                  <b>{Number(el.value).toLocaleString()}</b>IQD
                </Tag>
              ))}
            </Space>
          ),
      },
      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => (
          <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
        ),
      },
      {
        title: "Update Date",
        dataIndex: "updated_at",
        key: "updated_at",
        render: (date) => (
          <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
        ),
      },

      {
        title: "Action",
        key: "id",
        render: (row) => (
          <Space size="small">
            {authFunc("FEE_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("FEE_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(row.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((val) => removeFee(val), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "deliveryFee",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["deliveryFee", queryKey, page], getFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
