import React from "react";
import { Select, DatePicker, Space } from "antd";
import { useDiscount } from "../../store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function VoucherFilters() {
  const {
    setQueryFilter,
    queryKey, 
    setPage,
    active,
    setActive,
    from,
    setFrom,
    to,
    setTo,
  } = useDiscount();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      active: searchParams.get("status") || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
    };
    setActive(obj.active);
    setFrom(obj.from);
    setTo(obj.to);
  }, [
    searchParams.get("status"), 
    searchParams.get("from"),
    searchParams.get("to"),
    searchParams.get("page_num"),
  ]);

  return (
    <div className="filter-wrapper">
      <div className="item">
      <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setActive(val);
              setPage(
                searchParams.get("page_num") ? searchParams.get("page_num") : 1
              );
              searchParams.set("status", val);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setActive(null);
            searchParams.delete("status");
            setSearchParams(searchParams);
          }}
          placeholder="Select Status"
          style={{ width: "100%" }}
          value={active}
        >
          <Option value={"true"}>Active</Option>
          <Option value={"false"}>InActive</Option>
        </Select>
      </div>
      <div className="item">
        <Space direction="vertical" size={5}>
          <RangePicker
            value={
              from && to
                ? [
                    moment(from, "YYYY-MM-DD HH:mm"),
                    moment(to, "YYYY-MM-DD HH:mm"),
                  ]
                : []
            }
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={(dates, dateStrings) => {
              if (dates != null) {
                setFrom(dateStrings[0]);
                setTo(dateStrings[1]);
                searchParams.set("from", dateStrings[0]);
                searchParams.set("to", dateStrings[1]);
                setSearchParams(searchParams);
              } else {
                setFrom(null);
                setTo(null);
                searchParams.delete("from");
                searchParams.delete("to");
                setSearchParams(searchParams);
              }
            }}
          />
        </Space>
      </div>
    </div>
  );
}
