import React from "react";
import { Select, Spin } from "antd";
import { useVendorUser } from "../../pages/vendorUser/store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getVendors } from "../../api";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function VendorUserFilters() {
  const { setPage, vendorSearch, setVendorSearch, vendorID, setVendorID } =
    useVendorUser();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      vendorID: searchParams.get("vendorID") || null,
    };
    setVendorID(obj.vendorID);
  }, [searchParams.get("vendorID"), searchParams.get("page_num")]);

  const { isLoading: isvendorLoading, data: vendors } = useQuery(
    ["vendors", vendorSearch, 1],
    getVendors,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          notFoundContent={
            isvendorLoading ? (
              <div className="spin-wrapper">
                <Spin size="small" />
              </div>
            ) : null
          }
          allowClear
          showSearch
          onSearch={(val) => {
            setVendorSearch(val);
          }}
          onChange={(val) => {
            if (val != undefined) {
              setVendorID(val);
              setPage(1);
              searchParams.set("vendorID", val);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setVendorID(null);
            searchParams.delete("vendorID");
            setSearchParams(searchParams);
          }}
          placeholder="Select vendors"
          style={{ width: "100%" }}
          value={vendorID}
        >
          {vendors &&
            vendors.success &&
            vendors.data.records.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.arName}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}
