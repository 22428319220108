import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useDeclineReason } from "./store";
import { useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { createDeclineReason, editDeclineReason } from "../../api";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    arTitle,
    userType,
    code,
    setUserType,
    setCode,
    setArTitle,
    setFloating,
    refund,
    commission,
    type,
    setRefund,
    setCommission,
    setType,
    floating,
  } = useDeclineReason();

  const resetstate = () => {
    setId(null);
    setUserType(null);
    setCode(null);
    setArTitle(null);
    setFloating(null);
    setType(null);
    setCommission(null);
    setRefund(null);
  };

  const { mutate, isLoading } = useMutation(createDeclineReason, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "declineReasons",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetstate,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editDeclineReason,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "declineReasons",
          setterList: [() => setIsCreateModal(false), ()=>setId(null)],
          resetFunc: resetstate,
        });

      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.enTitle = values.arTitle;
    values.kuTitle = values.arTitle;
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Decline Reasons</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetstate();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          arTitle,
          userType,
          code,
          commission,
          type,
          refund,
          floating,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="arTitle"
              rules={[{ required: true }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Code" name="code" rules={[{ required: true }]}>
              <Input placeholder="Write Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Refund"
              name="refund"
              rules={[{ required: true, message: "required" }]}
            >
              <InputNumber style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="User Type"
              name="userType"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose User Type">
                <Option key="VENDOR" values={"VENDOR"}>
                  VENDOR
                </Option>
                <Option key="CLIENT" values={"CLIENT"}>
                  CLIENT
                </Option>
                <Option key="BIKER" values={"BIKER"}>
                  BIKER
                </Option>
                <Option key="BACKOFFICE_USER" values={"BACKOFFICE_USER"}>
                  BACKOFFICE_USER
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="commission" valuePropName="checked">
              <Checkbox
                checked={commission}
                onChange={(e) => setCommission(e.target.checked)}
              >
                {" "}
                is Commission ?
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="floating" valuePropName="checked">
              <Checkbox
                checked={floating}
                onChange={(e) => setFloating(e.target.checked)}
              >
                {" "}
                is floating ?
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
              <Select placeholder="Choose Type">
                <Option key="VENDOR" values={"VENDOR"}>
                  VENDOR
                </Option>
                <Option key="CLIENT" values={"CLIENT"}>
                  CLIENT
                </Option>
                <Option key="OPS" values={"OPS"}>
                  OPS
                </Option>
                <Option key="TECH" values={"TECH"}>
                  TECH
                </Option>
                <Option key="CONTENT" values={"CONTENT"}>
                  CONTENT
                </Option>
                <Option key="TEST" values={"TEST"}>
                  TEST
                </Option>
                <Option key="OTHER" values={"OTHER"}>
                  OTHER
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
