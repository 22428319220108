import create from "zustand";

export const usePaymentType = create((set) => ({
  queryKey: "",
  id: null,
  isCreateModal: false,
  name: null,

  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setName: (name) => set({ name }),
}));
