import { Typography, Space, Card, Spin } from "antd";
import { useQuery } from "react-query";
import { getUsedVouchers } from "../../api";
import dayjs from "dayjs";

const { Text } = Typography;

export const UsedVoucher = ({ clientID }) => {
  const { isLoading, data } = useQuery(
    ["usedVoucher", clientID],
    getUsedVouchers,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!clientID,
    }
  );

  return isLoading ? (
    <Space size={16} align="center">
      <Spin size="small" spinning={true} />
      <Text type="secondary">Loading used vouchers . . .</Text>
    </Space>
  ) : data?.data?.records.length === 0 ? null : (
    <>
      <Text style={{ fontSize: 12 }} type="secondary">
        Used Vouchers
      </Text>
      <br />
      <Space wrap>
        {data?.data?.records?.map((el, index) => (
          <Card
            bodyStyle={{ padding: 10 }}
            key={index}
            style={{ border: "none", background: "#f6f6f6" }}
          >
            <Space>
              <Text>
                {el?.voucherCode}
              </Text>
            </Space>
            <br />
            <Text type="secondary">
              {dayjs(el.CreatedAt).format("YYYY-MM-DD")}
            </Text>
          </Card>
        ))}
      </Space>
    </>
  );
};
