import {
  Card,
  Input,
  Space,
  Button,
  Breadcrumb,
  Radio,
  Tooltip,
} from "antd";

import {
  EyeOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";

import {PureTable } from "./table"
import {PureCreate } from "./create"
import {AddProducts } from "./addProducts"
import {AddUsers } from "./addUsers"
import {AddCategories } from "./addCategories"
import {PreviewModal } from "./previewModal"
import {AddBanners } from "./addBanners"

import { useAppBanner } from "./store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import authFunc from "../../helper/auth";

const { Search } = Input;

export const AppBannersPage = () => {
  const {
    setIsCreateModal,
    setIsGrid,
    isGrid,
    setIsPrivew,
    setQueryKey,
    queryKey,
  } = useAppBanner();

  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);


  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>

        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>App Banners</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search 
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Search..." 
            />
          </Space>
          <Space>
            <Button onClick={()=> setIsPrivew(true)} icon={<EyeOutlined/>}>Live Preview</Button>
            <Radio.Group
              style={{ marginRight: 20 }}
              value={isGrid}
              onChange={(e) => setIsGrid(e.target.value)}
              type="button"
            >
              <Tooltip  placement="top" title="View as list">
                <Radio.Button value={false}>
                  <UnorderedListOutlined />
                </Radio.Button>
              </Tooltip>
              <Tooltip  placement="top" title="View as icons">
                <Radio.Button value={true}>
                  <AppstoreOutlined />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
            {authFunc("APPBANNER_CREATE") && <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>}
          </Space>
        </div>
        <br />
        <PureTable />
        <PureCreate />
        <AddProducts/>
        <AddUsers/>
        <AddBanners />
        <AddCategories/>
        <PreviewModal/>
      </Card>
    </div>
  );
};
