import create from "zustand";

export const useServiceFee = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  name: null,
  description: null,
  id: null,
  max: null,
  min: null,
  value: null,

  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setMax: (max) => set({ max }),
  setMin: (min) => set({ min }),
  setValue: (value) => set({ value }),
}));
