import create from "zustand";

export const useVmsSettings = create((set) => ({
  isCreateModal: false,
  id: null,
  link: null,
  version: null,
  queryKey: "",
  vendorList: [],
  
  setQueryKey: (queryKey) => set({ queryKey }),

  setId: (id) => set({ id }),
  setLink: (link) => set({ link }),
  setVersion: (version) => set({ version }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setVendorList: (vendorList) => set({ vendorList }),
}));
