import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useMutation, useQuery } from "react-query";
import PasswordStrengthBar from "react-password-strength-bar";
import { createUser, editUser, getAuthGroup } from "../../api";
import { useEmployee } from "./store";
import { useEffect, useState } from "react";
import { phoneValidate } from "../../helper/phoneValidation";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    name,
    username,
    id,
    email,
    phone,
    roleID,
    setEmail,
    setName,
    setPassword,
    setPhone,
    setUsername,
    setId,
    setAuthGroupID,
    authGroupID,
  } = useEmployee();
  const [form] = Form.useForm();

  const [authSearch, setAuthSearch] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phoneState, setPhoneState] = useState(null);
  const [passScore, setPassScore] = useState(0);
  const [Pass, setPass] = useState("");

  const { isLoading: loading, data } = useQuery(
    ["authGroup", 1, authSearch, 500],
    getAuthGroup,
    {
      retry: 2,
    }
  );

  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "users",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(editUser, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "users",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    values.status = true;
    values.roleID = 1;
    if (
      id
    ) {
      values.id = id;
      editMutate(values);
    } else  {
      values.phone = phoneState;
      mutate(values);
    }
  };

  let resetState = () => {
    setEmail(null);
    setName(null);
    setPassword(null);
    setPhone(null);
    setUsername(null);
    setId(null);
    setAuthGroupID(null);
    setAuthSearch("");
    form.resetFields();
  };
  const checkPassword = (password) => {
    return /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])(?=.*\W)(?=.*\d))(?=.*[@#$%^&+=])|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/.test(
      password
    )
      ? password
      : "";
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        username,
        email,
        phone,
        roleID,
        authGroupID,
      });
    } else {
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Employee</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Baly mart" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true }]}
            >
              <Input placeholder={"baly001"} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: /^(078|079|077|075)\d{8}$/,
                  message: "Please enter a valid IQ mobile phone number",
                },
              ]}
            >
              <Input
                placeholder={"07**** ****"}
                maxLength="11"
                minLength="11"
                onChange={(e) => setPhoneState(e.target.value)}
                value={phoneState}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Auth Group"
              name="authGroupID"
              loading={loading}
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Auth Group"
                showSearch
                filterOption={false}
                onSearch={(val) => {
                  setAuthSearch(val);
                }}
              >
                {data &&
                  data.success &&
                  data?.data?.records?.map((el, index) => (
                    <Option key={index} value={el.id}>
                      {el.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: "email", required: true }]}
            >
              <Input placeholder={"example@baly.iq"} />
            </Form.Item>
          </Col>

          {!id ? (
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="******"
                  onChange={(e) => setPass(e.target.value)}
                />
              </Form.Item>
              <PasswordStrengthBar
                password={checkPassword(Pass)}
                onChangeScore={setPassScore}
              />
            </Col>
          ) : null}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                disabled={!id ? passScore < 3 : false}
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
