import React from "react";
import { Alert } from "antd";

function ErrorMessage({ errorInfo }) {
  const { error, loading, data } = errorInfo;

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  else return <></>;
}

export default ErrorMessage;
