import { Card, Input, Space, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import {PureCreate} from "./create"
import { useFreeDelivery } from "./store";
import authFunc from "../../helper/auth";


export const FreeDeliveryPage = () => {
  const { setIsCreateModal } = useFreeDelivery();

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Marketing</Breadcrumb.Item>
        <Breadcrumb.Item>Delivery Discount</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">


          {authFunc("DELIVERY_DISCOUNT_CREATE") && (
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />
        <PureTable />
        <PureCreate />
      </Card>
    </div>
  );
};
