import React from 'react';
import ReactDOM from 'react-dom';
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

import {
  BrowserRouter as Router,
} from "react-router-dom";



ConfigProvider.config({
  theme: {
    primaryColor: '#0000ff',
    borderRadiusBase: 20,
    errorColor: '#F81866',
    successColor:'#4DE252'
  },
});

ReactDOM.render(<Router><App /></Router>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
