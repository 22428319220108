import React, { useState, useEffect } from "react";
import { Col, Row, Select, message } from "antd";
import { useOrder } from "../store";

import { getValidVoucher, getPaymentType, checkVoucher } from "../../../api";
import { useQuery, useMutation } from "react-query";

const { Option } = Select;

export const MoreInfo = () => {
  const [filterPayment, setFilterPayment] = useState("");
  const [vid, setvId] = useState(null);
  const {
    setVoucher,
    setPayment,
    payment,
    voucher,
    client,
    vendor,
    products,
    id,
  } = useOrder();

  const { data: vouchers } = useQuery(
    ["vouchers", client?.id],
    getValidVoucher,
    {
      retry: 2,
    }
  );
  const { isLoading: paymentLoading, data: payments } = useQuery(
    ["paymentTypes", filterPayment],
    getPaymentType,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate } = useMutation(checkVoucher, {
    onSuccess: (data) => {
      if (data.success) {
        if (data?.data) {
          message.success("Voucher is valid");
          let _voucher = vouchers.data.records.find((el) => el.id == vid);
          setVoucher(_voucher);
        } else {
          message.error("Voucher is not valid!");
        }
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (vid) {
      handleCheckVoucher();
    } else if (!id && !voucher) {
      setVoucher(null);
    }
  }, [vid]);

  const handleCheckVoucher = () => {
    let _voucher = vouchers?.data?.records.find((el) => el.id === vid);
    if (_voucher) {
      mutate({
        vid,
        cid: client.id,
        vnid: vendor.id,
        caid: client.address.id,
        code: _voucher.code,
        orderDetail: products.map((el) => {
          return {
            productID: el.id,
            qty: el.qty,
            subproductIDS: "[]",
          };
        }),
      });
    }
  };

  return (
    <div className="step-container">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Select
            showSearch
            style={{ width: "100%", marginTop: 10 }}
            value={payment && payment.id}
            placeholder="Select Type"
            defaultActiveFirstOption={false}
            showArrow={true}
            loading={paymentLoading}
            onSearch={(val) => setFilterPayment(val)}
            onChange={(id) => {
              let _payment = payments.data.records.find((el) => el.id == id);

              setPayment(_payment);
            }}
            notFoundContent={null}
          >
            {payments &&
              payments.success &&
              payments.data.records.map((el, index) => (
                <Option key={index} value={el.id}>
                  {el.name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};
