import { Card, Input, Space, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreate } from "./create";
import authFunc from "../../helper/auth";
import { useContract } from "./store";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const { Search } = Input;

export const ContarctsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setIsCreateModal, setQueryKey } = useContract();

  useEffect(() => {
    return () => {
      setQueryKey("");
    };
  }, []);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Resturants</Breadcrumb.Item>
        {/* <Breadcrumb.Item>Resturant List</Breadcrumb.Item> */}
        <Breadcrumb.Item onClick={() => navigate(-1)}>
          <a>Resturants List</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Contracts</Breadcrumb.Item>
        {/* <Breadcrumb.Item>{id}</Breadcrumb.Item> */}
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              onSearch={(val) => setQueryKey(val)}
              style={{ width: 340 }}
              placeholder="Write To Search..."
            />
            {/* <Select
              loading={groupLoading}
              value={group}
              style={{ minWidth: 100 }}
              placeholder="Choose Group"
              onChange={(val) => setGroup(val)}
              allowClear
            >
              {groups && groups.success
                ? groups.data.records.map((el) => (
                    <Option key={el.id} value={el.id}>
                      {el.name}
                    </Option>
                  ))
                : null}
            </Select> */}
            {/* <Button icon={<SettingOutlined />}>Group Settings</Button> */}
          </Space>
          {authFunc("VENDOR_CONTRACT_CREATE") && (
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />
        <PureTable />
        <PureCreate vendorID={id} />
      </Card>
    </div>
  );
};
