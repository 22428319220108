import create from "zustand";

export const useCompundVouchers = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  code: null,
  active: true,
  voucherTitle: null,
  value: null,
  valueType: null,
  maxAmount: null,
  minAmount: null,
  totalQty: null,
  dayOfWeek: null,
  startDate: null,
  endDate: null,
  platform: null,
  userIDs: null,
  cityIDs: null,
  BusinessCategoryIDs: null,
  vendorIDs: null,
  fisrtOrderOnly: true,
  newUsersOnly: true,
  UDID: true,
  applyOverDiscount: true,
  id: null,
  schedule: null,
  avToCustomers: false,
  totalValue: null,
  vendorValue: null,

  isUsersModal: false,
  isVendorsModal: false,
  isCitiesModal: false,
  isCategoriesModal: false,
  queryFilter: "",
  page: 1,
  customerDesc: null,
  active: null,
  from: null,
  to: null,
  step: 0,
  stepsInfo: [
    {
      priority: 0,
      voucherType: "DISCOUNT",
      minAmount: 0,
      totalValue: 0,
      orderValue: 0,
      maxAmount: 0,
      vendorValue: 0,
      balyValue : 0,
    },
    {
      priority: 0,
      voucherType: "DISCOUNT",
      minAmount: 0,
      totalValue: 0,
      orderValue: 0,
      maxAmount: 0,
      vendorValue: 0,
      balyValue : 0,
    },
  ],

  setStepsInfo: (stepsInfo) => set({ stepsInfo }),
  setStep: (step) => set({ step }),
  setActive: (active) => set({ active }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),

  setIsUsersModal: (isUsersModal) => set({ isUsersModal }),
  setIsVendorsModal: (isVendorsModal) => set({ isVendorsModal }),
  setIsCitiesModal: (isCitiesModal) => set({ isCitiesModal }),
  setIsCategoriesModal: (isCategoriesModal) => set({ isCategoriesModal }),

  setTotalValue: (totalValue) => set({ totalValue }),
 
  setVendorValue: (vendorValue) => set({ vendorValue }),
  setSchedule: (schedule) => set({ schedule }),
  setCode: (code) => set({ code }),
  setVoucherTitle: (voucherTitle) => set({ voucherTitle }),
  setValue: (value) => set({ value }),
  setValueType: (valueType) => set({ valueType }),
  setMaxAmount: (maxAmount) => set({ maxAmount }),
  setMinAmount: (minAmount) => set({ minAmount }),
  setTotalQty: (totalQty) => set({ totalQty }),
  setDayOfWeek: (dayOfWeek) => set({ dayOfWeek }),
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setPlatform: (platform) => set({ platform }),
  setUserIDs: (userIDs) => set({ userIDs }),
  setCityIDs: (cityIDs) => set({ cityIDs }),
  setBusinessCategoryIDs: (BusinessCategoryIDs) => set({ BusinessCategoryIDs }),
  setVendorIDs: (vendorIDs) => set({ vendorIDs }),
  setFisrtOrderOnly: (fisrtOrderOnly) => set({ fisrtOrderOnly }),
  setUDID: (UDID) => set({ UDID }),
  setApplyOverDiscount: (applyOverDiscount) => set({ applyOverDiscount }),
  setNewUsersOnly: (newUsersOnly) => set({ newUsersOnly }),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPage: (page) => set({ page }),
  setAvToCustomers: (avToCustomers) => set({ avToCustomers }),
  setCustomerDesc: (customerDesc) => set({ customerDesc }),
}));
