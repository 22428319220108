import { useState, useEffect , useMemo } from "react";
import {
  Table,
  Typography,
  Alert,
  Pagination,
  Statistic,
  Tag,
  Tooltip,
} from "antd";
import { useQuery } from "react-query";
import numberForamt from "../../helper/numberFormat";
import { getPayment, pageSize } from "../../api";

import { useVendorPayment } from "./store";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

const { Text } = Typography;

export const PuerTable = () => {

  const [page, setPage] = useState(1);
  const { setTotalValue, isPayModal, vendorID, from, to } = useVendorPayment();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = useMemo(
    () => [ 
    {
      title: "Track No.",
      dataIndex: "trackNo",
      key: "trackNo",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Baly Commission",
      dataIndex: "percentage",
      key: "percentage",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text} %</Text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (val, row) => (
        <>
          {val === "previous balance" ? (
            <Tag color={"magenta"}>{val}</Tag>
          ) : val === "order" ? (
            <Tooltip placement="top" title={"Click to View Order"}>
              <Tag color={"purple"}>
                {" "}
                <a href={`/order/${row.id}`} target="_blank">
                  {val}
                </a>
              </Tag>
            </Tooltip>
          ) : (
            <Tag color={"green"}>{val}</Tag>
          )}
        </>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "lastStatus",
      key: "lastStatus",
      render: (el) => <Tag>{el}</Tag>,
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      render: (val) => <Tag>{val === false ? "No" : "Yes"}</Tag>,
    },
    {
      title: "Floating",
      dataIndex: "floating",
      key: "floating",
      render: (val) => <Tag>{val === false ? "No" : "Yes"}</Tag>,
    },
    {
      title: "Reason",
      dataIndex: "title",
      key: "title",
      render: (val) => <Tag>{val}</Tag>,
    },
    {
      title: "Sub Total",
      dataIndex: "subTotal",
      key: "subTotal",
      width: 110,
      render: (val) => <Text>{numberForamt(val)} IQD</Text>,
    },
    {
      title: "Vendor discount share",
      dataIndex: "discountVendorValue",
      key: "discountVendorValue",
      width: 110,
      render: (val) => <Text>{numberForamt(val)} IQD</Text>,
    },
    {
      title: "Vendor discount cap share",
      dataIndex: "vendorDiscountCap",
      key: "vendorDiscountCap",
      width: 110,
      render: (val) => <Text>{numberForamt(val)} IQD</Text>,
    },
    {
      title: "Vendor voucher share",
      dataIndex: "voucherVendorValue",
      key: "voucherVendorValue",
      width: 110,
      render: (val) => <Text>{numberForamt(val)} IQD</Text>,
    },
    {
      title: "Order Total",
      dataIndex: "orderTotal",
      key: "orderTotal",
      width: 110,
      render: (val) => <Text>{numberForamt(val)} IQD</Text>,
    },
    {
      title: "Refund",
      dataIndex: "refundValue",
      key: "refundValue",
      width: 110,
      render: (val) => <Text>{val !== null ? numberForamt(val) : 0} IQD</Text>,
    },
    {
      title: "Net vendor earning",
      dataIndex: "cash",
      key: "cash",
      width: 110,
      render: (val) => (
        <Text style={{ fontWeight: "bold" }}>{numberForamt(val)} IQD</Text>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: 130,
      render: (val) => (
        <Text style={{ fontWeight: "bold" }}>{numberForamt(val)} IQD</Text>
      ),
    },
  ],[])

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["vendorPayment", page, vendorID, from, to], getPayment, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && data.success && data?.data?.totalValue) {
      setTotalValue(data?.data?.totalValue);
    } else {
      setTotalValue(null);
    }
  }, [isPayModal]);

  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Statistic
        title="Balance (IQD)"
        value={data && data.success ? data?.data?.cash : 0}
      />
      <br />
      <Table
        rowClassName={(record, index) =>
          record.description === "previous balance" ? "table-row-color" : ""
        }
        loading={loading}
        columns={columns}
        dataSource={data && data.success ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 1300 }}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.success ? data?.data?.total : 0}
        showSizeChanger={false}
      />
    </>
  );
};
