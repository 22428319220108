import create from "zustand";

export const useAppBanner = create((set) => ({
    workingSchedule: null,
    isCreateModal: false,
    queryKey: "",
    img: null,
    name: null,
    polygon: null,
    priority: null,
    type: null,
    startDate: null,
    endDate: null,
    id: null,
    appBanner_product: [],
    banner: [],
    isAddBanners:false,
    isGrid: false,
    selectedType: "",
    isAddVendor: false,
    isAddUsers: false,
    isAddCat: false,
    users: [],
    categories: [],
    isPreview: false,
    lat: 33.3152,
    lon: 44.3661,
    description: null,
    vendorID: null,
    markerPosition: null,

    setLat: (lat) => set({ lat }),
    setMarkerPosition: (markerPosition) => set({ markerPosition }),
    setLon: (lon) => set({ lon }),
    setIsPrivew: (isPreview) => set({ isPreview }),
    setCategories: (categories) => set({ categories }),
    setAppBannerProduct: (appBanner_product) => set({ appBanner_product }),
    setIsAddVendor: (isAddVendor) => set({ isAddVendor }),
    setIsAddUsers: (isAddUsers) => set({ isAddUsers }),
    setIsAddCat: (isAddCat) => set({ isAddCat }),
    setSelectedType: (selectedType) => set({ selectedType }),
    setIsGrid: (isGrid) => set({ isGrid }),
    setId: (id) => set({ id }),
    setQueryKey: (queryKey) => set({ queryKey }),
    setStartDate: (startDate) => set({ startDate }),
    setEndDate: (endDate) => set({ endDate }),
    setImg: (img) => set({ img }),
    setName: (name) => set({ name }),
    setPriorty: (priority) => set({ priority }),
    setType: (type) => set({ type }),
    setPolygon: (polygon) => set({ polygon }),
    setDescription: (description) => set({ description }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
    setUsers: (users) => set({ users }),
    setBanners: (banners) => set({ banners }),
    setSchedule:(workingSchedule)=> set({workingSchedule}),
    setIsAddBanners: (isAddBanners) => set({ isAddBanners }),
    setVendorID: (vendorID) => set({ vendorID }),
}));
