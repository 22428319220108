import { useState, useEffect , useMemo } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Pagination,
  Switch,
  Popconfirm,
  Tooltip,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import {
  getProductVariation,
  removeProductVariation,
  pageSize,
  editProductVariationStatus,
} from "../../api";

import { useProductVariation } from "./store";
import { useSearchParams, useParams } from "react-router-dom";
import dayjs from "dayjs";
import handleDataMutation from "../../helper/dataMutation";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PuerTable = () => {

  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,
    setName,
    setDimension,
    setSize,
    setUnit,
    setImg,
    setBarcode,
    queryKey,
  } = useProductVariation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { productID } = useParams();
  const copy = require("clipboard-copy");

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setDimension(row.dimension);
    setSize(row.size);
    setUnit(row.unit);
    setImg(row.img);
    setBarcode(row.barcode);
    setIsCreateModal(true);
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editProductVariationStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "productVariation",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      editMutate({
        id: row.id,
        status: !row.status,
      });
    }
  };

  const copyBarcode = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      message.success("Copy to clipboard");
    } else {
      message.error("Faild Copy !");
    }
  };

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Product Variation Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <Text style={{ fontWeight: "bold" }}>{text ? text : "..."}</Text>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (unit) => <Text>{unit ? unit : "..."}</Text>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Dimension",
      dataIndex: "dimension",
      key: "dimension",
    },
    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "barcode",
      render: (val) => (
        <Space>
          <Tag>{val}</Tag>
          {val && (
            <Tooltip placement="top" title="copy barcode">
              <Button
                onClick={() => copyBarcode(val)}
                size="small"
                type="text"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("PRODUCT_UPDATE") ? false : true}
          loading={editLoading}
          checked={row.status}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("PRODUCT_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("PRODUCT_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this product variation?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate } = useMutation(removeProductVariation, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "productVariation",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["productVariation", queryKey, page, productID],
    getProductVariation,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!productID,
    }
  );



  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
