import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  Tag,
  Space,
  DatePicker,
  Spin,
  message,
  Checkbox,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useVendorDiscount } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createVendorDicount,
  editVendorDicount,
  getVendors,
  getProductsByVendorID,
  getVendorDicountOne,
} from "../../api";
import moment from "moment";
import { CustomeCalender } from "../../comonents/global";
import handleDataMutation from "../../helper/dataMutation";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";
const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    isAllProduct,
    setIsAllProduct,
    setValue,
    setStartDate,
    setVendorID,
    setDiscountType,
    setEndDate,
    schedule,
    setSchedule,
    vendorID,
    setBalyValue,
    setTotalValue,
    setVendorValue,
  } = useVendorDiscount();
  const [selected, setSelected] = useState(null);
  const [selectedResturan, setSelectedvendor] = useState(null);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [isItemRemoved, setIsItemReomved] = useState(false);
  const [form] = Form.useForm();
  const [type, setType] = useState("PERCENTAGE");
  const [Status, setStatus] = useState(true);
  const resetState = () => {
    setId(null);
    setValue(null);
    setStartDate(null);
    setVendorID(null);
    setDiscountType(null);
    setEndDate(null);
    setSchedule(null);
    form.resetFields();
    setList([]);
    setIsAllProduct(false);
    setIsItemReomved(false);
    setBalyValue(null);
    setTotalValue(null);
    setVendorValue(null);
    setSelected(null);
    setSelectedvendor(null);
  };

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const {
    isLoading: vendorLoading,
    data: vendors,
    refetch,
  } = useQuery(["vendors", filter], getVendors, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: isCreateModal,
  });

  const {
    isLoading: productLoading,
    data: products,
    refetch: productRefetch,
  } = useQuery(
    [
      "products",
      selectedResturan ? selectedResturan : vendorID,
      1,
      500,
      Status,
      productFilter,
    ],
    getProductsByVendorID,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!vendorID || !!selectedResturan,
    }
  );
  const {
    isLoading: vendorDiscountLoading,

    data: vendorDiscounts,
    refetch: vendorDiscountRefetch,
  } = useQuery("vendorDiscountOne", () => getVendorDicountOne(id), {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (selectedResturan || vendorID) {
      setList([]);
      productRefetch();
    }
  }, [selectedResturan, isAllProduct]);

  useEffect(() => {
    if (vendorDiscounts) {
      setList(vendorDiscounts?.data?.product || []);
      setVendorID(vendorDiscounts?.data?.vendorID);

      form.setFieldsValue({
        value: vendorDiscounts?.data?.value,
        startDate: moment(vendorDiscounts?.data?.startDate),
        endDate: moment(vendorDiscounts?.data?.endDate),
        discountType: "PERCENTAGE",

        vendorID: vendorDiscounts?.data?.vendorID,
        isAllProduct: vendorDiscounts?.data?.isAllProduct,
        totalValue: vendorDiscounts?.data?.totalValue,
        vendorValue: vendorDiscounts?.data?.vendorValue,
        balyValue: vendorDiscounts?.data?.balyValue,
        schedule: schedule,
      });
    }
  }, [vendorDiscounts]);

  useEffect(() => {
    if (id) {
      vendorDiscountRefetch();
    } else {
      resetState();
    }
  }, [id]);

  const { mutate, isLoading } = useMutation(createVendorDicount, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendorDiscounts",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorDicount,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendorDiscounts",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  useEffect(() => {
    if (selected && products.success) {
      setList([
        ...list,
        products?.data?.records?.find((el) => el.id == selected),
      ]);
      setSelected(null);
    }
  }, [selected]);

  useEffect(() => {
    if (isAllProduct) {
      setList([]);
    }
  }, [isAllProduct]);

  const onFinish = (values) => {
    values.isAllProduct = isItemRemoved ? false : isAllProduct;
    values.schedule = schedule ? JSON.stringify(schedule) : "[]";
    values.productID = JSON.stringify(list.map((el) => el.id));
    values.discountType = "PERCENTAGE";
    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  const getVendorValue = () => {
    form.setFieldsValue({
      vendorValue:
        form.getFieldValue("totalValue") - form.getFieldValue("balyValue"),
    });
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Vendor Discount</Title>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);

        queryClient.resetQueries("vendorDiscountOne");
      }}
      footer={false}
      destroyOnClose={true}
      maskClosable={false}
      width={"80%"}
    >
      <Spin spinning={vendorDiscountLoading}>
        <Form
          requiredMark={"optional"}
          name="basic"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Row gutter={[20, 20]}>
                <Col span={16}>
                  <Form.Item
                    label="vendor"
                    name="vendorID"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Select
                      loading={vendorLoading}
                      disabled={id ? true : false}
                      showSearch
                      filterOption={true}
                      optionFilterProp="children"
                      onSearch={(val) => setFilter(val)}
                      value={selectedResturan}
                      onChange={(val) => setSelectedvendor(val)}
                      style={{ width: "100%" }}
                      placeholder="Find vendor"
                      allowClear
                    >
                      {vendors?.success
                        ? vendors?.data?.records?.map((el) => (
                            <Option key={el.id} id={el.id} value={el.id}>
                              {el.arName}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Total Value"
                    name="totalValue"
                    rules={
                      type === "AMOUNT"
                        ? [{ required: true, message: "required" }]
                        : [
                            { required: true, message: "required" },
                            {
                              type: "number",
                              min: 0,
                              max: 100,
                              message: "must be percentage",
                            },
                          ]
                    }
                  >
                    {type === "AMOUNT" ? (
                      <InputNumber
                        formatter={(value) => inputNumberFormatter(value)}
                        parser={(value) => inputNumberParser(value)}
                        onChange={getVendorValue}
                        addonAfter="IQD"
                        style={{ width: "100%" }}
                        min={0}
                      />
                    ) : (
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        parser={(value) => value.replace("%", "")}
                        onChange={getVendorValue}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Baly Value"
                    name="balyValue"
                    rules={
                      type === "AMOUNT"
                        ? [{ required: true, message: "required" }]
                        : [
                            { required: true, message: "required" },
                            {
                              type: "number",
                              min: 0,
                              max: 100,
                              message: "must be percentage",
                            },
                          ]
                    }
                  >
                    {type === "AMOUNT" ? (
                      <InputNumber
                        formatter={(value) => inputNumberFormatter(value)}
                        parser={(value) => inputNumberParser(value)}
                        onChange={getVendorValue}
                        addonAfter="IQD"
                        style={{ width: "100%" }}
                        min={0}
                      />
                    ) : (
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        onChange={getVendorValue}
                        parser={(value) => value.replace("%", "")}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Vendor Value"
                    name="vendorValue"
                    rules={
                      type === "AMOUNT"
                        ? [{ required: true, message: "required" }]
                        : [
                            { required: true, message: "required" },
                            {
                              type: "number",
                              min: 0,
                              max: 100,
                              message: "must be percentage",
                            },
                          ]
                    }
                  >
                    {type === "AMOUNT" ? (
                      <InputNumber
                        formatter={(value) => inputNumberFormatter(value)}
                        parser={(value) => inputNumberParser(value)}
                        addonAfter="IQD"
                        readOnly={true}
                        style={{ width: "100%" }}
                        min={0}
                      />
                    ) : (
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        readOnly={true}
                        parser={(value) => value.replace("%", "")}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="End Date"
                    name="endDate"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="isAllProduct" valuePropName="checked">
                    <Checkbox
                      value={isAllProduct}
                      onChange={(e) => setIsAllProduct(e.target.checked)}
                    >
                      All Products in this vendor ?
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Divider style={{ marginTop: 0 }} />
                  <Select
                    disabled={isAllProduct}
                    showSearch
                    filterOption={false}
                    optionFilterProp="children"
                    loading={productLoading}
                    value={selected}
                    onChange={(val) => setSelected(val)}
                    onSearch={(val) => setProductFilter(val)}
                    style={{ width: "100%" }}
                    placeholder="Find Products"
                    allowClear
                  >
                    {products && products.success
                      ? products?.data?.records?.map((el) => (
                          <Option
                            disabled={isRepeated(el.id)}
                            key={el.id}
                            id={el.id}
                            value={el.id}
                          >
                            {el?.product && `${el?.product} - `}{" "}
                            {el?.name && `${el?.name} - `}
                            {el?.size && el?.size} {el?.unit && `${el?.unit}`}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Col>

                <Col span={24}>
                  <div style={{ maxHeight: "24vh", overflow: "auto" }}>
                    <Space wrap>
                      {list.length > 0
                        ? list.map((el) => (
                            <Tag
                              key={el.id}
                              closable
                              onClose={() => {
                                setList(
                                  list.filter((item) => item.id != el.id)
                                );
                                setIsItemReomved(true);
                                form.setFieldsValue({ isAllProduct: false });
                              }}
                            >
                              {el?.product && `${el?.product} - `}{" "}
                              {el?.name && `${el?.name} - `}
                              {el?.size && el?.size} {el?.unit && `${el?.unit}`}
                            </Tag>
                          ))
                        : null}
                    </Space>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={11}>
              <Form.Item
                label="Schedule"
                name="schedule"
                rules={[{ required: true, message: "schedule is required" }]}
              >
                <CustomeCalender
                  value={schedule || null}
                  onChange={(val) => setSchedule(val)}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider />
              <Form.Item>
                <Button
                  loading={isLoading || editLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
