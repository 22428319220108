import create from "zustand";

export const useClient = create((set) => ({
  isCreateModal: false,
  firstName: null,
  lastName: null,
  username: null,
  password: null,
  roleID: 1,
  email: null,
  phone: null,
  authGroupID: 1,
  status: true,
  vendorID: null,
  id: null,
  queryKey: "",
  isReset: false,
  gender: '',
  isCreateBlockModal: false,
  userId: null,
  isSelectReason: false,
  
  setGender: (gender) => set({ gender }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsReset: (isReset) => set({ isReset }),
  setId: (id) => set({ id }),
  setFirstName: (firstName) => set({ firstName }),
  setLastName: (lastName) => set({ lastName }),
  setUsername: (username) => set({ username }),
  setPassword: (password) => set({ password }),
  setRoleID: (roleID) => set({ roleID }),
  setStatus: (status) => set({ status }),
  setEmail: (email) => set({ email }),
  setPhone: (phone) => set({ phone }),
  setVendorID: (vendorID) => set({ vendorID }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setIsCreateBlockModal: (isCreateBlockModal) => set({ isCreateBlockModal }),
  setUserId: (userId) => set({ userId }),
  setIsSelectReason: (isSelectReason) => set({ isSelectReason }),
}));
