import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useApp } from "../../store";
import { useMutation } from "react-query";
import { resetProfile } from "../../api";
import { ResetPassword } from "../../comonents/global/resetPassword";

const { Title } = Typography;

export const ChangePassword = () => {
  const { user, isChangePassword, setIsChangePassword, setIsLogin, setUser } =
    useApp();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(resetProfile, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsChangePassword(false);
        localStorage.removeItem("groceryBO_token");
        localStorage.removeItem("groceryBO_refresh_token");
        localStorage.removeItem("groceryBO_user");
        setUser(null);
        setIsLogin(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    values.id = user.id;
    mutate(values);
  };

  return (
    // <Modal
    //   title={<Title level={5}>Change Password</Title>}
    //   className="app-modal"
    //   centered
    //   visible={isChangePassword}
    //   onCancel={() => {
    //     setIsChangePassword(false);
    //   }}
    //   footer={false}
    //   destroyOnClose={true}
    //   width={400}
    // >
    //   <Form
    //     requiredMark={"optional"}
    //     name="basic"
    //     form={form}
    //     initialValues={{ remember: true, price: 0 }}
    //     onFinish={onFinish}
    //     autoComplete="off"
    //     layout="vertical"
    //   >
    //     <Row gutter={[20, 20]}>
    //       <Col span={24}>
    //         <Form.Item
    //           label="Old Password"
    //           name="oldpassword"
    //           rules={[{ required: true }]}
    //         >
    //           <Input placeholder="*****" />
    //         </Form.Item>
    //       </Col>
    //       <Col span={24}>
    //         <Form.Item
    //           label="New Password"
    //           name="newpassword"
    //           rules={[{ required: true }]}
    //         >
    //           <Input placeholder="*****" />
    //         </Form.Item>
    //       </Col>
    //       <Col span={24}>
    //         <Form.Item
    //           label="Confirm New Password"
    //           name="confirm"
    //           rules={[
    //             {
    //               required: true,
    //               validator: (_, value) => {
    //                 if (value == form.getFieldValue("newpassword")) {
    //                   return Promise.resolve();
    //                 } else {
    //                   return Promise.reject("Passowrd uncorrect!");
    //                 }
    //               },
    //             },
    //           ]}
    //         >
    //           <Input placeholder="*****" />
    //         </Form.Item>
    //       </Col>

    //       <Col span={24}>
    //         <Divider />
    //         <Form.Item>
    //           <Button
    //             loading={isLoading}
    //             type="primary"
    //             htmlType="submit"
    //             icon={<SaveOutlined />}
    //           >
    //             Save
    //           </Button>
    //         </Form.Item>
    //       </Col>
    //     </Row>
    //   </Form>
    // </Modal>
    <ResetPassword />
  );
};
