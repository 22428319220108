import { ClockCircleOutlined } from "@ant-design/icons";
import { Typography, Space, Select, message, Modal } from "antd";
import { useOrderView } from "./store";
import { useState } from "react";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  changeOrderStatus,
  getOrderStatus,
  getDeclineReason,
  changeDeclineReason,
} from "../../api";

import dayjs from "dayjs";
import handleDataMutation from "../../helper/dataMutation";
const { Text, Title } = Typography;
const { Option } = Select;

export const StatusHistory = () => {
  const {
    order,
    setIsCreateCancelModal,
    isSelectReason,
    setSelectedReason,
    selectedReason,
  } = useOrderView();
  const [history, setHistoy] = useState([]);

  const { isLoading: statusLoading, data: orderStatus } = useQuery(
    "orderStatus",
    getOrderStatus,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!order,
    }
  );

  const { isLoading: reasonsLoading, data: reasonData } = useQuery(
    ["declineReasons", "", 1, 500],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  useEffect(() => {
    if (order && order?.order) {
      setHistoy(JSON.parse(order?.order?.status)?.reverse());
    }
  }, [order]);

  const statusRender = () => {
    return (
      <>
        <Select
          dropdownMatchSelectWidth={false}
          size="small"
          value={selectedReason}
          onChange={(val) => handleChangeStatus(val)}
          bordered={false}
          placeholder="Change Status"
          loading={statusLoading}
          style={{ color: "#0000ff", width: "100%" }}
        >
          {orderStatus &&
            orderStatus?.data?.records?.map((el, index) => (
              <Option key={index} value={el.code}>
                {el.arTitle}{" "}
                <small style={{ color: el?.color }}>( {el.code} )</small>
                <br />
                <small style={{ opacity: 0.6 }}>{el.userType}</small>
              </Option>
            ))}
        </Select>
      </>
    );
  };

  const declineReasonsRender = () => {
    return (
      <>
        <Select
          dropdownMatchSelectWidth={false}
          size="small"
          onChange={(val) => handleChangedeclineReason(val)}
          bordered={false}
          value={delicneReasons?.arTitle}
          placeholder="Change Decline Reason"
          loading={reasonsLoading}
          style={{ color: "#0000ff", width: "100%" }}
        >
          {reasonData &&
            reasonData?.data?.records?.map(
              (el, index) =>
                el.userType === "BACKOFFICE_USER" && (
                  <Option
                    disabled={delicneReasons?.arTitle === el.arTitle}
                    key={el.id}
                    value={el.id}
                  >
                    {el.floating ? "Floating :" + el.arTitle : el.arTitle}
                    <br />
                    <small style={{ opacity: 0.6, fontStyle: "italic" }}>
                      code: {el.code}
                    </small>
                  </Option>
                )
            )}
        </Select>
      </>
    );
  };

  const { mutate: changeStatusMutate } = useMutation(changeOrderStatus, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "orderInfo",
        setterList: [() => setSelectedReason(null)],
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: declineReasonMutate } = useMutation(changeDeclineReason, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "orderInfo",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleChangeStatus = (val) => {
    Modal.confirm({
      content: (
        <div>
          <Title level={5}>Confirm</Title>
          <Text>Do you really want to change the status of the order?</Text>
        </div>
      ),
      onOk: () => {
        let dataForm = {
          id: order?.order?.id,
          status: val,
        };
        setSelectedReason(val);
        if (
          val === "CANCELLED" ||
          val === "BACKOFFICE_REJECTED" ||
          val === "REJECTED"
        ) {
          setIsCreateCancelModal(true);
          if (isSelectReason) {
            changeStatusMutate(dataForm);
          }
        } else {
          changeStatusMutate(dataForm);
        }
      },
    });
  };

  const handleChangedeclineReason = (val) => {
    Modal.confirm({
      content: (
        <div>
          <Title level={5}>Confirm</Title>
          <Text>
            Do you really want to change the Decline Reason of the order?
          </Text>
        </div>
      ),
      onOk: () => {
        let dataForm = {
          id: order?.order?.id,
          declineReasonID: Number(val),
        };
        declineReasonMutate(dataForm);
      },
    });
  };

  let delicneReasons =
    order?.order?.declineReason && JSON.parse(order?.order?.declineReason);
  return (
    <>
      {order?.order?.declineReason !== "{}" && (
        <>
          <Text> Decline Reason </Text>
          <div className="status-history">
            <section style={{ padding: "10px 8px" }}>
              {declineReasonsRender()}
            </section>
          </div>
        </>
      )}

      <Text>Status History </Text>
      <div className="status-history">
        <section style={{ padding: "10px 8px" }}>{statusRender()}</section>
        {history?.map((el, index) => (
          <section key={index}>
            <span style={{ color: el?.color }}>
              {el.status} -
              <Text style={{ fontStyle: "italic" }} type="secondary">
                {el?.arTitle}
              </Text>
            </span>
            <div className="app-space-between">
              <div>
                by <b> {el?.userName}</b> ({el?.userType})
              </div>
            </div>
            <Text type="secondary">
              <Space size={4}>
                {dayjs(el.created_at).format("YYYY-MM-DD hh:mm A")}
                <ClockCircleOutlined />
              </Space>
            </Text>
          </section>
        ))}
      </div>
    </>
  );
};
