import create from "zustand";

export const useOrder = create((set) => ({
  queryKey: "",
  queryID: "",
  isCreateModal: false,
  client: null,
  vendor: null,
  products: null,
  voucher: null,
  payment: null,
  id: null,
  isFilter: false,
  queryFilter: "",
  isView: false,
  row: null,
  defaultProducts: [],
  delayedQueryFilter: "",
  filterVendor: "",
  page: 1,
  vendorSearch : "",
  cityID : "",
  clientID : "",
  status : "",
  voucherID : "",
  from : "",
  to : null,
  vendorID : null,
 voucherQuery : "",
 order: null,
 basket: [],
 voucher:null,

 
 setBasket: (basket) => set({ basket }),
  setOrder: (order) => set({ order }),
 setVoucher: (voucher) => set({ voucher }),
  setVoucherQuery: (voucherQuery) => set({ voucherQuery }),
  setVendorID: (vendorID) => set({ vendorID }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setVoucherID: (voucherID) => set({ voucherID }),
  setStatus: (status) => set({ status }),
  setClientID: (clientID) => set({ clientID }),
  setCityID: (cityID) => set({ cityID }),
  setVendorSearch: (vendorSearch) => set({ vendorSearch }),
  setFilterVendor: (filterVendor) => set({ filterVendor }),
  setDeafultProducts: (defaultProducts) => set({ defaultProducts }),
  setRow: (row) => set({ row }),
  setIsView: (isView) => set({ isView }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setIsFilter: (isFilter) => set({ isFilter }),
  setId: (id) => set({ id }),
  setPayment: (payment) => set({ payment }),
  setVoucher: (voucher) => set({ voucher }),
  setProducts: (products) => set({ products }),
  setVendor: (vendor) => set({ vendor }),
  setClient: (client) => set({ client }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setQueryID: (queryID) => set({ queryID }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setDelayedQueryFilter: (delayedQueryFilter) => set({ delayedQueryFilter }),
  setPage: (page) => set({ page }),
}));
