import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  Checkbox,
  DatePicker,
  Input,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDiscount } from "../../store";
import { useMutation, useQuery } from "react-query";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";
import { creatVoucher, editVoucher, getVoucher } from "../../api";
import { CustomeCalender } from "../../comonents/global";
import handleDataMutation from "../../helper/dataMutation";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    code,
    voucherTitle,
    valueType,
    maxAmount,
    minAmount,
    noOfUsage,
    totalQty,
    startDate,
    endDate,
    platform,
    fisrtOrderOnly,
    newUsersOnly,
    UDID,
    applyOverDiscount,
    id,
    setEndDate,
    setStartDate,
    setVoucherTitle,
    setValueType,
    setUserIDs,
    setTotalQty,
    setPlatform,
    setDayOfWeek,
    setMinAmount,
    setId,
    setFisrtOrderOnly,
    setCode,
    setNoOfUsage,
    setMaxAmount,
    setNewUsersOnly,
    setUDID,
    setApplyOverDiscount,
    schedule,
    setSchedule,
    totalValue,
    vendorValue,
    balyValue,
    setBalyValue,
    setTotalValue,
    setVendorValue,
    setAvToCustomers,
    avToCustomers,
    customerDesc,
    setCustomerDesc,
    voucherType,
  } = useDiscount();

  const [form] = Form.useForm();
  const [type, setType] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const resetState = async () => {
    setEndDate(null);
    setType(null);
    setVoucherTitle(null);
    setValueType(null);
    setUserIDs(null);
    setTotalQty(null);
    setPlatform(null);
    setDayOfWeek(null);
    setNewUsersOnly(null);
    setUDID(null);
    setApplyOverDiscount(null);
    setMinAmount(null);
    setId(null);
    setFisrtOrderOnly(null);
    setNoOfUsage(null);
    setMaxAmount(null);
    setCode(null);
    setSchedule(null);
    setBalyValue(null);
    setTotalValue(null);
    setVendorValue(null);
    setMaxValue(null);
    setMinValue(null);
    setAvToCustomers(null);
    setCustomerDesc(null);
    form.resetFields();
  };

  const {
    isLoading: getVoucherLoading,
    data: editVoucherData,
    refetch: VoucherRefetch,
  } = useQuery("voucherOne", () => getVoucher(id), {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (id && editVoucherData?.data) {
      const {
        code,
        voucherTitle,
        valueType,
        maxAmount,
        minAmount,
        noOfUsage,
        totalQty,
        startDate,
        endDate,
        platform,
        fisrtOrderOnly,
        newUsersOnly,
        UDID,
        applyOverDiscount,
        totalValue,
        vendorValue,
        balyValue,
        avToCustomers,
        customerDesc,
        schedule,
        voucherType: voucherType,
      } = editVoucherData?.data;

      form.setFieldsValue({
        code,
        voucherTitle,
        valueType,
        maxAmount,
        minAmount,
        noOfUsage,
        totalQty,

        platform: platform ? JSON.parse(platform) : [],
        fisrtOrderOnly,
        newUsersOnly,
        UDID,
        applyOverDiscount,
        totalValue,
        vendorValue,
        balyValue,
        avToCustomers,
        customerDesc,
        startDate: startDate ? moment(startDate) : null,
        endDate: endDate ? moment(endDate) : null,
        schedule: JSON.parse(schedule ? schedule : "[]"),
        voucherType: voucherType,
      });
      setType(valueType);
    } else {
      form.resetFields();
    }
  }, [editVoucherData?.data, id]);

  const { mutate, isLoading } = useMutation(creatVoucher, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vouchers",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVoucher,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["vouchers", "bulkVouchers"],
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.platform = JSON.stringify(values.platform);
    values.schedule = JSON.stringify(schedule);

    const startDate = new Date(values.startDate);
    startDate.setHours(0, 0, 0, 0);
    values.startDate = startDate;

    const endDate = new Date(values.endDate);
    endDate.setHours(23, 59, 59, 999);
    values.endDate = endDate;

    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values.valueType = "PERCENTAGE";

    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  const handleMinMaxValue = () => {
    if (form.getFieldValue("maxAmount") > form.getFieldValue("minAmount")) {
      form.setFields([
        {
          name: "minAmount",
          errors: [],
        },
        {
          name: "maxAmount",
          errors: [],
        },
      ]);
    }
  };

  const getVendorValue = () => {
    form.setFieldsValue({
      vendorValue:
        form.getFieldValue("totalValue") - form.getFieldValue("balyValue"),
    });
  };

  const handleType = (value) => {
    setType(value);
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Voucher</Title>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
        setType(null);
      }}
      footer={false}
      maskClosable={false}
      width={"80%"}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[20, 20]}>
              <Col span={8}>
                <Form.Item
                  label="Title"
                  name="voucherTitle"
                  rules={[
                    {
                      required: true,
                      message: "required",
                      message: "required",
                    },
                  ]}
                >
                  <Input placeholder={"Voucher Title"} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input placeholder={"Balyf70"} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Discount Type"
                  name="voucherType"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select placeholder="Choose type" onChange={handleType}>
                    <Option value={"DISCOUNT"}>Discount</Option>
                    <Option value={"DELIVERY"}>Free Delivery</Option>
                    <Option value={"COMBINED"}>Combined</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Total Value"
                  name="totalValue"
                  rules={
                    type === "AMOUNT"
                      ? [{ required: true, message: "required" }]
                      : [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "must be percentage",
                          },
                        ]
                  }
                >
                  {type === "AMOUNT" ? (
                    <InputNumber
                      formatter={(value) => inputNumberFormatter(value)}
                      parser={(value) => inputNumberParser(value)}
                      onChange={getVendorValue}
                      addonAfter="IQD"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  ) : (
                    <InputNumber
                      min={0}
                      max={100}
                      addonAfter="%"
                      parser={(value) => value.replace("%", "")}
                      onChange={getVendorValue}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Baly Value"
                  name="balyValue"
                  rules={
                    type === "AMOUNT"
                      ? [{ required: true, message: "required" }]
                      : [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "must be percentage",
                          },
                        ]
                  }
                >
                  {type === "AMOUNT" ? (
                    <InputNumber
                      formatter={(value) => inputNumberFormatter(value)}
                      parser={(value) => inputNumberParser(value)}
                      onChange={getVendorValue}
                      addonAfter="IQD"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  ) : (
                    <InputNumber
                      min={0}
                      max={100}
                      addonAfter="%"
                      parser={(value) => value.replace("%", "")}
                      onChange={getVendorValue}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Vendor Value"
                  name="vendorValue"
                  rules={
                    type === "AMOUNT"
                      ? [{ required: true, message: "required" }]
                      : [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "must be percentage",
                          },
                        ]
                  }
                >
                  {type === "AMOUNT" ? (
                    <InputNumber
                      formatter={(value) => inputNumberFormatter(value)}
                      parser={(value) => inputNumberParser(value)}
                      addonAfter="IQD"
                      readOnly={true}
                      style={{ width: "100%" }}
                      min={0}
                    />
                  ) : (
                    <InputNumber
                      min={0}
                      max={100}
                      addonAfter="%"
                      readOnly={true}
                      parser={(value) => value.replace("%", "")}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="No. Use"
                  name="noOfUsage"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Quentity"
                  name="totalQty"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    min={1}
                  />
                </Form.Item>
              </Col>

              <Col span={7}>
                <Form.Item
                  label="Basket Value (IQD)"
                  name="minAmount"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    onChange={(value) => {
                      setMinValue(value);
                      handleMinMaxValue();
                    }}
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    max={100000}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Max Discount Value(IQD)"
                  name="maxAmount"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    onChange={(value) => {
                      setMaxValue(value);
                      handleMinMaxValue();
                    }}
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    max={100000}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Customer Description"
                  name="customerDesc"
                  rules={[
                    {
                      required: true,
                      message: "required",
                      message: "required",
                    },
                  ]}
                >
                  <Input placeholder={"Customer Description"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Platforms"
                  name="platform"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    mode="tags"
                    placeholder="Choose platform"
                    style={{
                      maxHeight: "80px",
                      overflow: "auto",
                    }}
                  >
                    <Option value={"PWA"}>PWA</Option>
                    <Option value={"WEB"}>WEB</Option>
                    <Option value={"IOS_SUPERAPP"}>IOS_SUPERAPP</Option>
                    <Option value={"ANDROID_SUPERAPP"}>ANDROID_SUPERAPP</Option>
                    <Option value={"IOS_SHELL"}>IOS_SHELL</Option>
                    <Option value={"ANDROID_SHELL"}>ANDROID_SHELL</Option>
                    <Option value={"IOS"}>IOS</Option>
                    <Option value={"ANDROID"}>ANDROID</Option>
                    <Option value={"WINDOWS10"}>WINDOWS10</Option>
                    <Option value={"DESKTOP_WEB"}>DESKTOP_WEB</Option>
                    <Option value={"MOBILE_WEB"}>MOBILE_WEB</Option>
                    <Option value={"WEB_IOS"}>WEB_IOS</Option>
                    <Option value={"WEB_MACOS"}>WEB_MACOS</Option>
                    <Option value={"WEB_WINDOWS"}>WEB_WINDOWS</Option>
                    <Option value={"SUPERAPP_ANDROID"}>SUPERAPP_ANDROID</Option>
                    <Option value={"SUPERAPP_IOS"}>SUPERAPP_IOS</Option>
                    <Option value={"WEB_ANDROID"}>WEB_ANDROID</Option>
                    <Option value={"SHELL_IOS"}>SHELL_IOS</Option>
                    <Option value={"SHELL_ANDROID"}>SHELL_ANDROID</Option>
                    <Option value={"OTHER"}>OTHER</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="fisrtOrderOnly" valuePropName="checked">
                  <Checkbox>First Order Only?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="newUsersOnly" valuePropName="checked">
                  <Checkbox>New Users Only?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="UDID" valuePropName="checked">
                  <Checkbox> Devices Only?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="applyOverDiscount" valuePropName="checked">
                  <Checkbox>Apply over discount ?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="avToCustomers" valuePropName="checked">
                  <Checkbox> Available to Customers ?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
                <Form.Item>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={11}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Schedule"
                  name="schedule"
                  rules={[{ required: true, message: "schedule is required" }]}
                >
                  <CustomeCalender
                    value={id ? schedule : null}
                    onChange={(val) => setSchedule(val)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
