import { useState, useEffect, useMemo } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import authFunc from "../../helper/auth";
import { getBlockReason, removeBlockReason, pageSize } from "../../api";
import { useBlockReason } from "./store";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const [page, setPage] = useState(1);
  const { setId, setIsCreateModal, setName, queryKey } = useBlockReason();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Category Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        render: (id, row) => (
          <Space>
            {authFunc("BLOCK_REASON_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("BLOCK_REASON_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation(removeBlockReason, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "blockReason",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["blockReason", queryKey, page], getBlockReason, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
