import { useState, useEffect , useMemo} from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { getBrand, removeBrand, pageSize } from "../../api";
import { useBrand } from "./store";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const { setId, setIsCreateModal, setName, queryKey } = useBrand();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Brand Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Update Date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("BRAND_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("BRAND_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this Brand?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate } = useMutation(removeBrand, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "brand",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["brand", queryKey, page], getBrand, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.brands : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.count}
        showSizeChanger={false}
      />
    </>
  );
};
