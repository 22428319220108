import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  Select,
  Checkbox,
} from "antd";
import { message as ANTmessage } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useVoucherSettings } from "./store";
import { useMutation } from "react-query";

import { createVoucherSettings, editVoucherSettings } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";
const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    id,
    voucherTitle,
    message,
    balyValue,
    vendorValue,
    type,
    valueType,
    maxAmount,
    minAmount,
    numberOfDays,
    setId,
    setIsCreateModal,
    setVoucherTitle,
    setMessage,
    setBalyValue,
    setVendorValue,
    setType,
    setValueType,
    setMaxAmount,
    setMinAmount,
    setNumberOfDays,
    setApplyOverDiscount,
    applyOverDiscount,
    setAvToCustomers,
    avToCustomers,
    customerDesc,
    setCustomerDesc,
    platform,
  } = useVoucherSettings();

  const resetState = () => {
    setId(null);
    setVoucherTitle(null);
    setMessage(null);
    setBalyValue(null);
    setVendorValue(null);
    setType(null);
    setValueType(null);
    setMaxAmount(null);
    setMinAmount(null);
    setNumberOfDays(null);
    setApplyOverDiscount(null);
    setAvToCustomers(null);
    setCustomerDesc(null);
  };

  const { mutate, isLoading } = useMutation(createVoucherSettings, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "voucherSettings",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      ANTmessage.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVoucherSettings,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "voucherSettings",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    const platformArray = values.platform;
    values.platform = JSON.stringify(platformArray);

    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  const handleType = (value) => {
    setValueType(value);
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} voucher Setting</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          voucherTitle,
          message,
          balyValue,
          type,
          valueType,
          maxAmount,
          minAmount,
          numberOfDays,
          applyOverDiscount,
          avToCustomers,
          customerDesc,
          vendorValue,
          platform,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              label="voucher Title"
              name="voucherTitle"
              rules={[{ required: true }]}
            >
              <Input placeholder="voucher Title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Customer Description"
              name="customerDesc"
              rules={[{ required: true }]}
            >
              <Input placeholder="Customer Description" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: false }]}
            >
              <Input placeholder="message" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
              <Select placeholder="Choose type">
                <Option value={"APOLOGY"}>Apology</Option>
                <Option value={"REFERRAL"}>Referral</Option>
                <Option value={"BATCH"}>Batch</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Number Of Days"
              name="numberOfDays"
              rules={[{ required: true }]}
            >
              <InputNumber
                rows={4}
                placeholder={"Number Of Days"}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => inputNumberParser(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Value Type"
              name="valueType"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose type" onChange={handleType}>
                <Option value={"PERCENTAGE"}>percentage</Option>
                <Option value={"AMOUNT"}>amount</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Baly Value"
              name="balyValue"
              rules={
                valueType === "AMOUNT"
                  ? [{ required: true, message: "required" }]
                  : [
                      { required: true, message: "required" },
                      {
                        type: "number",
                        min: 0,
                        max: 100,
                        message: "must be percentage",
                      },
                    ]
              }
            >
              {valueType === "AMOUNT" ? (
                <InputNumber
                  formatter={(value) => inputNumberFormatter(value)}
                  parser={(value) => inputNumberParser(value)}
                  placeholder={"Baly Value"}
                  addonAfter="IQD"
                  style={{ width: "100%" }}
                  min={0}
                />
              ) : (
                <InputNumber
                  min={0}
                  max={100}
                  addonAfter="%"
                  placeholder={"Baly Value"}
                  parser={(value) => value.replace("%", "")}
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Vendor Value"
              name="vendorValue"
              rules={
                valueType === "AMOUNT"
                  ? [{ required: true, message: "required" }]
                  : [
                      { required: true, message: "required" },
                      {
                        type: "number",
                        min: 0,
                        max: 100,
                        message: "must be percentage",
                      },
                    ]
              }
            >
              {valueType === "AMOUNT" ? (
                <InputNumber
                  formatter={(value) => inputNumberFormatter(value)}
                  parser={(value) => inputNumberParser(value)}
                  placeholder={"Vendor Value"}
                  addonAfter="IQD"
                  style={{ width: "100%" }}
                  min={0}
                />
              ) : (
                <InputNumber
                  min={0}
                  max={100}
                  addonAfter="%"
                  placeholder={"Baly Value"}
                  parser={(value) => value.replace("%", "")}
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Max Amount"
              name="maxAmount"
              rules={[{ required: true }]}
            >
              <InputNumber
                rows={4}
                placeholder={"Max Amount"}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => inputNumberParser(value)}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Min Amount"
              name="minAmount"
              rules={[{ required: true }]}
            >
              <InputNumber
                rows={4}
                placeholder={"Min Amount"}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => inputNumberParser(value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Platforms"
              name="platform"
              rules={[{ required: true, message: "required" }]}
            >
              <Select
                mode="tags"
                placeholder="Choose platform"
                style={{
                  maxHeight: "80px",
                  overflow: "auto",
                }}
              >
                <Option value={"PWA"}>PWA</Option>
                <Option value={"WEB"}>WEB</Option>
                <Option value={"IOS_SUPERAPP"}>IOS_SUPERAPP</Option>
                <Option value={"ANDROID_SUPERAPP"}>ANDROID_SUPERAPP</Option>
                <Option value={"IOS_SHELL"}>IOS_SHELL</Option>
                <Option value={"ANDROID_SHELL"}>ANDROID_SHELL</Option>
                <Option value={"IOS"}>IOS</Option>
                <Option value={"ANDROID"}>ANDROID</Option>
                <Option value={"WINDOWS10"}>WINDOWS10</Option>
                <Option value={"DESKTOP_WEB"}>DESKTOP_WEB</Option>
                <Option value={"MOBILE_WEB"}>MOBILE_WEB</Option>
                <Option value={"WEB_IOS"}>WEB_IOS</Option>
                <Option value={"WEB_MACOS"}>WEB_MACOS</Option>
                <Option value={"WEB_WINDOWS"}>WEB_WINDOWS</Option>
                <Option value={"SUPERAPP_ANDROID"}>SUPERAPP_ANDROID</Option>
                <Option value={"SUPERAPP_IOS"}>SUPERAPP_IOS</Option>
                <Option value={"WEB_ANDROID"}>WEB_ANDROID</Option>
                <Option value={"SHELL_IOS"}>SHELL_IOS</Option>
                <Option value={"SHELL_ANDROID"}>SHELL_ANDROID</Option>
                <Option value={"OTHER"}>OTHER</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="applyOverDiscount" valuePropName="checked">
              <Checkbox>Apply over discount ?</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="avToCustomers" valuePropName="checked">
              <Checkbox> Available to Customers ?</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
