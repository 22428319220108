import create from "zustand";

export const useProductVariation = create((set) => ({
  isCreateModal: false,
  
  id: null,
  name: null,
  dimension: null,
  queryKey: "",
  size: null,
  unit: null,
  img: null,
  barcode: null,
  
  setQueryKey: (queryKey) => set({ queryKey }),

  setId: (id) => set({ id }),
  setSize: (size) => set({ size }),
  setUnit: (unit) => set({ unit }),
  setName: (name) => set({ name }),
  setDimension: (dimension) => set({ dimension }),
  setImg: (img) => set({ img }),
  setBarcode: (barcode) => set({ barcode }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));