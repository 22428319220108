import { Button, Divider, Space, message } from "antd";

import { useVendor } from "../store";
import { useMutation } from "react-query";

import { editeVendor } from "../../../api";
import handleDataMutation from "../../../helper/dataMutation";
import { PureMap } from "../map";

export const GetLocation = () => {
  const {
    id,
    setId,
    step,
    setStep,
    setIsNewClient,
    setIsCreateModal,
    setIsClientExest,
    delieveryFeeID,
    setdDelieveryfeeid,

    maxamount,
    minamount,
    setMaxamount,
    setMinamount,

    deliveryDistanceID,
    serviceFeeID,
    setDeliveryDistanceID,
    setServiceFeeID,

    setHasPreOrder,
    hasPreOrder,

    setIsModalFilter,
    setWorkingSchedule,
    workingSchedule,

    arName,
    rank,
    provinceID,
    cityID,
    zoneID,
    address,
    ownerName,
    businessCategoryIDS,
    phone,
    mgmtPhone,
    deliveryRangeDistance,
    statusCodeID,
    description,
    lat,
    lon,
    setLat,
    setLon,
    setBannerFile,
    setLogoFile,
    setOwnerName,
    setProvinceID,
    setCityID,
    setZoneID,
    setStatusCodeID,
    setDeliveryRangeDistance,
    setPhone,
    setMgmtPhone,
    setDescription,
    setBusinessCategoryIDS,
    setAddress,
    setArName,
    setRank,
    setCommission,
    setCommissionType,
    commission,
    commissionType,
  } = useVendor();

  const { mutate, isLoading } = useMutation(editeVendor, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendors",
        setterList: [
          () => setIsCreateModal(false),
          () => setIsCreateModal(false),
          () => setIsClientExest(false),
          () => setIsNewClient(false),
          () => setHasPreOrder(false),
          () => setIsModalFilter(true),
          () => setId(null),
          () => setLat(""),
          () => setLon(""),
          () => setBannerFile(""),
          () => setLogoFile(""),
          () => setOwnerName(null),
          () => setProvinceID(null),
          () => setCityID(null),
          () => setZoneID(null),
          () => setStatusCodeID(null),
          () => setDeliveryRangeDistance(null),
          () => setPhone(null),
          () => setMgmtPhone(null),
          () => setDescription(null),
          () => setBusinessCategoryIDS([]),
          () => setAddress(null),
          () => setArName(null),
          () => setRank(null),
          () => setdDelieveryfeeid(null),
          () => setDeliveryDistanceID(null),
          () => setMaxamount(null),
          () => setMinamount(null),
          () => setStep(0),
          () => setServiceFeeID(null),
          () => setWorkingSchedule(null),
          () => setCommission(null),
          () => setCommissionType(null),
        ],
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleSave = () => {
   mutate({
      id,
      arName,
      enName: arName,
      kuName: arName,
      rank,
      provinceID,
      cityID,
      zoneID,
      address,
      ownerName,
      businessCategoryIDS: JSON.stringify(businessCategoryIDS),
      phone,
      mgmtPhone,
      deliveryRangeDistance: deliveryRangeDistance,
      statusCodeID,
      description,
      active: true,
      lat,
      lon,
      delieveryFeeID,
      deliveryDistanceID,
      maxamount,
      serviceFeeID,
      minamount,
      hasPreOrder,
      workingSchedule:JSON.stringify(workingSchedule),
      commission,
      commissionType,
    });
  };



  return (
    <>
      <PureMap location={lat & lon ? [lon, lat] : null} />
      <div
        className="resturant-constroll-form"
        style={{ paddingRight: 10, paddingLeft: 10 }}
      >
        <Divider />
        <Space>
          <Button onClick={() => setStep(step - 1)}>Back</Button>
          {lat && lon ? (
            <Button
              loading={isLoading}
              type="primary"
              onClick={() => (id ? handleSave() : setStep(step + 1))}
            >
              {id ? "Finish" : "Next"}
            </Button>
          ) : null}
        </Space>
      </div>
    </>
  );
};
