import create from "zustand";

export const useProduct = create((set) => ({
  isCreateModal: false,
  isFileModal: false,
  isHistoryModal: false,
  id: null,
  name: null,
  description: null,
  queryKey: "",
  categoryID: null,
  subCategoryID: null,
  brandID: null,
  statusHistory: null,
  priority: null,
  // limit:null,
  page: 1,
  queryFilter: "",
  categorySearch : "",
  subCategorySearch : "",
  brandSearch : "",
  selectedCategory:null,
  selectedSubCategory:null,
  selectedBrand:null,


  setSelectedCategor: (selectedCategory) => set({ selectedCategory }),
  setSelectedSubCategory: (selectedSubCategory) => set({ selectedSubCategory }),
  setSelectedBrand: (selectedBrand) => set({ selectedBrand }),
  setCategorySearch: (categorySearch) => set({ categorySearch }),
  setSubCategorySearch: (subCategorySearch) => set({ subCategorySearch }),
  setBrandSearch: (brandSearch) => set({ brandSearch }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setCategoryID: (categoryID) => set({ categoryID }),
  setSubCategoryID: (subCategoryID) => set({ subCategoryID }),
  setBrandID: (brandID) => set({ brandID }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setIsFileModal: (isFileModal) => set({ isFileModal }),
  setIsHistoryModal: (isHistoryModal) => set({ isHistoryModal }),
  setStatusHistory: (statusHistory) => set({ statusHistory }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setPage: (page) => set({ page }),
  setPriority: (priority) => set({ priority }),
  // setLimit: (limit) => set({ limit }),
}));
