import {
  Drawer,
  Button,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Avatar,
  List,
} from "antd";
import { useOrder } from "./store";
import { useEffect } from "react";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { PureMap } from "./map";
import { useState } from "react";
import numberForamt from "../../helper/numberFormat";
const { Title, Text } = Typography;
const { Meta } = Card;


export const OrderView = () => {
  const { isView, setIsView, row } = useOrder();

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (row) {
      setItems(row?.data?.order?.orderDetail);
    }
  }, [row]);

  useEffect(() => {}, [row]);

  return (
    <Drawer
      title="Basic Drawer"
      footer={null}
      headerStyle={{ display: "none" }}
      placement="right"
      onClose={() => setIsView(false)}
      visible={isView}
      width={"100%"}
    >
      <div className="app-order-details app-container">
        <section className="header app-flex">
          <h1>Order Detials</h1>
          <Button
            onClick={() => setIsView(false)}
            type="text"
            icon={<CloseOutlined />}
          />
        </section>
        <section className="body">
          <Row gutter={[30, 30]}>
            <Col span={10}>
              <Card
                style={{ width: "100%" }}
                bodyStyle={{ padding: 0 }}
                size="small"
                cover={
                  <PureMap
                    position={[
                      row?.data?.order?.clientLon,
                      row?.data?.order?.clientLat,
                    ]}
                  />
                }
              ></Card>
            </Col>
            <Col span={8}>
              <Card>
                <List
                  itemLayout="horizontal"
                  footer={
                    <Space
                      size={10}
                      direction="vertical"
                      style={{ padding: "0px 5px" }}
                    >
                      <Space align="end" size={20}>
                        <Text>Sub Total :</Text>
                        <Title level={5}>
                          {numberForamt(row?.data?.order?.subTotal)} IQD
                        </Title>
                      </Space>
                      <Space align="end" size={20}>
                        <Text>Total Value :</Text>
                        <Title level={5}>
                          {numberForamt(row?.data?.order?.totalValue)} IQD
                        </Title>
                      </Space>
                    </Space>
                  }
                  dataSource={items}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          size="small"
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                        />,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={<Avatar src={item.product?.img} />}
                        title={item.product?.name}
                        description={
                          <b
                            style={{ color: "#0000ff", fontSize: 14 }}
                          >{`${numberForamt(item.price)} IQD`}</b>
                        }
                      />
                      <Text>Qt : {item.qty}</Text>
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card style={{ width: "100%" }} size="small">
                <Space size={16}>
                  <Avatar size="large" />
                  <Space size={0} direction="vertical">
                    <Title style={{ margin: 0 }} level={5}>
                      Murtadha M.Abed
                    </Title>
                    <Space>
                      <Text>07710000000</Text>/ <Text>المنصور قرب الشارع</Text>
                    </Space>
                  </Space>
                </Space>
              </Card>

              <Card
                size="small"
                style={{
                  marginTop: 16,
                }}
                cover={
                  <img
                    style={{ height: 180, objectFit: "cover" }}
                    alt="example"
                    src={row?.data?.vendor?.bannerImg}
                  />
                }
              >
                <Meta
                  title="مطعم كباب جبار"
                  description="المنصور شارع الشوارع"
                />
              </Card>
            </Col>
          </Row>
        </section>
      </div>
    </Drawer>
  );
};
