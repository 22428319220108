import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
} from "antd";
import { SafetyOutlined } from "@ant-design/icons";
import { useVendorPayment } from "./store";
import { useMutation } from "react-query";

import { createPayment } from "../../api";
import { useState } from "react";
import numberForamt from "../../helper/numberFormat";
import handleDataMutation from "../../helper/dataMutation";
import { inputNumberFormatter } from "../../helper/inputNumberFormatter";
const { Title } = Typography;

export const ConfirmPayment = () => {
  const {
    setIsConfirmModal,
    setTotalValue,
    setIsPayModal,
    setPayedAmount,
    setReceiverName,
    isConfirmModal,

    receiverName,
    payedAmount,

    vendorID,
  } = useVendorPayment();

  const [isEqual, setIsEqual] = useState(null);

  const resetState = () => {
    setIsConfirmModal(false);
    setIsPayModal(false);
    setIsEqual(null);
    setPayedAmount(null);
    setReceiverName(null);
    setTotalValue(null);
  };

  const { mutate } = useMutation(createPayment, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendorPayment",
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (val) => {
    if (val.amount === payedAmount) {
      setIsEqual(true);
      let _data = {
        vendorID: vendorID,
        receiverName: receiverName,
        amount: payedAmount,
      };

      mutate(_data);
    } else {
      setIsEqual(false);
    }
  };

  return (
    <Modal
      title={<Title level={5}> Confirm Payment </Title>}
      className="app-modal"
      centered
      visible={isConfirmModal}
      onCancel={() => {
        setIsConfirmModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <p>
        {" "}
        Type the amount {numberForamt(payedAmount)} IQD to confirm payment{" "}
      </p>

      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Amount"
              name="amount"
              validateStatus={isEqual === false && "error"}
              help={isEqual === false && "Should be the same amount"}
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder={"Amount"}
                addonAfter="IQD"
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SafetyOutlined />}
              >
                Confirm
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
