import { Card, Input, Breadcrumb, Space } from "antd";
import { PureTable } from "./table";
import {OrderView} from "../orders/view";
import { useOrder } from "../orders/store";
import OrderFilters from "../../comonents/Filters/orderFilters";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


const { Search } = Input;

export const DelayedOrdersPage = () => {
    const { setQueryKey, queryKey } = useOrder();
    const [search, setSearch] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if (queryKey) {
          setSearch(queryKey);
        }
      }, []);

      useEffect(() => {
        if (searchParams.get("q")) {
          setSearch(searchParams.get("q"));
          setQueryKey(searchParams.get("q"));
        } else {
          setSearch("");
          setQueryKey("");
        }
      }, [searchParams.get("q")]);
     
    return (
        <div className="app-page">
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Orders</Breadcrumb.Item>
                <Breadcrumb.Item>Delayed Orders List</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <div className="app-space-between">
                <Space direction="vertical">
                    <Search
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onSearch={(val) => {
                            searchParams.set("q", val);
                            searchParams.set("page_num", 1);
                            setSearchParams(searchParams);
                            setQueryKey(val);
                        }}
                        style={{ width: 250 }}
                        placeholder="Write To Search..."
                    />
                  <OrderFilters />
                  </Space>
                </div>
               <br/>
                <PureTable />
                <OrderView />
            </Card>
        </div>
    );
};
