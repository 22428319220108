import "./App.css";
import {
  VendorsPage,
  CategoriesPage,
  LoginPage,
  OrdersPage,
  DelayedOrdersPage,
  VouchersPage,
  ProductsReviewPage,
  RegionsPage,
  ZonesPage,
  RolesPage,
  ProductsPage,
  EmployeesPage,
  MediaPage,
  ClientsPage,
  GovernoratesPage,
  VendorCategoriesPage,
  StatusCodesPage,
  VmsSettingPage,
  ReferalCodesPage,
  ReferralSettingsPage,
  PwaSettingsPage,
  VoucherSettingsPage,
  ContarctsPage,
  VendorsReviewPage,
  OrderStatusPage,
  PaymentTypePage,
  DeclineReasonsPage,
  ReviewReasonsPage,
  DiscountsPage,
  OrderViewPage,
  MsgTemplatePage,
  TokensPage,
  VendorLogPage,
  BlockReasonPage,
  VendorPaymentPage,
  BulkVouchersPage,
  AuthGroupPage,
  DeliveryFeePage,
  DeliveryDistancePage,
  ApologySettingsPage,
  AppBannersPage,
  VendorUserPage,
  ProductVariationPage,
  VendorProductVariationPage,
  BrandPage,
  SubCategoriesPage,
  BikerReviewPage,
  ServiceFeePage,
  FreeDeliveryPage,
  CompoundVouchers,
} from "./pages";
import { useApp } from "./store";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Layout, Spin } from "antd";
import { AppHeader } from "./comonents";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./queryClient";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect } from "react";
import { CustomErrorBoundary } from "./comonents/CustomErrorBoundry/index";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

import authFunc from "./helper/auth";
import { ResetPassword } from "./comonents/global/resetPassword";

const { Content } = Layout;

function App() {
  const {
    isLogin,
    setIsLogin,
    setUser,
    appLoading,
    setAppLoading,
    setIsReset,
    isReset
  } = useApp();
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("groceryBO_token");
    const _user = localStorage.getItem("groceryBO_user");
    if (token && _user) {
      setIsLogin(true);
      setUser(JSON.parse(_user));
    }
  }, []);



  // const refreshToken = async () => {
  //   setAppLoading(true);
  //   refresh(async (resp, err) => {
  //     if (err) return loagout();
  //     if (resp.success) {
  //       setAppLoading(false);
  //       localStorage.setItem("groceryBO_token", resp.data.token);
  //       localStorage.setItem("groceryBO_refresh_token", resp.data.refreshToken);
  //     } else if (resp.err === "password expired") {
  //       localStorage.clear();
  //       setIsLogin(false);
  //       setUser(null);
  //       setIsReset(true);
  //     } else {
  //       loagout();
  //     }
  //   });
  // };


  // useEffect(() => {
  //   const token = localStorage.getItem("groceryBO_token");
  //   if (token) {
  //     var decoded = jwt_decode(token);
  //     if (!dayjs().isAfter(dayjs(decoded.exp))) {
  //       refreshToken();
  //     } else {
  //       setAppLoading(false);
  //     }
  //   } else {
  //     loagout();
  //   }
  // }, [location]);

  return (
    <CustomErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Layout className="layout">
          {isLogin ? (
            location?.pathname.split("/")[1] !== "order" ? (
              <AppHeader />
            ) : null
          ) : null}
          <Content className="app-content">
            <div className="app-container" style={{ marginTop: 40 }}>
              <Spin spinning={appLoading}>
                {!appLoading && (
                  <Routes>
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route
                      exact
                      path="/"
                      element={
                        <PrivateRoute Component={OrdersPage} auth={isLogin} />
                      }
                    />
                    <Route
                      exact
                      path="/categories"
                      element={
                        <PrivateRoute
                          Component={CategoriesPage}
                          auth={isLogin && authFunc("CATEGORY_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/brand"
                      element={
                        <PrivateRoute
                          Component={BrandPage}
                          auth={isLogin && authFunc("BRAND_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/categories/:categoryID/subCategories"
                      element={
                        <PrivateRoute
                          Component={SubCategoriesPage}
                          auth={isLogin && authFunc("CATEGORY_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor"
                      element={
                        <PrivateRoute
                          Component={VendorsPage}
                          auth={isLogin && authFunc("VENDOR_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_user"
                      element={
                        <PrivateRoute
                          Component={VendorUserPage}
                          auth={isLogin && authFunc("VENDOR_READ")}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/delayed_orders"
                      element={
                        <PrivateRoute
                          Component={DelayedOrdersPage}
                          auth={isLogin && authFunc("ORDER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/order/:id"
                      element={
                        <PrivateRoute
                          Component={OrderViewPage}
                          auth={isLogin && authFunc("ORDER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/order_status"
                      element={
                        <PrivateRoute
                          Component={OrderStatusPage}
                          auth={isLogin && authFunc("ORDER_STATUS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/payment_typ"
                      element={
                        <PrivateRoute
                          Component={PaymentTypePage}
                          auth={isLogin && authFunc("PAYMENT_TYPE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/review_reasons"
                      element={
                        <PrivateRoute
                          Component={ReviewReasonsPage}
                          auth={isLogin && authFunc("REVIEW_REASON_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/decline_reason"
                      element={
                        <PrivateRoute
                          Component={DeclineReasonsPage}
                          auth={isLogin && authFunc("REASON_READ")}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/clients"
                      element={
                        <PrivateRoute
                          Component={ClientsPage}
                          auth={isLogin && authFunc("CLIENT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/tokens"
                      element={
                        <PrivateRoute
                          Component={TokensPage}
                          auth={isLogin && authFunc("TOKEN_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/msg_template"
                      element={
                        <PrivateRoute
                          Component={MsgTemplatePage}
                          auth={isLogin && authFunc("MSG_TEMPLATE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/authGroup"
                      element={
                        <PrivateRoute
                          Component={AuthGroupPage}
                          auth={isLogin && authFunc("AUTH_GROUP_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendors"
                      element={
                        <PrivateRoute
                          Component={VendorsPage}
                          auth={isLogin && authFunc("VENDOR_USER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/employees"
                      element={
                        <PrivateRoute
                          Component={EmployeesPage}
                          auth={isLogin && authFunc("USER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vouchers"
                      element={
                        <PrivateRoute
                          Component={VouchersPage}
                          auth={isLogin && authFunc("VOUCHER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/compound_vouchers"
                      element={
                        <PrivateRoute
                          Component={CompoundVouchers}
                          auth={isLogin && authFunc("VOUCHER_READ")}
                        />
                      }
                    />

                    {/* <Route
                      exact
                      path="/discountCap"
                      element={
                        <PrivateRoute
                          Component={DiscountCapPage}
                          auth={isLogin && authFunc("DISCOUNT_CAP_READ")}
                        />
                      }
                    /> */}
                    <Route
                      exact
                      path="/discounts"
                      element={
                        <PrivateRoute
                          Component={DiscountsPage}
                          auth={isLogin && authFunc("VENDOR_DISCOUNT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/delivery_fee"
                      element={
                        <PrivateRoute
                          Component={DeliveryFeePage}
                          auth={isLogin && authFunc("FEE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/delivery_time"
                      element={
                        <PrivateRoute
                          Component={DeliveryDistancePage}
                          auth={isLogin && authFunc("DISTANCE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/products_review"
                      element={
                        <PrivateRoute
                          Component={ProductsReviewPage}
                          auth={isLogin && authFunc("PRODUCT_REVIEW_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendors_review"
                      element={
                        <PrivateRoute
                          Component={VendorsReviewPage}
                          auth={isLogin && authFunc("VENDOR_REVIEW_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/biker_review"
                      element={
                        <PrivateRoute
                          Component={BikerReviewPage}
                          auth={isLogin && authFunc("BIKER_REVIEW_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/governorates"
                      element={
                        <PrivateRoute
                          Component={GovernoratesPage}
                          auth={isLogin && authFunc("AREA_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/regions"
                      element={
                        <PrivateRoute
                          Component={RegionsPage}
                          auth={isLogin && authFunc("AREA_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/zones"
                      element={
                        <PrivateRoute
                          Component={ZonesPage}
                          auth={isLogin && authFunc("AREA_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/roles"
                      element={
                        <PrivateRoute Component={RolesPage} auth={isLogin} />
                      }
                    />
                    <Route
                      exact
                      path="/status_codes"
                      element={
                        <PrivateRoute
                          Component={StatusCodesPage}
                          auth={isLogin && authFunc("STATUS_CODE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/block_reason"
                      element={
                        <PrivateRoute
                          Component={BlockReasonPage}
                          auth={isLogin && authFunc("BLOCK_REASON_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/VMS_settings"
                      element={
                        <PrivateRoute
                          Component={VmsSettingPage}
                          auth={isLogin && authFunc("VMS_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/referal_codes"
                      element={
                        <PrivateRoute
                          Component={ReferalCodesPage}
                          auth={isLogin && authFunc("REFERRAL_CODE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_payment"
                      element={
                        <PrivateRoute
                          Component={VendorPaymentPage}
                          auth={isLogin}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_log"
                      element={
                        <PrivateRoute
                          Component={VendorLogPage}
                          auth={isLogin && authFunc("VENDOR_LOG_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/referral_settings"
                      element={
                        <PrivateRoute
                          Component={ReferralSettingsPage}
                          auth={isLogin && authFunc("REFERRAL_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/apology_settings"
                      element={
                        <PrivateRoute
                          Component={ApologySettingsPage}
                          auth={isLogin && authFunc("APOLOGY_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/PWA_settings"
                      element={
                        <PrivateRoute
                          Component={PwaSettingsPage}
                          auth={isLogin && authFunc("SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/voucher_settings"
                      element={
                        <PrivateRoute
                          Component={VoucherSettingsPage}
                          auth={isLogin && authFunc("VOUCHER_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/bulk_voucher"
                      element={
                        <PrivateRoute
                          Component={BulkVouchersPage}
                          auth={isLogin}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendors/:id/contracts"
                      element={
                        <PrivateRoute
                          Component={ContarctsPage}
                          auth={isLogin && authFunc("VENDOR_CONTRACT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/media"
                      element={
                        <PrivateRoute Component={MediaPage} auth={isLogin} />
                      }
                    />
                    <Route
                      exact
                      path="/app_banners"
                      element={
                        <PrivateRoute
                          Component={AppBannersPage}
                          auth={isLogin && authFunc("APPBANNER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_categories"
                      element={
                        <PrivateRoute
                          Component={VendorCategoriesPage}
                          auth={isLogin && authFunc("CATEGORY_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/products"
                      element={
                        <PrivateRoute
                          Component={ProductsPage}
                          auth={isLogin && authFunc("PRODUCT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/products/:productID/productVariation"
                      element={
                        <PrivateRoute
                          Component={ProductVariationPage}
                          auth={isLogin && authFunc("PRODUCT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/service_fee"
                      element={
                        <PrivateRoute
                          Component={ServiceFeePage}
                          auth={isLogin && authFunc("FEE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendors/:vendorID/vendorProductVariation"
                      element={
                        <PrivateRoute
                          Component={VendorProductVariationPage}
                          auth={isLogin && authFunc("PRODUCT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/delivery_discounts"
                      element={
                        <PrivateRoute
                          Component={FreeDeliveryPage}
                          auth={isLogin && authFunc("DELIVERY_DISCOUNT_READ")}
                        />
                      }
                    />
                  </Routes>
                )}
              </Spin>
            </div>
          </Content>
        </Layout>
        <ResetPassword />
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </CustomErrorBoundary>
  );
}

const PrivateRoute = ({ Component, auth }) => {
  return auth ? <Component /> : <Navigate to="/login" />;
};

export default App;
