import { Button, Divider, Upload, Space, message } from "antd";

import { useVendor } from "../store";
import {
  SmileOutlined,
  PictureOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-query";

import { createVendor } from "../../../api";
import { listSchedule } from "../../../comonents/global";
import handleDataMutation from "../../../helper/dataMutation";

const { Dragger } = Upload;

export const GetImages = () => {
  const {
    step,
    setStep,
    setIsNewClient,
    setIsCreateModal,
    setIsClientExest,
    delieveryFeeID,
    setdDelieveryfeeid,

    maxamount,
    minamount,
    setMaxamount,
    setMinamount,

    deliveryDistanceID,
    setDeliveryDistanceID,
    setServiceFeeID,
    serviceFeeID,
    setHasPreOrder,
    hasPreOrder,

    setIsModalFilter,

    arName,
    rank,
    provinceID,
    cityID,
    zoneID,
    address,
    ownerName,
    businessCategoryIDS,
    phone,
    mgmtPhone,
    logoFile,
    bannerFile,
    deliveryRangeDistance,
    statusCodeID,
    description,
    lat,
    lon,
    setLat,
    setLon,
    setBannerFile,
    setLogoFile,
    setOwnerName,
    setProvinceID,
    setCityID,
    setZoneID,
    setStatusCodeID,
    setDeliveryRangeDistance,
    setPhone,
    setMgmtPhone,
    setDescription,
    setBusinessCategoryIDS,
    setAddress,
    setArName,
    setRank,
    commission,
    commissionType,
    setCommission,
    setCommissionType,
  } = useVendor();

  const { mutate, isLoading } = useMutation(createVendor, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendors",
        setterList: [
          () => setIsCreateModal(false),
          () => setIsClientExest(false),
          () => setIsNewClient(false),
          () => setHasPreOrder(false),
          () => setIsModalFilter(true),
          () => setLat(""),
          () => setLon(""),
          () => setBannerFile(""),
          () => setLogoFile(""),
          () => setOwnerName(null),
          () => setProvinceID(null),
          () => setCityID(null),
          () => setZoneID(null),
          () => setStatusCodeID(null),
          () => setDeliveryRangeDistance(null),
          () => setPhone(null),
          () => setMgmtPhone(null),
          () => setDescription(null),
          () => setBusinessCategoryIDS([]),
          () => setAddress(null),
          () => setArName(null),
          () => setRank(null),
          () => setdDelieveryfeeid(null),
          () => setDeliveryDistanceID(null),
          () => setMaxamount(null),
          () => setServiceFeeID(null),
          () => setMinamount(null),
          () => setCommission(null),
          () => setCommissionType(null),
          () => setStep(0),
        ],
      });
      
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleSave = async () => {
    let formData = {
      arName,
      enName: arName,
      kuName: arName,
      rank,
      provinceID,
      cityID,
      zoneID,
      address,
      ownerName,
      businessCategoryIDS: JSON.stringify(businessCategoryIDS),
      phone,
      mgmtPhone,
      logoFile,
      bannerFile,
      deliveryRangeDistance: deliveryRangeDistance / 1000,
      statusCodeID,
      description,
      active: true,
      lat,
      lon,
      commission,
      commissionType,
      delieveryFeeID,
      deliveryDistanceID,
      maxamount,
      serviceFeeID,
      minamount,
      hasPreOrder,
      workingSchedule: JSON.stringify(listSchedule),
    };

    mutate(formData);
  };

  const bannerProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setBannerFile(file);
      return false;
    },
  };
  const logoProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setLogoFile(file);
      return false;
    },
  };

  return (
    <>
      <br />
      <Dragger {...logoProps}>
        <p className="ant-upload-drag-icon">
          <SmileOutlined />
        </p>
        <p className="ant-upload-text">Brand</p>
        <p className="ant-upload-hint">
          Click or drag Image to this area to upload
        </p>
      </Dragger>
      <br />
      <br />
      <Dragger {...bannerProps}>
        <p className="ant-upload-drag-icon">
          <PictureOutlined />
        </p>
        <p className="ant-upload-text">Cover Image</p>
        <p className="ant-upload-hint">
          Click or drag Image to this area to upload
        </p>
      </Dragger>
      <div
        className="resturant-constroll-form"
        style={{ paddingRight: 10, paddingLeft: 10 }}
      >
        <Divider />
        <Space>
          <Button onClick={() => setStep(step - 1)}>Back</Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSave}
          >
            Finish
          </Button>
        </Space>
      </div>
    </>
  );
};
