import { useState, useEffect , useMemo } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Badge,
  Pagination,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import authFunc from "../../helper/auth";

import { getStatusCode, removeStatusCode, pageSize } from "../../api";
import { useStatusCode } from "./store";
import { useSearchParams } from "react-router-dom";
import handleDataMutation from "../../helper/dataMutation";
const { Text } = Typography;

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setDescription,
    setId,
    setIsCreateModal,
    setName,
    queryKey,
    setColor,
  } = useStatusCode();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setDescription(row.description);
    setColor(row.color);
    setIsCreateModal(true);
  };

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (color) => <Badge color={color} text={color} />,
    },

    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("STATUS_CODE_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("STATUS_CODE_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              disabled={row.mandatory == "true" ? true : false}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={row.mandatory == "true" ? true : false}
                size="small"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate } = useMutation(removeStatusCode, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "statusCode",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["statusCode", queryKey, page], getStatusCode, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
