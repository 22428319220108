import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  message,
  Select,
  Card,
  Space,
  Input,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import {
  getBannersForSlider,
  bannersToBanner,
  getAppBannerEdit,
} from "../../api";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

export const AddBanners = () => {
  const {
    id,
    setIsAddBanners,
    isAddBanners,
    banners,
    setType,
    setPolygon,
    setPriorty,
    setId,
    setName,
    setImg,
    setEndDate,
    setStartDate,
    setBanners,
  } = useAppBanner();
  const [list, setList] = useState([]);
  const [searchlist, setSearchList] = useState([]);
  const [filter, setFilter] = useState("");
  const [bannerSearch, setSearch] = useState("");

  const handleSearch = (val) => {
    setSearch(val);
    if (val !== "") {
      let bannerSearch = list.filter((el) => el.name.includes(val));
      setSearchList(
        bannerSearch.length !== 0 ? bannerSearch : [{ searchRes: false }]
      );
    } else {
      setSearchList([]);
    }
  };

 

  const SortableItem = SortableElement(({ value }) => (
    <Card
      bordered={false}
      bodyStyle={{ padding: "10px 16px", backgroundColor: "#f6f6f6" }}
      style={{ marginBottom: 10 }}
    >
      <div className="app-space-between">
        <Space size={16}>
          <img
            style={{ borderRadius: "8px", width: 100, height: 70 }}
            src={value.img}
          />
          <Space direction="vertical" size={0}>
            <Text>{value.name}</Text>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {value.priority}
            </Text>
          </Space>
        </Space>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setList(list.filter((el) => el.id != value.id));
            if (bannerSearch) {
              setSearchList(
                searchlist.filter((el) => el.id != value.id).length !== 0
                  ? searchlist.filter((el) => el.id != value.id)
                  : [{ searchRes: false }]
              );
            }
          }}
          icon={<DeleteOutlined />}
          size="small"
          type="text"
          danger
        />
      </div>
    </Card>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) =>
          value.searchRes !== false ? (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ) : null
        )}
      </div>
    );
  });

  const { isLoading: resturantLoading, data: bannersData } = useQuery(
    ["banners_for_slider", filter],
    getBannersForSlider,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    bannersToBanner,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "appBanner",
          setterList: [() => setIsAddBanners(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { isLoading: isEditData, data: editData } = useQuery(
    [id],
    getAppBannerEdit,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
  

  useEffect(() => {
    setBanners(editData?.data?.banner);
  }, [editData?.data?.banner, id]);

  useEffect(() => {   
    if (id) {
      setList(
        editData?.data?.banner
          ?  editData?.data?.banner?.sort((a, b) => (b.priority > a.priority ? 1 : -1))
          : []
      );
    }
  }, [id , editData?.data?.banner]);


  let onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMoveImmutable(list, oldIndex, newIndex));
  };

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  const handleSave = () => {
    let dataForm = {
      banners: JSON.stringify(list.map((el) => el.id)),
      id,
    };
    editMutate(dataForm);
  };

  const resetState = () => {
    setId(null);
    setImg(null);
    setType(null);
    setStartDate(null);
    setEndDate(null);
    setName(null);
    setPolygon(null);
    setPriorty(null);
    setSearch("");
    setList([]);
    setSearchList([]);
    setBanners([]);
  };

  return (
    <Modal
      title={<Title level={5}>Banners to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddBanners}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddBanners(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Search
            onSearch={(val) => handleSearch(val)}
            placeholder="Search to add more to this banner..."
          />
        </Col>
        <Col span={24}>
          <Select
            showSearch
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            onChange={(val) => {
              if (val) {
                setList([
                  ...list,
                  bannersData?.data?.records?.find((el) => el.id === val),
                ]);
              }
            }}
            style={{ width: "100%" }}
            placeholder="Find banners to add on this banner"
            allowClear
          >
            {!resturantLoading ? (
              bannersData.success ? (
                bannersData?.data?.records?.map((el) => (
                  <Option
                    key={el.id}
                    id={el.id}
                    value={el.id}
                    disabled={isRepeated(el.id)}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        <Col span={24}>
          <div style={{ minHeight: 400, maxHeight: "70vh", overflow: "auto" }}>
            <SortableList
              distance={1}
              items={searchlist.length > 0 ? searchlist : list}
              onSortEnd={onSortEnd}
            />
          </div>
        </Col>
        <Col span={18}>
          <Button
            loading={editLoading}
            type="primary"
            onClick={handleSave}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
