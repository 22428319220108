import create from "zustand";

export const useZone = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,
  area: null,
  areaSelect: null,
  createMapCenter: null,
  queryKey: "",
  
  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setAreaSelect: (areaSelect) => set({ areaSelect }),
  setName: (name) => set({ name }),
  setArea: (area) => set({ area }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setCreateMapCenter: (createMapCenter) => set({ createMapCenter }),
}));
