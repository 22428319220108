import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useAppBanner } from "./store";
import PolygonToCenter from "../../constants/PolygonToCenter";
import { getVendorsOnMap } from "../../api";
import { useQuery } from "react-query";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Polygon,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "react-leaflet-draw";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";

let vendorMarkerIcon = L.icon({
  iconUrl: markerIconPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

export const PureMapDrow = () => {
  const {
    setPolygon,
    polygon,
    isCreateModal,
    markerPosition,
    setMarkerPosition,
  } = useAppBanner();
  const [isRender, setIsRender] = useState(false);

  const { isLoading: loading, data } = useQuery(
    [
      "getVendorsOnMap",
      markerPosition && markerPosition[0],
      markerPosition && markerPosition[1],
    ],
    getVendorsOnMap,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: markerPosition !== null,
    }
  );

  let cords = JSON.parse(polygon)?.coordinates[0];
  const reverseCords = (cords) => {
    return cords.map((item) => {
      return [item[1], item[0]];
    });
  };

  const MapComponent = () => {
    const map = useMap();

    useEffect(() => {
      setTimeout(function () {
        map.invalidateSize();
      }, 100);
    }, [isCreateModal, polygon]);

    map.on("dblclick", (e) => {
      map.panTo(new L.latLng(e.latlng.lat, e.latlng.lng));
      setMarkerPosition([e.latlng.lat, e.latlng.lng]);
    });

    if (polygon && !data) {
      map.setView(
        [
          PolygonToCenter(JSON.parse(polygon))[1],
          PolygonToCenter(JSON.parse(polygon))[0],
        ],
        12
      );
    }

    return null;
  };

  const ToolsButtons = () => {
    const map = useMap();

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    const drawControlFull = new L.Control.Draw({
      position: "topright",
      edit: {
        featureGroup: drawnItems,
      },
      draw: {
        rectangle: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        marker: false,
        polygon: true,
      },
    });

    const drawControlEditOnly = new L.Control.Draw({
      position: "topright",
      edit: {
        featureGroup: drawnItems,
      },
      draw: false,
    });

    useEffect(() => {
      if (!isCreateModal && isRender) {
        map.removeControl(drawControlFull);
        map.eachLayer((layer) => {
          if (layer instanceof L.Path) {
            map.removeLayer(layer);
          }
        });
      }
    }, [isCreateModal]);

    if (!isRender && isCreateModal) {
      map.addControl(drawControlFull);
      setIsRender(true);
    }

    map.on(L.Draw.Event.CREATED, (e) => {
      if (!isRender && isCreateModal) {
        setPolygon(
          JSON.stringify({
            coordinates: e.layer.toGeoJSON().geometry.coordinates,
            type: e.layer.toGeoJSON().geometry.type,
          })
        );
        map.eachLayer((layer) => {
          if (layer instanceof L.Path) {
            map.removeLayer(layer);
          }
        });
        drawnItems.addLayer(e.layer);
      }
    });
    map.on(L.Draw.Event.EDITED, (e) => {
      if (
        !isRender &&
        isCreateModal &&
        e.layers.toGeoJSON().features.length > 0
      ) {
        setPolygon(
          JSON.stringify({
            coordinates: e.layers.toGeoJSON().features[0].geometry.coordinates,
            type: "polygon",
          })
        );
      }
    });

    map.on(L.Draw.Event.DELETED, () => {
      if (!isRender && isCreateModal) {
        setPolygon(null);
      }
    });

    return null;
  };

  return (
    <div>
      <Spin spinning={loading}>
        <MapContainer
          center={[33.3152, 44.3661]}
          zoomControl={false}
          zoom={10}
          scrollWheelZoom={true}
          attributionControl={false}
          style={{ height: "500px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
          />
          {markerPosition && (
            <Marker position={markerPosition} icon={vendorMarkerIcon} />
          )}
          {cords && <Polygon positions={reverseCords(cords)} />}
          {data &&
            data?.data &&
            data.success &&
            data?.data?.map((el, index) => (
              <Marker
                key={index}
                position={[el.lat, el.lon]}
                icon={L.icon({
                  className: "marker",
                  iconUrl: el.logoImg || markerIconPng,
                  iconSize: [40, 40],
                })}
              >
                <Popup>{el.arName}</Popup>
              </Marker>
            ))}
          <ToolsButtons />
          <MapComponent />
        </MapContainer>
      </Spin>
    </div>
  );
};
