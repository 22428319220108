import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin,
  Tag,
  Space,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDiscount } from "../../../store";
import { useMutation, useQuery } from "react-query";

import handleDataMutation from "../../../helper/dataMutation";
import { AddToVoucher, getVoucher, getVendors } from "../../../api";
import parseName from "../../../helper/parseName";
const { Text } = Typography;
const { Option } = Select;

export const PureGetVendors = () => {
  const { setIsCitiesModal, id } = useDiscount();
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState(null);
  const [list, setList] = useState([]);

  const {
    isLoading: clientLoading,

    data: clients,
    refetch,
  } = useQuery(["vendorsSearch", filter], getVendors, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: getVoucherLoading,

    refetch: VoucherRefetch,
  } = useQuery("voucherOne", () => getVoucher(id), {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (id) {
      refetch();
      VoucherRefetch();
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      VoucherRefetch();
    }
  }, []);

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  useEffect(() => {
    if (selected && clients.success) {
      setList([...list, clients.data.records.find((el) => el.id == selected)]);
      setSelected(null);
    }
  }, [selected]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToVoucher("vendor", values),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["vouchers", "vendorsVoucher"],
          setterList: [() => setIsCitiesModal(false), () => setList([])],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = () => {
    let dataForm = {
      id,
      vendorID: list.map((el) => el.id),
    };

    editMutate(dataForm);
  };

  return (
    <div style={{ width: 400 }}>
      <Row gutter={[0, 10]}>
        <Col flex={"100%"}>
          <Select
            showSearch
            value={selected}
            onChange={(val) => setSelected(val)}
            filterOption={false}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            style={{ width: "100%" }}
            placeholder="Find Vendor"
            allowClear
          >
            {!clientLoading ? (
              clients?.success ? (
                clients?.data?.records?.map((el) => (
                  <Option
                    disabled={isRepeated(el.id)}
                    key={el.id}
                    id={el.id}
                    value={el.id}
                  >
                    <Text> {el.arName}</Text>
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <Spin spinning={getVoucherLoading}>
            <Space size={10} wrap>
              {list.length > 0
                ? list.map((el) => (
                    <Tag
                      key={el.id}
                      closable
                      onClose={() => {
                        setList(list.filter((item) => item.id != el.id));
                      }}
                      color="purple"
                    >
                      <Text> {el.arName}</Text>
                    </Tag>
                  ))
                : null}
            </Space>
          </Spin>
        </Col>
        <Col span={24}>
          <Divider />
          <Button
            loading={editLoading}
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            onClick={onFinish}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};
