import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  DatePicker,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDiscountCap } from "./store";
import { useMutation, useQuery } from "react-query";

import { createDiscountCap, getVendors, editDiscountCap } from "../../api";
import { CustomeCalender } from "../../comonents/global";
import dayjs from "dayjs";
import handleDataMutation from "../../helper/dataMutation";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";

const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    valueType,
    maxamount,
    minamount,
    totalQty,
    startDate,
    endDate,
    id,
    setEndDate,
    setStartDate,
    setDayOfWeek,
    setMinAmount,
    setId,
    setMaxAmount,
    schedule,
    setSchedule,
    totalValue,
    setTotalValue,
    status,
    vendorID,
    vendor,
    setStatus,
    setVendorID,
    setVendor,
    setVendorValue,
    setBalyValue,
    vendorValue,
    balyValue,
    discountType,
  } = useDiscountCap();

  const [form] = Form.useForm();
  const [type, setType] = useState(null);
  const [filter, setFilter] = useState("");
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [vendorList, setvendorList] = useState([]);

  const resetState = async () => {
    setEndDate(null);
    setStartDate(null);
    setDayOfWeek(null);
    setMinAmount(null);
    setId(null);
    setMaxAmount(null);
    setSchedule(null);
    setTotalValue(null);
    setMaxValue(null);
    setMinValue(null);
    setStatus(null);
    setVendorID(null);
    setVendor(null);
    setBalyValue(null);
    setVendorValue(null);
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        vendorID,
        vendor,
        maxamount,
        minamount,
        totalQty,
        startDate,
        endDate,
        status,
        totalValue,
        vendorValue,
        balyValue,
        discountType,
        schedule,
      });
      setType(valueType);
    } else {
      form.resetFields();
    }
  }, [id]);

  const { mutate, isLoading } = useMutation(createDiscountCap, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "discountCap",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editDiscountCap,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "discountCap",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.schedule = JSON.stringify(schedule);
    values.startDate = dayjs(values.startDate).toDate();
    values.endDate = dayjs(values.endDate).toDate();
    values.vendorIDs = "[]";
    values.discountType = "PERCENTAGE";
    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  const { isLoading: vendorLoading, data: vendors } = useQuery(
    ["vendors", filter],
    getVendors,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  useEffect(() => {
    if (vendors && vendors?.success) {
      let cuurentList = vendors?.data?.records.find((el) => el.id === vendorID);
      if (cuurentList) {
        setvendorList(vendors?.data?.records);
      } else {
        setvendorList([
          ...vendors.data.records,
          { id: vendorID, arName: vendor },
        ]);
      }
    }
  }, [vendors, vendorID]);

  const handleMinMaxValue = () => {
    if (form.getFieldValue("maxamount") > form.getFieldValue("minamount")) {
      form.setFields([
        {
          name: "minamount",
          errors: [],
        },
        {
          name: "maxamount",
          errors: [],
        },
      ]);
    }
  };

  const getVendorValue = () => {
    form.setFieldsValue({
      vendorValue:
        form.getFieldValue("totalValue") - form.getFieldValue("balyValue"),
    });
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Discount Cap</Title>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
        setType(null);
      }}
      footer={false}
      maskClosable={false}
      width={980}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[20, 20]}>
              <Col span={20}>
                <Form.Item
                  label="Vendor"
                  name="vendorID"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    loading={vendorLoading}
                    optionFilterProp="children"
                    onSearch={(val) => setFilter(val)}
                    style={{ width: "100%" }}
                    placeholder="Find Vendors"
                    value={vendorID}
                    allowClear
                  >
                    {vendorList.map((el) => (
                      <Option key={el.id} id={el.id} value={el.id}>
                        {el.arName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Total Value"
                  name="totalValue"
                  rules={[
                    { required: true, message: "required" },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "must be percentage",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                    parser={(value) => value.replace("%", "")}
                    onChange={getVendorValue}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Baly Value"
                  name="balyValue"
                  rules={[
                    { required: true, message: "required" },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "must be percentage",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                    parser={(value) => value.replace("%", "")}
                    onChange={getVendorValue}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Vendor Value"
                  name="vendorValue"
                  rules={[
                    { required: true, message: "required" },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "must be percentage",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                    readOnly={true}
                    parser={(value) => value.replace("%", "")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={7}>
                <Form.Item
                  label="Min Value ( IQD )"
                  name="minamount"
                  rules={
                    form.getFieldValue("maxamount")
                      ? [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max:
                              form.getFieldValue("maxamount") - 1 ||
                              maxamount - 1,
                            message: "must be less than max value",
                          },
                        ]
                      : [{ required: true, message: "required" }]
                  }
                >
                  <InputNumber
                    onChange={(value) => {
                      setMinValue(value);
                      handleMinMaxValue();
                    }}
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    max={100000}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Max Value ( IQD )"
                  name="maxamount"
                  rules={
                    form.getFieldValue("minamount")
                      ? [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min:
                              form.getFieldValue("minamount") + 1 ||
                              minamount + 1,
                            max: 500000,
                            message: "must be more than min value",
                          },
                        ]
                      : [{ required: true, message: "required" }]
                  }
                >
                  <InputNumber
                    onChange={(value) => {
                      setMaxValue(value);
                      handleMinMaxValue();
                    }}
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    max={100000}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
                <Form.Item>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={11}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Schedule"
                  name="schedule"
                  rules={[{ required: true, message: "schedule is required" }]}
                >
                  <CustomeCalender
                    value={id ? schedule : null}
                    onChange={(val) => setSchedule(val)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
