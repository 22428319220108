import { Table } from "antd";

import { getDeclineReason } from "../../api";
import { useQuery } from "react-query";
import { useMemo } from "react";

export const KeysTable = () => {
  const { isLoading, data } = useQuery(
    ["declineReasons", "", 1, 100],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const columns = useMemo(
    () => [ 
    {
      title: "Key",
      dataIndex: "arTitle",
    },
    {
      title: "Description",
      dataIndex: "arTitle",
    },
  ],[])

  return (
    <Table
      size={"small"}
      loading={isLoading}
      columns={columns}
      dataSource={data ? data?.data?.records : []}
      bordered
      pagination={false}
      scroll={{
        y: 430,
      }}
    />
  );
};
