import create from "zustand";

export const useTokens = create((set) => ({
    isCreateModal: false,
    serviceName: null,
    roleID: 1,
    authGroupID: 1,
    status: true,
    vendorID: null,
    id: null,
    queryKey: "",
    isReset: false,
    serviceType: "",
    roles : [],

    setRoles: (roles) => set({ roles }),
    setServiceType: (serviceType) => set({ serviceType }),
    setQueryKey: (queryKey) => set({ queryKey }),
    setIsReset: (isReset) => set({ isReset }),
    setId: (id) => set({ id }),
    setServiceName: (serviceName) => set({ serviceName }),
    setRoleID: (roleID) => set({ roleID }),
    setStatus: (status) => set({ status }),
    setVendorID: (vendorID) => set({ vendorID }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
