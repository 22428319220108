import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  InputNumber,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useReferralSettings } from "./store";
import { useMutation, useQuery } from "react-query";

import {
  createReferralSettings,
  editReferralSettings,
  getVoucherSettings,
} from "../../api";
import { KeysTable } from "./keysTable";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,

    id,
    name,
    referredVoucherID,
    referrerVoucherID,
    inviteCount,
    msg,
    config,
    deepLink,
    maxUse,

    setName,
    setId,
    setMsg,
    setReferrerVoucherID,
    setReferredVoucherID,
    setInviteCount,
    setDeepLink,
    setConfig,
    setMaxUse,
  } = useReferralSettings();

  const resetState = () => {
    setName(null);
    setId(null);
    setMsg(null);
    setReferrerVoucherID(null);
    setReferredVoucherID(null);
    setInviteCount(null);
    setDeepLink(null);
    setConfig(null);
    setMaxUse(null);
  };

  const { mutate, isLoading } = useMutation(createReferralSettings, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "referralSettings",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editReferralSettings,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "referralSettings",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { data: Voucherdata } = useQuery(
    ["voucherSettings", "", "REFERRAL", 500],
    getVoucherSettings,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const onFinish = (values) => {
    let _data = {
      inviteCount: values.inviteCount,
      maxUse: values.maxUse,
      name: values.name,
      referredVoucherID: values.referredVoucherID,
      referrerVoucherID: values.referrerVoucherID,
      msg: JSON.stringify({
        content: values.content,
        sendBy: values.sendBy,
      }),
      config: JSON.stringify({
        campaignURL: values.campaignURL,
        apiKey: values.apiKey,
      }),
      deepLink: values.deepLink,
    };
    if (id) {
      _data.id = id;
      editMutate(_data);
    } else {
      mutate(_data);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Referral Settings</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={980}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          name,
          maxUse,
          referredVoucherID,
          referrerVoucherID,
          inviteCount,
          content: msg ? JSON.parse(msg).content : null,
          sendBy: msg ? JSON.parse(msg).sendBy : [],
          deepLink,
          campaignURL: config ? JSON.parse(config).campaignURL : null,
          apiKey: config ? JSON.parse(config).apiKey : null,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={13}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="name" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Invite Count"
                  name="inviteCount"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: "100%" }} placeholder="Number" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Max Use"
                  name="maxUse"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Max Use"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Referred Voucher"
                  name="referredVoucherID"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {Voucherdata
                      ? Voucherdata?.data?.records?.map((el) => (
                          <Option key={el.id} value={el.id}>
                            {el.voucherTitle}
                          </Option>
                        ))
                      : []}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Referrer Voucher"
                  name="referrerVoucherID"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {Voucherdata
                      ? Voucherdata?.data?.records?.map((el) => (
                          <Option key={el.id} value={el.id}>
                            {el.voucherTitle}
                          </Option>
                        ))
                      : []}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Content"
                  name="content"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Send By"
                  name="sendBy"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    mode="multiple"
                    defaultValue={null}
                    placeholder="Message Type"
                  >
                    <Option value={"Email"}>Email</Option>
                    <Option value={"SMS"}>SMS</Option>
                    <Option value={"Notification"}>Notification</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item label="Campaign URL" name="campaignURL">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="API Key" name="apiKey">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Deep Link"
                  name="deepLink"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
                <Form.Item>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={10}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <KeysTable />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
