import React from "react";
import { InvoiceInfo } from "../invoice";
export const GetProducts = () => {
  return (
    <div
      className="step-container order-product"
      style={{ maxHeight: 510, overflowX: "clip", overflowY: "auto" }}
    >
      <InvoiceInfo />
    </div>
  );
};
