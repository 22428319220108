import { Card, Space, Button, Breadcrumb } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { PureGrid } from "./grid";

export const MediaPage = () => {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>

        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Media</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space></Space>
          <Space>
            <Button type="primary" icon={<UploadOutlined />}>
              Upload Image
            </Button>
          </Space>
        </div>
        <br />
        <PureGrid />
      </Card>
    </div>
  );
};
