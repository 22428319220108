import { useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  message,
  Space,
  Input,
  Popover,
} from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useBulkVoucher } from "../../../store";
import { useMutation } from "react-query";
import { queryClient } from "../../../queryClient";
import { AddToVoucher } from "../../../api";
import { openFile } from "../../../helper/openFile";
import { VendorTable } from "./vendorTable";
import { PureGetVendors } from "./getVendrs";
import handleDataMutation from "../../../helper/dataMutation";
const { Title, Text } = Typography;
const { Search } = Input;

export const PureAddVendores = () => {
  const { isVendorsModal, setIsVendorsModal, id, setId } = useBulkVoucher();
  const [filter, setFilter] = useState("");

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToVoucher("vendor", values),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["vouchers", "clientsVoucher"],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  return (
    <Modal
      title={<Title level={5}>Add Resturant To Voucher</Title>}
      className="app-modal"
      centered
      visible={isVendorsModal}
      onCancel={() => {
        setId(null);
        setIsVendorsModal(false);
        queryClient.resetQueries("voucherOne");
      }}
      footer={false}
      maskClosable={false}
      destroyOnClose={true}
      width={"80%"}
    >
      <Row gutter={[10, 10]}>
        <Col flex={"100%"}>
          <div className="app-space-between">
            <Space>
              <Search
                onSearch={(val) => setFilter(val)}
                style={{ width: 340 }}
                placeholder="Write To Search..."
              />
            </Space>
            <Space>
              <Popover placement="rightTop" content={<PureGetVendors />}>
                <Button type="primary" icon={<SearchOutlined />}>
                  Get Vondors
                </Button>
              </Popover>
              <Text>OR</Text>
              <Button
                onClick={() => openFile(editMutate, id)}
                type="primary"
                icon={<DownloadOutlined />}
                loading={editLoading}
              >
                Import excel file
              </Button>
            </Space>
          </div>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <VendorTable id={id} filter={filter} />
        </Col>
      </Row>
    </Modal>
  );
};
