import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  message,
  Space,
  Input,
  Popover,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { AddToBanners } from "../../api";
import { useState, useEffect } from "react";
import { ClientTable } from "./clientTable";
import { PureGetUsers } from "./getUsers";
import { openFile } from "../../helper/openFile";
import { SearchOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Search } = Input;

export const AddUsers = () => {
  const { id, setIsAddUsers, isAddUsers, setId, setUsers, users } =
    useAppBanner();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (id && isAddUsers) {
    }
  }, [id]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToBanners("user", values),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["appBanner", "clientsAppbanner"],
          setterList: [() => setUsers([])],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const resetState = () => {
    setId(null);
    setUsers([]);
  };

  useEffect(() => {
    return () => {
      setFilter("");
    };
  }, []);

  return (
    <Modal
      title={<Title level={5}>Users to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddUsers}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddUsers(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={900}
    >
      <Row gutter={[10, 10]}>
        <Col flex={"100%"}>
          <div className="app-space-between">
            <Space>
              <Search
                onSearch={(val) => setFilter(val)}
                style={{ width: 340 }}
                placeholder="Write To Search..."
              />
            </Space>
            <Space>
              <Popover placement="rightTop" content={<PureGetUsers />}>
                <Button type="primary" icon={<SearchOutlined />}>
                  Get Users
                </Button>
              </Popover>
              <Text>OR</Text>
              <Button
                onClick={() => openFile(editMutate, id)}
                type="primary"
                icon={<DownloadOutlined />}
                loading={editLoading}
              >
                import
              </Button>
            </Space>
          </div>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <ClientTable id={id} filter={filter} />
        </Col>
      </Row>
    </Modal>
  );
};
