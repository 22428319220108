import { useEffect, useState, useMemo } from "react";
import {
  Table,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Pagination,
  Switch,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,
  WomanOutlined,
  ManOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { getClients, removeClient, editClient, pageSize } from "../../api";
import { useClient } from "./store";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const {
    setId,
    setUsername,
    setPhone,
    setStatus,
    setIsCreateModal,
    setEmail,
    queryKey,
    setIsReset,
    setFirstName,
    setLastName,
    setGender,
    setIsCreateBlockModal,
    setUserId,
    isSelectReason,
  } = useClient();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setGender(row.gender);
    setUsername(row.username);
    setPhone(row.phone);
    setStatus(row.status);
    setFirstName(row.firstName);
    setLastName(row.lastName);
    setEmail(row.email);
    setIsCreateModal(true);
  };

  const handleReset = (row) => {
    setId(row.id);
    setIsReset(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        key: "id",
        render: (row) => (
          <Space>
            {row.gender == "female" ? (
              <WomanOutlined style={{ color: "#eb2f96" }} />
            ) : (
              <ManOutlined style={{ color: "#2196f3" }} />
            )}
            <Text style={{ fontWeight: "bold" }}>
              {row.firstName} {row.lastName}
            </Text>
          </Space>
        ),
      },
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => (
          <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            disabled={authFunc("CLIENT_UPDATE") ? false : true}
            loading={editLoading}
            checked={row.status}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },

      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id, row) => (
          <Space size="small">
            {authFunc("CLIENT_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("CLIENT_UPDATE") && (
              <Tooltip placement="top" title="Reset Password">
                <Button
                  onClick={() => handleReset(row)}
                  size="small"
                  icon={<HistoryOutlined />}
                />
              </Tooltip>
            )}
            {authFunc("CLIENT_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { mutate } = useMutation((val) => removeClient(val), {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "clients",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["clients", queryKey, page], getClients, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editClient,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "clients",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      if (!row.status === false) {
        setUserId(row.id);
        setIsCreateBlockModal(true);
        if (isSelectReason) {
          row.status = !row.status;
          editMutate(row);
        }
      } else {
        row.status = !row.status;
        editMutate(row);
      }
    }
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
