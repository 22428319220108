import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Polygon,
} from "react-leaflet";
import { useZone } from "./store";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";

export const PureMap = () => {
  const { areaSelect } = useZone();
  const [CenterPostion, setCenterPostion] = useState([33.312805, 44.361488]);

  const reverseCords = (cords) => {
    return cords?.map((item) => {
      return item?.map((item) => {
        return [item[1], item[0]];
      });
    });
  };
  const MapComponent = () => {
    const map = useMap();
    useEffect(() => {
      if (CenterPostion) {
        map.setView(CenterPostion, map.getZoom());
      }
    }, [CenterPostion]);
    return null;
  };

  useEffect(() => {
    if (areaSelect !== null) {
      const reversed = reverseCords(areaSelect?.coordinates);

      const latSum = reversed?.[0]?.reduce((acc, coord) => acc + coord[0], 0);
      const lngSum = reversed?.[0]?.reduce((acc, coord) => acc + coord[1], 0);
      const latAvg = latSum / reversed?.[0]?.length;
      const lngAvg = lngSum / reversed?.[0]?.length;

      setCenterPostion([latAvg, lngAvg]);
    }
  }, [areaSelect]);

  return (
    <MapContainer
      center={CenterPostion}
      zoomControl={false}
      zoom={15}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "400px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      {areaSelect && (
        <Polygon
          positions={reverseCords(areaSelect?.coordinates)}
          color="blue"
          fillColor="blue"
        />
      )}
      {areaSelect && (
        <Marker
          position={CenterPostion}
          icon={
            new Icon({
              iconUrl: markerIconPng,
              iconSize: [25, 41],
              iconAnchor: [12, 41],
            })
          }
        />
      )}
      <MapComponent />
    </MapContainer>
  );
};
