import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { usePaymentType } from "./store";
import { useMutation } from "react-query";

import { createPaymentType, editPaymentType } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const PureCreate = () => {
  const { isCreateModal, setIsCreateModal, id, setId, name, setName } =
    usePaymentType();
  const resetstate = () => {
    setId(null);
    setName(null);
  };

  const { mutate, isLoading } = useMutation(createPaymentType, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "paymentTypes",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetstate,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editPaymentType,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "paymentTypes",
          setterList: [() => setIsCreateModal(false), () => setId(null)],
          resetFunc: resetstate,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Payment Type</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetstate();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          name,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Write name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
