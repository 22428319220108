import { useEffect, useMemo } from "react";
import {
  Table,
  Tag,
  Alert,
  Typography,
  Pagination,
  Popover,
  Button,
  Space,
} from "antd";
import { useQuery } from "react-query";
import { EditOutlined, BellOutlined } from "@ant-design/icons";
import { getDelayedOrders, pageSize } from "../../api";
import { useOrder } from "../orders/store";
import { useNavigate } from "react-router-dom";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const {
    queryKey,
    queryFilter,
    setPage,
    page,
    queryID,
    vendorID,
    cityID,
    clientID,
    status,
    voucherID,
    from,
    to,
  } = useOrder();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Customer",
        dataIndex: "client",
        key: "client",
        render: (client) => (
          <Text style={{ fontWeight: "bold" }}>{client ? client : "..."}</Text>
        ),
      },
      {
        title: "Address",
        dataIndex: "clientAddress",
        key: "clientAddress",
        width: 200,
        ellipsis: true,
        render: (clientAddress) => (
          <Text>{clientAddress ? clientAddress : "..."}</Text>
        ),
      },
      {
        title: "SubTotal",
        dataIndex: "subTotal",
        key: "subTotal",
        render: (subTotal) => Number(subTotal).toLocaleString(),
      },
      {
        title: "Delivery",
        dataIndex: "deliveryFee",
        key: "deliveryFee",
        render: (deliveryFee) =>
          deliveryFee > 0 ? "+" + Number(deliveryFee).toLocaleString() : 0,
      },

      {
        title: "Discount",
        dataIndex: "id",
        key: "id",
        render: (id, row) => {
          let total = row?.totalValue;
          let voucher = row?.discountTotalValue + row?.voucherTotalValue;
          let finalDiscount =
            total < voucher ? total - row?.deliveryFee : voucher;
          return (
            <i style={{ color: voucher ? "red" : "gray" }}>
              {finalDiscount > 0
                ? "-" + Number(finalDiscount).toLocaleString()
                : finalDiscount}
            </i>
          );
        },
      },

      {
        title: "TotalValue",
        dataIndex: "totalBeforeRound",
        key: "totalBeforeRound",
        render: (totalBeforeRound) => (
          <b>{Number(totalBeforeRound).toLocaleString()}</b>
        ),
      },
      {
        title: "Rounded Total Value",
        dataIndex: "totalValue",
        key: "totalValue",
        render: (totalValue) => <b>{Number(totalValue).toLocaleString()}</b>,
      },

      {
        title: "Vendor",
        dataIndex: "vendor",
        key: "vendor",
        render: (vendor) => (vendor ? vendor : "..."),
      },
      {
        title: "Creation Date",
        dataIndex: "CreatedAt",
        key: "CreatedAt",
        render: (CreatedAt) => (
          <Text type="secondary">
            {dayjs(CreatedAt).format("YYYY-MM-DD hh:mm A")}
          </Text>
        ),
      },

      {
        title: "Payment",
        dataIndex: "paymentType",
        key: "paymentType",
        render: (paymentType) => (
          <Tag color="magenta">{paymentType ? paymentType : "..."}</Tag>
        ),
      },

      {
        title: "Voucher",
        dataIndex: "voucherCode",
        key: "voucherCode",
        render: (voucherCode) => (
          <Text>{voucherCode ? voucherCode : "..."}</Text>
        ),
      },

      {
        title: "TrackNo.",
        dataIndex: "trackNo",
        key: "trackNo",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          let statusHistory = status ? JSON.parse(status) : [];
          let currentStatus = status ? JSON.parse(status).pop() : "";
          return (
            <Popover
              title="Status History"
              content={
                <div>
                  {statusHistory.map(
                    (el, index) =>
                      el.status && (
                        <p key={index}>
                          <b style={{ color: el.color }}>{el.status}</b>/
                          changed by
                          <b>
                            {el?.userType === "BIKER"
                              ? el?.biker_name
                              : el?.userName}
                          </b>
                          (<small>{el.userType}</small>) /
                          <Text type="secondary">
                            {dayjs(el.created_at).format("hh:mm a")}
                          </Text>
                        </p>
                      )
                  )}
                </div>
              }
            >
              <Space>
                <a style={{ color: currentStatus.color }}>
                  {currentStatus.arTitle}
                </a>
                {currentStatus.status === "SUSPEND" ? (
                  <BellOutlined className="rings-alert" />
                ) : null}
              </Space>
            </Popover>
          );
        },
      },

      {
        title: "PreOrder?",
        dataIndex: "preOrder",
        key: "preOrder",
        render: (val) =>
          val ? <Tag color={"geekblue"}>Pre-Order</Tag> : "...",
      },

      {
        title: "Action",
        dataIndex: "id",
        key: "id",

        render: (id) => (
          <Space>
            {authFunc("ORDER_UPDATE") && (
              <Button
                onClick={() => window.open(`/order/${id}`)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const { isLoading, error, data } = useQuery(
    [
      "orders",
      queryKey,
      page,
      "",
      vendorID,
      cityID,
      clientID,
      status,
      voucherID,
      from,
      to,
    ],
    getDelayedOrders,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      refetchInterval: 30000,
    }
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
