import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Input,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useClient } from "./store";
import { useMutation, useQuery } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { blockUser, getBlockReason } from "../../api";

const { Title } = Typography;
const { Option } = Select;

export const CreateBlockReason = () => {
  const {
    isCreateBlockModal,
    setIsCreateBlockModal,
    userId,
    setUserId,
    setIsSelectReason,
  } = useClient();

  const { data: blockReasonData } = useQuery(
    ["blockReason", "", 1],
    getBlockReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateBlockModal,
    }
  );

  const { mutate: blockUserMutate, isLoading: blockUserLoading } = useMutation(
    blockUser,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "clients",
          setterList: [() => setUserId(null), () => setIsSelectReason(false)],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (val) => {
    let dataForm = {
      ClientID: Number(userId),
      Note: val.note,
      reasonID: val.blockReasonID,
    };
    blockUserMutate(dataForm);
    setIsCreateBlockModal(false);
    setIsSelectReason(true);
  };

  return (
    <Modal
      title={<Title level={5}> Select Block Reason </Title>}
      className="app-modal"
      centered
      visible={isCreateBlockModal}
      onCancel={() => {
        setIsCreateBlockModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Block Reasons"
              name="blockReasonID"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select Cancel Reasons"
              >
                {blockReasonData?.data?.records.map((val) => (
                  <Option key={val.id} value={val.id}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Note" name="note" rules={[{ required: true }]}>
              <Input placeholder={"Write a note"} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={blockUserLoading}
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
              >
                Send
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
