import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  Checkbox,
  DatePicker,
  Input,
  message,
  Card,
  Tag,
  Space,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { SaveOutlined } from "@ant-design/icons";
import { useCompundVouchers } from "./store";
import { useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import { creatVoucher, editVoucher, getVoucher } from "../../api";
import { CustomeCalender } from "../../comonents/global/customeCalender";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import moment from "moment";
import {
  inputNumberFormatter,
  inputNumberParser,
} from "../../helper/inputNumberFormatter";

const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    code,
    voucherTitle,
    valueType,
    maxAmount,
    minAmount,
    priority,
    totalQty,
    startDate,
    endDate,
    platform,
    fisrtOrderOnly,
    newUsersOnly,
    UDID,
    applyOverDiscount,
    id,
    setEndDate,
    setStartDate,
    setVoucherTitle,
    setValueType,
    setUserIDs,
    setTotalQty,
    setPlatform,
    setDayOfWeek,
    setMinAmount,
    setId,
    setFisrtOrderOnly,
    setCode,
    setMaxAmount,
    setNewUsersOnly,
    setUDID,
    setApplyOverDiscount,
    schedule,
    setSchedule,
    totalValue,
    vendorValue,
    balyValue,
    setBalyValue,
    setTotalValue,
    setVendorValue,
    setAvToCustomers,
    avToCustomers,
    customerDesc,
    setCustomerDesc,
    stepsInfo,
    setStepsInfo,
  } = useCompundVouchers();
  const [form] = Form.useForm();
  const [type, setType] = useState(null);

  const resetState = async () => {
    setEndDate(null);
    setType(null);
    setStartDate(null);
    setVoucherTitle(null);
    setValueType(null);
    setUserIDs(null);
    setTotalQty(null);
    setPlatform(null);
    setDayOfWeek(null);
    setNewUsersOnly(null);
    setUDID(null);
    setApplyOverDiscount(null);
    setMinAmount(null);
    setId(null);
    setFisrtOrderOnly(null);
    setMaxAmount(null);
    setCode(null);
    setSchedule(null);
    setAvToCustomers(null);
    setCustomerDesc(null);
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(creatVoucher, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vouchers",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVoucher,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["vouchers", "bulkVouchers"],
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const getVendorValue = (index) => {
    const fields = form.getFieldsValue();
    const { voucherSteps } = fields;
    Object.assign(voucherSteps[index], {
      vendorValue:
        voucherSteps[index].totalValue - voucherSteps[index].balyValue,
    });

    form.setFieldsValue({ voucherSteps });
  };

  const onFinish = (values) => {
    values.voucherSteps.map((item, index) => {
      values.voucherSteps[index].priority = index;
    });
    values.noOfUsage = values.noOfUsage ? values.noOfUsage : 1;
    values.platform = JSON.stringify(values.platform);
    values.schedule = JSON.stringify(schedule);
    values.startDate = dayjs(values.startDate).toDate();
    values.endDate = dayjs(values.endDate).toDate();
    values.userIDs = "[]";
    values.cityIDs = "[]";
    values.BusinessCategoryIDs = "[]";
    values.vendorIDs = "[]";
    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values.voucherType = "COMPOUND";
    values.valueType = "PERCENTAGE";
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };
  const { isLoading: getVoucherLoading, data: voucherOne } = useQuery(
    "voucherOne",
    () => getVoucher(id),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!isCreateModal,
    }
  );

  useEffect(() => {
    if (id && voucherOne && voucherOne?.success) {
      const data = voucherOne?.success ? voucherOne?.data : [];

      form.setFieldsValue({
        code: data.code,
        noOfUsage: 1,
        voucherTitle: data.voucherTitle,
        totalQty: data.totalQty,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
        platform: data.platform ? JSON.parse(data.platform) : [],
        fisrtOrderOnly: data.fisrtOrderOnly,
        newUsersOnly: data.newUsersOnly,
        UDID: data.UDID,
        applyOverDiscount: data.applyOverDiscount,
        avToCustomers: data.avToCustomers,
        customerDesc: data.customerDesc,
        voucherSteps: data.voucherSteps,
        schedule: data.schedule
          ? JSON.parse(data.schedule)?.length > 0
            ? JSON.parse(data.schedule)
            : null
          : [],
      });
    } else {
      form.resetFields();
    }
  }, [id, voucherOne]);
  return (
    <Modal
      forceRender
      title={<p>{id ? "Edit" : "New"} Compound Voucher</p>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
        setType(null);
      }}
      footer={false}
      maskClosable={false}
      width={"80%"}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          voucherSteps: stepsInfo,
          startDate: startDate ? dayjs(startDate) : null,
          endDate: endDate ? dayjs(endDate) : null,
        }}
      >
        <Row
          gutter={[20, 20]}
          style={{
            height: "140px",
            maxHeight: "250px",
          }}
        >
          <Col span={24}>
            <Form.List name="voucherSteps">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    style={{
                      width: "100px",
                      marginLeft: "auto",
                      marginRight: "16px",
                    }}
                    type="dashed"
                    onClick={() => add()}
                    block
                    disabled={form.getFieldValue("voucherSteps")?.length >= 5}
                  >
                    Add Step
                  </Button>
                  <Space direction="vertical" />
                  <Row
                    style={{
                      maxHeight: "125px",
                      overflow: "auto",
                      overflowX: "hidden",
                      marginTop: "10px",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Form.Item
                        className="distanc-list"
                        required={true}
                        key={field.key}
                      >
                        <Row gutter={[12, 12]}>
                          <Col span={2}>
                            <Form.Item
                              label={"Priority"}
                              name={[field.name, "priority"]}
                              requiredMark={false}
                              width={"100%"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Tag color="blue">{index + 1}</Tag>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              label="VoucherType"
                              name={[field.name, "voucherType"]}
                              rules={[{ required: true, message: "required" }]}
                            >
                              <Select
                                placeholder="Voucher Type"
                                style={{
                                  maxHeight: "80px",
                                  overflow: "auto",
                                }}
                                defaultValue={
                                  stepsInfo[index]?.voucherType || "DISCOUNT"
                                }
                              >
                                <Option value={"DELIVERY"}>
                                  Free Delivery
                                </Option>
                                <Option value={"COMBINED"}>Combined</Option>
                                <Option value={"DISCOUNT"}>Discount</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              label="Min Basket Value"
                              name={[field.name, "minAmount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "required",
                                },
                              ]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  inputNumberFormatter(value)
                                }
                                parser={(value) => inputNumberParser(value)}
                                min={0}
                                style={{ width: "100%" }}
                                max={100000}
                                onChange={(val) => setMinAmount(val)}
                                defaultValue={stepsInfo[index]?.minAmount}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              label="Max Discount Value(IQD)"
                              name={[field.name, "maxAmount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "required",
                                },
                              ]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  inputNumberFormatter(value)
                                }
                                parser={(value) => inputNumberParser(value)}
                                style={{ width: "100%" }}
                                max={100000}
                                min={0}
                                onChange={(val) => setMaxAmount(val)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              label="Total Value"
                              name={[field.name, "totalValue"]}
                              rules={[
                                { required: true, message: "required" },
                                {
                                  type: "number",
                                  min: 0,
                                  max: 100,
                                  message: "must be percentage",
                                },
                              ]}
                              onChange={() => getVendorValue(index)}
                            >
                              <InputNumber
                                min={0}
                                max={100}
                                addonAfter="%"
                                parser={(value) => value.replace("%", "")}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={3}>
                            <Form.Item
                              label="Baly Value"
                              name={[field.name, "balyValue"]}
                              rules={[{ required: true, message: "required" }]}
                            >
                              <InputNumber
                                min={0}
                                max={100}
                                addonAfter="%"
                                parser={(value) => value.replace("%", "")}
                                onChange={() => getVendorValue(index)}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              label="Vendor Value"
                              name={[field.name, "vendorValue"]}
                              rules={[{ required: true, message: "required" }]}
                            >
                              <InputNumber
                                min={0}
                                max={100}
                                addonAfter="%"
                                parser={(value) => value.replace("%", "")}
                                style={{ width: "100%" }}
                                readOnly={true}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Form.Item label={"   "} requiredMark={false}>
                              <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => remove(field.name)}
                                disabled={fields.length === 2}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                  </Row>
                </div>
              )}
            </Form.List>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={16}>
            <Row gutter={[12, 12]}>
              <Col span={4}>
                <Form.Item
                  label="Title"
                  name="voucherTitle"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Input placeholder={"Voucher Title"} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Vouhcer Code"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Input placeholder={"Voucher Code"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Customer Description"
                  name="customerDesc"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Input placeholder={"Customer Description"} />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item
                  label="Quantity"
                  name="totalQty"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    formatter={(value) => inputNumberFormatter(value)}
                    parser={(value) => inputNumberParser(value)}
                    style={{ width: "100%" }}
                    min={1}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Platforms"
                  name="platform"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    mode="tags"
                    placeholder="Choose platform"
                    style={{
                      maxHeight: "80px",
                      overflow: "auto",
                    }}
                  >
                    <Option value={"PWA"}>PWA</Option>
                    <Option value={"WEB"}>WEB</Option>
                    <Option value={"IOS_SUPERAPP"}>IOS_SUPERAPP</Option>
                    <Option value={"ANDROID_SUPERAPP"}>ANDROID_SUPERAPP</Option>
                    <Option value={"IOS_SHELL"}>IOS_SHELL</Option>
                    <Option value={"ANDROID_SHELL"}>ANDROID_SHELL</Option>
                    <Option value={"IOS"}>IOS</Option>
                    <Option value={"ANDROID"}>ANDROID</Option>
                    <Option value={"WINDOWS10"}>WINDOWS10</Option>
                    <Option value={"DESKTOP_WEB"}>DESKTOP_WEB</Option>
                    <Option value={"MOBILE_WEB"}>MOBILE_WEB</Option>
                    <Option value={"WEB_IOS"}>WEB_IOS</Option>
                    <Option value={"WEB_MACOS"}>WEB_MACOS</Option>
                    <Option value={"WEB_WINDOWS"}>WEB_WINDOWS</Option>
                    <Option value={"SUPERAPP_ANDROID"}>SUPERAPP_ANDROID</Option>
                    <Option value={"SUPERAPP_IOS"}>SUPERAPP_IOS</Option>
                    <Option value={"WEB_ANDROID"}>WEB_ANDROID</Option>
                    <Option value={"SHELL_IOS"}>SHELL_IOS</Option>
                    <Option value={"SHELL_ANDROID"}>SHELL_ANDROID</Option>
                    <Option value={"OTHER"}>OTHER</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={5}>
                <Form.Item
                  label="No of Usage"
                  name="noOfUsage"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    min={1}
                  />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item name="fisrtOrderOnly" valuePropName="checked">
                  <Checkbox>First Order Only?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="newUsersOnly" valuePropName="checked">
                  <Checkbox>New Users Only?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="UDID" valuePropName="checked">
                  <Checkbox> Devices Only?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="applyOverDiscount" valuePropName="checked">
                  <Checkbox>Apply over discount ?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="avToCustomers" valuePropName="checked">
                  <Checkbox> Available to Customers ?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
                <Form.Item>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={6}>
            <Row gutter={[4, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Schedule"
                  name="schedule"
                  rules={[{ required: true, message: "schedule is required" }]}
                >
                  <CustomeCalender
                    value={id ? schedule : null}
                    onChange={(val) => {
                      setSchedule(val);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
