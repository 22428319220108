import { Card, Input, Button, Breadcrumb, Space, Alert } from "antd";
import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons";
import { PuerTable } from "./table";
import { PureCreate } from "./create";
import { HistoryModal } from "./historyModal";
import { FileModal } from "./fileModal";
import { useProduct } from "./store";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ProductFilters from "../../comonents/Filters/productFilters";

const { Search } = Input;

export const ProductsPage = () => {
  const { setIsCreateModal, setIsFileModal, setQueryKey, queryKey } =
    useProduct();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Vendors</Breadcrumb.Item>
        <Breadcrumb.Item>Product List</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Search..."
            />
            <ProductFilters />
          </Space>
          <Space>
            <Button
              onClick={() => setIsFileModal(true)}
              type="primary"
              icon={<FileExcelOutlined />}
            >
              Import From Excel
            </Button>
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          </Space>
        </div>
        <br />
        <PuerTable />
        <PureCreate />
        <HistoryModal />
        <FileModal />
      </Card>
    </div>
  );
};
