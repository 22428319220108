import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input } from "antd";
import { useOrder } from  "../store";

import { getClients } from "../../../api";
import { useQuery } from "react-query";

const { Option } = Select;

export const GetClient = () => {
  const [form] = Form.useForm();
  const [filter, setFilter] = useState("");
  const { client, setClient } = useOrder();

  const { isLoading, data, refetch } = useQuery(
    ["clients", filter],
    getClients,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  useEffect(() => {
    if (client) {
      form.setFieldsValue(client);
    }
  }, [client]);

  return (
    <div className="step-container">
      <Select
        showSearch
        style={{ width: "100%", marginTop: 10 }}
        placeholder="Find Client..."
        defaultActiveFirstOption={false}
        showArrow={true}
        loading={isLoading}
        filterOption={false}
        onSearch={(val) => setFilter(val)}
        onChange={(id) => {
          let _client = data?.data?.records.find((el) => el.id == id);
          setClient(_client);
        }}
        notFoundContent={null}
      >
        {data &&
          data.success &&
          data?.data?.records.map((el, index) => (
            <Option key={index} value={el.id}>
              {el.firstName + " " + el.lastName}
            </Option>
          ))}
      </Select>
      {client && (
        <Form
          requiredMark={"optional"}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          style={{ marginTop: 30 }}
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder="Murtadha" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="LastName"
                name="lastName"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder="M. Abed" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder={"baly001"} />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder={"07** *** ****"} />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
                <Input readOnly placeholder={"example@baly.iq"} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="ِAddress"
                name="address"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(val) => {
                    client.address = client.clientAddress.find(
                      (el) => el.id == val
                    );
                    setClient(client);
                  }}
                >
                  {client.clientAddress &&
                    client.clientAddress.map((el, index) => (
                      <Option key={index} value={el.id}>
                        {el.address}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};
