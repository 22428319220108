import create from "zustand";

export const useDeclineReason = create((set) => ({
  queryKey: "",
  id: null,
  isCreateModal: false,
  dataRow: null,
  arTitle: null,
  code: null,
  userType: null,
  floating:false,
  refund:null,
  commission:false,
  type:[],


  setId: (id) => set({ id }),
  setDataRow: (data) => set({ data }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setArTitle: (arTitle) => set({ arTitle }),
  setCode: (code) => set({ code }),
  setFloating: (floating) => set({ floating }),
  setUserType: (userType) => set({ userType }),
  setRefund: (refund) => set({ refund }),
  setType: (type) => set({ type }),
  setCommission: (commission) => set({ commission }),

}));
