import React from "react";
import { Select, Spin, Space, Checkbox, Tooltip } from "antd";
import { useVendor } from "../../pages/vendors/store";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getGovs, getRegions, getStatus, getCategories } from "../../api";
import "../../App.css";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function VendorFilters() {
  const {
    setQueryFilter,
    queryKey,
    setPage,
    govsSearch,
    setGovsSearch,
    citySearch,
    setCitySearch,
    statusSearch,
    setStatusSearch,
    categorySearch,
    setCategorySearch,
    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    active,
    setActive,
    statusCodeID,
    setStatusCodeID,
    businessCategoryIDS,
    setBusinessCategoryIDS,

    searchRating,
    setSearchRating,
    asc,
    setAsc,
  } = useVendor();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: catLoading,
    data: catList,
    refetch: catRefetch,
  } = useQuery(["category", categorySearch, 1, 100], getCategories, {
    enabled: !!categorySearch,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: isGovsLoading,
    data: govs,
    refetch: govsRefetch,
  } = useQuery(["govs", govsSearch, 1], getGovs, {
    enabled: !!govsSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isCityLoading,
    data: cities,
    refetch: citiesRefetch,
  } = useQuery(["regions", "", citySearch, 1], getRegions, {
    enabled: !!citySearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isStatusLoading,
    data: status,
    refetch: statusRefetch,
  } = useQuery(["status", "", statusSearch, 1], getStatus, {
    enabled: !!statusSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let obj = {
      cityID: Number(searchParams.get("cityID")) || null,
      statusCodeID: Number(searchParams.get("statusCodeID")) || null,
      businessCategoryIDS:
        Number(searchParams.get("businessCategoryIDS")) || null,
      searchRating: searchParams.get("searchRating") || null,
      asc: searchParams.get("asc") || null,
      provinceIdFilter: Number(searchParams.get("provinceID")) || null,
      active: searchParams.get("active") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setCityID(obj.cityID);
    setProvinceID(obj.provinceIdFilter);
    setStatusCodeID(obj.statusCodeID);
    setBusinessCategoryIDS(obj.businessCategoryIDS);
    setSearchRating(obj.searchRating);
    setAsc(obj.asc);
    setActive(obj.active);
    setPage(obj.page_num);
  }, [
    searchParams.get("cityID"),
    searchParams.get("statusCodeID"),
    searchParams.get("businessCategoryIDS"),
    searchParams.get("searchRating"),
    searchParams.get("asc"),
    searchParams.get("provinceID"),
    searchParams.get("active"),
    searchParams.get("page_num"),
  ]);

  return (
    <Space>
      <Select
        filterOption={false}
        value={provinceID}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isGovsLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val != undefined) {
            setProvinceID(val);
            setPage(1);
            searchParams.set("provinceID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setProvinceID(null);
          searchParams.delete("provinceID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setGovsSearch(val)}
        placeholder="Select Province"
        style={{ width: "100%" }}
        onClick={() => govsRefetch()}
      >
        {govs &&
          govs.success &&
          govs.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isCityLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={cityID}
        onChange={(val) => {
          if (val != undefined) {
            setCityID(val);
            setPage(1);
            searchParams.set("cityID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setCityID(null);
          searchParams.delete("cityID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCitySearch(val)}
        placeholder="Select City"
        style={{ width: "100%" }}
        onClick={() => citiesRefetch()}
      >
        {cities &&
          cities.success &&
          cities.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isStatusLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={statusCodeID}
        onChange={(val) => {
          if (val != undefined) {
            setStatusCodeID(val);
            setPage(1);
            searchParams.set("statusCodeID", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setStatusCodeID(null);
          searchParams.delete("statusCodeID");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setStatusSearch(val)}
        placeholder="Select Status"
        style={{ width: "100%" }}
        onClick={() => statusRefetch()}
      >
        {status &&
          status.success &&
          status.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          catLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={businessCategoryIDS}
        onChange={(val) => {
          if (val != undefined) {
            setBusinessCategoryIDS(val);
            setPage(1);
            searchParams.set("businessCategoryIDS", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setBusinessCategoryIDS(null);
          searchParams.delete("businessCategoryIDS");
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCategorySearch(val)}
        placeholder="Select Category"
        style={{ width: "100%" }}
        onClick={() => catRefetch()}
      >
        {catList &&
          catList.success &&
          catList?.data?.records?.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        allowClear
        value={active}
        onChange={(val) => {
          if (val != undefined) {
            setActive(val);
            setPage(1);
            searchParams.set("active", val);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setActive(null);
          searchParams.delete("active");
          setSearchParams(searchParams);
        }}
        placeholder="Select State"
        style={{ width: "100%" }}
      >
        <Option value={"true"}>Active</Option>
        <Option value={"false"}>InActive</Option>
      </Select>

      <Checkbox
        checked={searchRating}
        onChange={(e) => {
          if (e.target.checked === true) {
            setSearchRating(e.target.checked);
            searchParams.set("searchRating", e.target.checked);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          } else {
            searchParams.delete("searchRating");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
      >
        <Tooltip placement="topLeft" title={"From highest to lowest"}>
          Order By Rating
        </Tooltip>
      </Checkbox>

      <Checkbox
        checked={asc}
        onChange={(e) => {
          if (e.target.checked === true) {
            setAsc(e.target.checked);
            searchParams.set("asc", e.target.checked);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          } else {
            searchParams.delete("asc");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
      >
        <Tooltip placement="topLeft" title={"Asynchronous ordering"}>
          Order By asynchronous
        </Tooltip>
      </Checkbox>
    </Space>
  );
}
