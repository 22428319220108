import { ClockCircleOutlined } from "@ant-design/icons";
import { Typography, Space, Row, Col } from "antd";
import { useOrderView } from "./store";
import dayjs from "dayjs";

const { Text } = Typography;

export const MoreInfo = () => {
  const { order } = useOrderView();

  return (
    <>
      <Row gutter={[0, 6]}>
        <Col span={24}>
          <Text>Estimated delivery time</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <ClockCircleOutlined />
                  {order?.order?.estimatedDeliveryTime} Minute
                </Space>
              </Text>
            </section>
          </div>
        </Col>
        <Col span={24}>
          <Text>PreOrder Delivery Time</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <ClockCircleOutlined />
                  {order?.order?.preOrder
                    ? dayjs(order?.order?.preOrderDeliveryTime).format(
                        "YYYY-MM-DD hh:mm A"
                      )
                    : "No Delivery Time"}
                </Space>
              </Text>
            </section>
          </div>
        </Col>
      </Row>
    </>
  );
};
