import { Card, Input, Space, Button, Breadcrumb } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { PureTable } from "./table";
import { PureCreate } from "./create";
import { PureAddVendores } from "./options/addVendors";
import { PureAddCitents } from "./options/addCities";
import { PureAddCategories } from "./options/addCategories";
import { PureAddClients } from "./options/addClients";

import { useCompundVouchers } from "./store";
import CompoundVouchersFilter from "./compoundVoucherFilters";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;

export const CompoundVouchers = () => {
  const { setIsCreateModal, setQueryKey, queryKey } = useCompundVouchers();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Marketing</Breadcrumb.Item>
        <Breadcrumb.Item>Compound Vouchers</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Search..."
            />
            <CompoundVouchersFilter />
          </Space>

          {authFunc("VOUCHER_CREATE") && (
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />
        <PureTable />
        <PureCreate />
        <PureAddVendores />
        <PureAddCitents />
        <PureAddCategories />
        <PureAddClients />
      </Card>
    </div>
  );
};
