import { useState, useEffect , useMemo} from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Tag,
  Switch,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import authFunc from "../../helper/auth";
import {
  getCategory,
  removeCategory,
  pageSize,
  editCategoryStatus,
} from "../../api";
import { useSubCategory } from "./store";
import { useParams, useSearchParams } from "react-router-dom";
import { ImageTable } from "../../comonents/global";
import dayjs from "dayjs";
import handleDataMutation from "../../helper/dataMutation";

const { Text } = Typography;

export const PuerTable = () => {

  const [page, setPage] = useState(1);
  const { setId, setName, setIsCreateModal, queryKey, setPriority } =
    useSubCategory();

  const [searchParams, setSearchParams] = useSearchParams();
  const { categoryID } = useParams();
  const [statusLoadingID, setStatusLoadingID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setPriority(row.priority);
    setIsCreateModal(true);
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editCategoryStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "subCategory",
          setterList: [() => setStatusLoadingID(null)],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeStatus = (row) => {
    setStatusLoadingID(row.id);
    editMutate({
      id: row.id,
      status: !row.status,
    });
  };

  const columns = useMemo(
    () =>   [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      key: "id",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.image}
          id={row.id}
          pathName={"category/image"}
          imgKey={"img"}
          updateStore={"subCategory"}
        />
      ),
    },
    {
      title: "SubCategory Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Update Date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => <Tag color="red">{priority || "N/A"}</Tag>,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      key: "updated_by",
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          loading={editLoading && row.id === statusLoadingID}
          checked={row.status}
          onChange={() => handleChangeStatus(row)}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("CATEGORY_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("CATEGORY_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this category?"
              onConfirm={() => mutate(id)}
              disabled={row.mandatory == "true" ? true : false}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={row.mandatory == "true" ? true : false}
                size="small"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate } = useMutation(removeCategory, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "subCategory",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["subCategory", queryKey, page, 10, categoryID], getCategory, {
    refetchOnWindowFocus: false,
    retry: 2,
  });



  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.categories : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
