import { useEffect, useState, useMemo } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Pagination,
} from "antd";
import { DislikeFilled, LikeFilled } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import { getReviews, editReviews, pageSize } from "../../api";
import { useReviews } from "../productsReview/store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import handleDataMutation from "../../helper/dataMutation";

const { Text } = Typography;
const statusColor = {
  ACCEPTED: "success",
  REJECTED: "error",
  PENDING: "orange",
};

export const PureTable = () => {
  const { queryKey, rate } = useReviews();
  const [page, setPage] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["bikerReveiw", "bikerreview", queryKey, page, "", rate],
    getReviews,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate, isLoading: editLoading } = useMutation(
    (val) => editReviews("bikerreview", val),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "blockReason",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleEdit = (row, status) => {
    row.status = status;
    mutate(row);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Biker",
        dataIndex: "bikerID",
        key: "bikerID",
        render: (text, row) => (
          <Space direction="vertical">
            <Text>
              <b>ID:</b> {text}
            </Text>
            <Text>
              <b>Phone:</b> {row.phoneNo}
            </Text>
          </Space>
        ),
      },

      {
        title: "Order",
        dataIndex: "orderID",
        key: "orderID",
        render: (text, row) =>
          text ? (
            <Space direction="vertical">
              <Text>
                {" "}
                <b>Order ID:</b> {text}
              </Text>
              <Text>
                <b>Biker Order ID:</b> {row.bikerOrderID}
              </Text>
            </Space>
          ) : (
            <small>...</small>
          ),
      },
      {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
        render: (rate) => (
          <Tag color={rate === 1 ? "red" : "blue"}>
            {rate === 1 ? <DislikeFilled /> : <LikeFilled />}
          </Tag>
        ),
      },
      {
        title: "Customer",
        dataIndex: "client",
        key: "client",
        render: (text, row) =>
          text ? (
            <Space direction="vertical">
              <Text>
                <b>ID:</b> {row.clientID}
              </Text>
              <Text>
                {" "}
                <b>Phone:</b> {text}
              </Text>
            </Space>
          ) : (
            <small>No Client yet..</small>
          ),
      },
      {
        title: "Customer reviews",
        dataIndex: "comment",
        key: "comment",
        render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
      },
      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => (
          <Text type="secondary">{moment(date).format(" DD/ MM/ YYYY")}</Text>
        ),
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        render: (reason) => {
          let reasons = JSON.parse(reason);
          return (
            <Text style={{ fontSize: 12 }}>
              {reasons?.length > 0
                ? reasons?.map((el, index) => (
                    <Tag color="geekblue" key={index}>
                      {el?.title}
                    </Tag>
                  ))
                : "..."}
            </Text>
          );
        },
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (val) => <Tag color={statusColor[val]}>{val}</Tag>,
      },

      {
        title: "Action",
        key: "id",
        render: (row) =>
          row.status == "PENDING"
            ? authFunc("BIKER_REVIEW_UPDATE") && (
                <Space>
                  <Button
                    loading={editLoading}
                    onClick={() => handleEdit(row, "ACCEPTED")}
                    type="primary"
                    size="small"
                    color="red"
                  >
                    Accept
                  </Button>
                  <Button
                    loading={editLoading}
                    onClick={() => handleEdit(row, "REJECTED")}
                    danger
                    type="primary"
                    size="small"
                  >
                    Reject
                  </Button>
                </Space>
              )
            : row.status == "REJECTED"
            ? authFunc("BIKER_REVIEW_UPDATE") && (
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "ACCEPTED")}
                  type="primary"
                  size="small"
                >
                  Accept
                </Button>
              )
            : authFunc("BIKER_REVIEW_UPDATE") && (
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "REJECTED")}
                  danger
                  type="primary"
                  size="small"
                >
                  Reject
                </Button>
              ),
      },
    ],
    []
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record?.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
