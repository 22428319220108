import React from "react";
import { Select } from "antd";
import { useReviewReason } from "../../store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function ReviewReasonsFilters() {
  const { queryKey, setPage, category, setCategory } = useReviewReason();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj ={
      category: searchParams.get("category") || null,
    }
    setCategory(obj.category);
  }, [searchParams.get("category")]);

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setCategory(val);
              setPage(1);
              searchParams.set("category", val);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setCategory(null);
            searchParams.delete("category");
            setSearchParams(searchParams);
          }}
          placeholder="Select Category"
          style={{ width: "100%" }}
          value={category}
        >
          <Option value={"BIKER"}>BIKER</Option>
          <Option value={"VENDOR"}>VENDOR</Option>
          <Option value={"PRODUCT"}>PRODUCT</Option>
        </Select>
      </div>
    </div>
  );
}
