import { useState, useEffect , useMemo } from "react";
import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Tag,
  Switch,
  Popover,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

import {
  getVoucherSettings,
  removeVoucherSettings,
  updateVoucherSettingsStatus,
} from "../../api";

import { useVoucherSettings } from "./store";

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    queryKey,
    setId,
    setVoucherTitle,
    setMessage,
    setBalyValue,
    setVendorValue,
    setType,
    setValueType,

    setMaxAmount,
    setMinAmount,
    setNumberOfDays,
    setIsCreateModal,
    setApplyOverDiscount,
    setAvToCustomers,
    setCustomerDesc,
    setPlatform,
  } = useVoucherSettings();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setVoucherTitle(row.voucherTitle);
    setMessage(row.message);
    setBalyValue(row.balyValue);
    setVendorValue(row?.vendorValue);
    setType(row.type);
    setValueType(row.valueType);
    setMaxAmount(row.maxAmount);
    setMinAmount(row.minAmount);
    setNumberOfDays(row.numberOfDays);
    setIsCreateModal(true);
    setApplyOverDiscount(row.applyOverDiscount);
    setAvToCustomers(row.avToCustomers);
    setCustomerDesc(row.customerDesc);
    setPlatform(JSON.parse(row.platform));
  };

  const { mutate: editStatusMutate, isLoading: editStatusLoading } =
    useMutation(updateVoucherSettingsStatus, {
      onSuccess: (data) => {
        setSelectedID(null);
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "voucherSettings",
        });
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    });

  const editVocherSettingsstatus = (row) => {
    setSelectedID(row.id);
    let data = {
      id: row.id,
      type: row.type,
      status: !row.status,
    };
    editStatusMutate(data);
  };

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Voucher Title",
      dataIndex: "voucherTitle",
      key: "voucherTitle",
    },
    {
      title: "Customer Description",
      dataIndex: "customerDesc",
      key: "customerDesc",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <Tag>{type}</Tag>,
    },
    {
      title: "Baly Value",
      dataIndex: "balyValue",
      key: "balyValue",
      render: (balyValue, valueType) =>
        valueType.valueType === "PERCENTAGE"
          ? Number(balyValue || 0).toLocaleString() + "%"
          : Number(balyValue || 0).toLocaleString(),
    },
    {
      title: "Vendor Value",
      dataIndex: "vendorValue",
      key: "vendorValue",
      render: (vendorValue, valueType) =>
        valueType.valueType === "PERCENTAGE"
          ? Number(vendorValue || 0).toLocaleString() + "%"
          : Number(vendorValue || 0).toLocaleString(),
    },

    {
      title: "Value Type",
      dataIndex: "valueType",
      key: "valueType",
      render: (val) => <Tag color={"purple"}>{val}</Tag>,
    },

    {
      title: "Max Amount",
      dataIndex: "maxAmount",
      key: "maxAmount",
      render: (maxAmount) => Number(maxAmount).toLocaleString(),
    },
    {
      title: "Min Amount",
      dataIndex: "minAmount",
      key: "minAmount",
      render: (minAmount) => Number(minAmount).toLocaleString(),
    },
    {
      title: "Number Of Days",
      dataIndex: "numberOfDays",
      key: "numberOfDays",
      render: (numberOfDays) => Number(numberOfDays).toLocaleString(),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("VOUCHER_SETTING_UPDATE") ? false : true}
          loading={selectedID === row.id && editStatusLoading}
          checked={row.status}
          onChange={(state) => editVocherSettingsstatus(row)}
        />
      ),
    },
    {
      title: "Platforms",
      dataIndex: "platform",
      key: "platform",
      ellipsis: true,
      render: (val) => (
        <Popover
          content={
            val
              ? Array.isArray(JSON.parse(val))
                ? JSON.parse(val).map((el) => (
                    <Tag key={el} color="geekblue">
                      {el}
                    </Tag>
                  ))
                : val
              : "..."
          }
        >
          {val
            ? Array.isArray(JSON.parse(val))
              ? JSON.parse(val).map((el) => (
                  <Tag key={el} color="geekblue">
                    {el}
                  </Tag>
                ))
              : val
            : "..."}
        </Popover>
      ),
    },

    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("VOUCHER_SETTING_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("VOUCHER_SETTING_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => removeMutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={row.mandatory == "true" ? true : false}
                size="small"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate: removeMutate } = useMutation(removeVoucherSettings, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "voucherSettings",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["voucherSettings", queryKey, , , page], getVoucherSettings, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
