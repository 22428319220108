import { useState, useEffect } from "react";
import {
  Typography,
  TimePicker,
  Row,
  Col,
  Space,
  Card,
  Button,
} from "antd";
import "resize-observer-polyfill/dist/ResizeObserver.global";
import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";

import dayjs from "dayjs"

const { Text } = Typography;
const { RangePicker } = TimePicker;

export const listSchedule = (fromInit) => [
  {
    key: 1,
    weekDay: "Sunday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
  {
    key: 2,
    weekDay: "Monday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
  {
    key: 3,
    weekDay: "Tuesday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
  {
    key: 4,
    weekDay: "Wednesday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
  {
    key: 5,
    weekDay: "Thursday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
  {
    key: 6,
    weekDay: "Friday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
  {
    key: 7,
    weekDay: "Saturday",
    workingTime: [
      {
        from: dayjs().set('hour', fromInit).set('minute', 0).toDate(),
        to: dayjs().set('hour', 23).set('minute', 59).toDate(),
      },
    ],
  },
];

export const CustomeSchedule = ({ value, onChange ,fromInit }) => {
  const [schedule, setSchedule] = useState(value ? value : listSchedule(fromInit));

  useEffect(() => {
    if (onChange) {
      onChange(schedule);
    }

  }, [schedule]);

  let handleAdd = (el) => {
    let objIndex = schedule.findIndex((obj) => obj.key == el.key);

    let newList = [...schedule];
    newList[objIndex] = {
      ...el,
      workingTime: [...el.workingTime, { from: null, to: null }],
    };

    setSchedule(newList);
  };

  let handleDelete = (el, index) => {
    let objIndex = schedule.findIndex((obj) => obj.key === el.key);
    el.workingTime.splice(index, 1);
    let newList = [...schedule];
    newList[objIndex] = {
      ...el,
      workingTime: el.workingTime,
    };

    setSchedule(newList);
  };

  let handleChange = (el, index, value) => {
    let objIndex = schedule.findIndex((obj) => obj.key === el.key);
    el.workingTime[index] = { from: value ? dayjs(value[0]).toDate() : dayjs().set("hour", 0).set("minute", 0).toDate(),
    to: value ? dayjs(value[1]).toDate() : dayjs().set("hour", 0).set("minute", 0).toDate() };
    let newList = [...schedule];
    newList[objIndex] = {
      ...el,
      workingTime: el.workingTime,
    };

    setSchedule(newList);
  };

  return (
    <div className="custome-schedule" style={{ marginTop: 20 }}>
      <Row gutter={[10, 10]}>
        {schedule.map((el) => (
          <Col key={el.key} flex={"14.2857142857%"}>
            <Card bodyStyle={{ padding: "4px 10px" }}>
              <Text>{el.weekDay}</Text>
            </Card>
            {el.workingTime.map((time, index) => (
              <Space style={{ marginTop: 16 }}>
                <DeleteOutlined
                  className={index == 0 ? "disabled" : "tauchable"}
                  onClick={() => index != 0 && handleDelete(el, index)}
                />
                <RangePicker
                  value={
                    time.from !== null
                      ? [dayjs(time.from), dayjs(time.to)]
                      : []
                  }
                  onChange={(val) => handleChange(el, index, val)}
                  bordered={false}
                  format={"hh:mm A"}
                  size="small"
                />
              </Space>
            ))}
            <Space
              onClick={() => handleAdd(el)}
              className="tauchable"
              style={{ marginTop: 10 }}
            >
              <PlusCircleFilled />
              ....
            </Space>
          </Col>
        ))}
      </Row>
      <Button></Button>
    </div>
  );
};
