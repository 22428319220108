import { useEffect, useState, useMemo } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Popover,
  Alert,
  Menu,
  Dropdown,
  Card,
  message,
  Modal,
  Pagination,
  Switch,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  ShoppingCartOutlined,
  ContactsOutlined,
  MoreOutlined,
  DownOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";

import authFunc from "../../helper/auth";
import {
  getVendors,
  removeVendor,
  pageSize,
  updateVendorStatus,
  getStatus,
  editVendorStatus,
} from "../../api";
import { PureMapModal } from "./mapModal";
import { WorkingSchedule } from "./workingSchedule";
import handleDataMutation from "../../helper/dataMutation";
import { useVendor } from "./store";
import { useNavigate } from "react-router-dom";
import { ImageTable } from "../../comonents/global";
import { useSearchParams } from "react-router-dom";

const { Text, Title } = Typography;

export const PureTable = () => {
  const {
    setIsMapModal,
    setIsCreateModal,
    setId,
    setStep,
    queryKey,

    setIsSchedule,
    setRow,

    setPage,
    page,
    setIsCloseTimeModal,
    setClosedUntil,

    provinceID,
    cityID,
    statusCodeID,
    businessCategoryIDS,
    active,
    searchRating,
    asc,
  } = useVendor();

  const [selected, setIsSelected] = useState(null);

  const [selectedID, setSelectedID] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setStep(0);
    setId(row.id);

    setIsCreateModal(true);
  };

  const { loading: statusCodeLoading, data: statusList } = useQuery(
    "statusCodes",
    getStatus,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const menu = (row) => (
    <Menu onClick={(e) => editVendorState(e, row)}>
      {statusList?.data?.records?.map((el) => (
        <Menu.Item key={el.id}>{el.name}</Menu.Item>
      ))}
    </Menu>
  );

  const editVendorState = (e, row) => {
    setSelectedID(row.id);

    let data = {
      id: row.id,
      statusCodeID: Number(e.key),
    };
    editStateMutate(data);
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        width: 65,
      },
      {
        title: "Logo",
        key: "id",
        width: 65,
        render: (row) => (
          <ImageTable
            logo={true}
            url={row.logoImg}
            id={row.id}
            pathName={"vendor/logo"}
            imgKey={"logoFile"}
            updateStore={"vendors"}
          />
        ),
      },
      {
        title: "Banner",
        key: "id",
        width: 100,
        render: (row) => (
          <ImageTable
            logo={false}
            url={row.bannerImg}
            id={row.id}
            pathName={"vendor/banner"}
            imgKey={"bannerFile"}
            updateStore={"vendors"}
          />
        ),
      },

      {
        title: "Name",
        dataIndex: "arName",
        key: "arName",
        ellipsis: true,
        render: (text, row) => (
          <Popover
            placement="topLeft"
            content={
              <Space direction="vertical" size={0}>
                <Text>
                  <b>{row.arName}</b>
                </Text>
                <Text type="secondary">{row.description || "....."}</Text>
              </Space>
            }
          >
            <Text style={{ fontWeight: "bold" }}>{text}</Text>
          </Popover>
        ),
      },

      {
        title: "Region",
        dataIndex: "city",
        key: "city",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        ellipsis: true,
        render: (text) => (
          <Tooltip title={text}>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {text}
            </Text>
          </Tooltip>
        ),
      },

      {
        title: "Owner Name",
        dataIndex: "ownerName",
        key: "ownerName",
        ellipsis: true,
      },
      {
        title: "Owner Phone",
        dataIndex: "mgmtPhone",
        key: "mgmtPhone",
        ellipsis: true,

        render: (text, row) => (
          <Text style={{ fontSize: 12 }}>{row.mgmtPhone}</Text>
        ),
      },
      {
        title: "Vendor Phone",
        dataIndex: "phone",
        key: "phone",
        ellipsis: true,

        render: (text, row) => (
          <Text style={{ fontSize: 12 }}>{row.phone}</Text>
        ),
      },
      {
        title: "Vendor Type",
        dataIndex: "businessCategories",
        key: "businessCategories",
        ellipsis: true,
        render: (val) => (
          <Popover
            content={
              val
                ? val.map((el) => (
                    <Tag key={el.id} color="geekblue">
                      {el.name}
                    </Tag>
                  ))
                : "..."
            }
          >
            {val
              ? val.map((el) => (
                  <Text key={el.id} color="geekblue">
                    {el.name}
                  </Text>
                ))
              : "..."}
          </Popover>
        ),
      },
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
        width: 50,
      },

      {
        title: "Status",
        key: "id",
        width: 60,
        render: (row) => (
          <Switch
            disabled={authFunc("VENDOR_UPDATE") ? false : true}
            loading={selectedID === row.id && editLoading}
            checked={row.active ? true : false}
            onChange={() => handleChangeActive(row)}
          />
        ),
      },
      {
        title: "Vendor State",
        dataIndex: "StatusCode",
        key: "StatusCode",
        render: (StatusCode, row) => (
          <Dropdown
            overlay={menu(row)}
            disabled={authFunc("VENDOR_UPDATE") ? false : true}
          >
            <Button size="small" type="link">
              {StatusCode} <DownOutlined />
            </Button>
          </Dropdown>
        ),
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        width: 70,
        align: "right",

        render: (id, row) => (
          <Dropdown
            overlay={
              <Card bodyStyle={{ padding: 0 }}>
                {authFunc("PRODUCT_READ") && (
                  <div
                    className="vendor-action-item"
                    onClick={() =>
                      navigate(`/vendors/${id}/vendorProductVariation`)
                    }
                  >
                    <Space>
                      <ShoppingCartOutlined />
                      Vendor Products
                    </Space>
                  </div>
                )}

                {authFunc("VENDOR_CONTRACT_READ") && (
                  <div
                    className="vendor-action-item"
                    onClick={() => navigate(`/vendors/${id}/contracts`)}
                  >
                    <Space>
                      <ContactsOutlined />
                      Vendor Contracts
                    </Space>
                  </div>
                )}

                {authFunc("VENDOR_UPDATE") && (
                  <div
                    className="vendor-action-item"
                    onClick={() => {
                      setIsSchedule(true);
                      setRow(row);
                    }}
                  >
                    <Space>
                      <CalendarOutlined />
                      Working Days
                    </Space>
                  </div>
                )}
                {authFunc("VENDOR_UPDATE") && (
                  <div
                    className="vendor-action-item"
                    onClick={() => {
                      setIsCloseTimeModal(true);
                      setClosedUntil(row?.closed_until);
                      setRow(row);
                    }}
                  >
                    <Space>
                      <FieldTimeOutlined />
                      Close Time
                    </Space>
                  </div>
                )}

                {authFunc("VENDOR_UPDATE") && (
                  <div
                    className="vendor-action-item"
                    onClick={() => {
                      setId(row.id);
                      setIsMapModal(true);
                      setIsSelected(row);
                    }}
                  >
                    <Space>
                      <EnvironmentOutlined />
                      Vendor Location
                    </Space>
                  </div>
                )}

                {authFunc("VENDOR_UPDATE") && (
                  <div
                    className="vendor-action-item"
                    onClick={() => handleEdit(row)}
                  >
                    <Space>
                      <EditOutlined />
                      Edit Info
                    </Space>
                  </div>
                )}

                {authFunc("VENDOR_DELETE") && (
                  <div
                    className="vendor-action-item vendor-action-item-danger"
                    onClick={() => {
                      Modal.confirm({
                        loading: true,
                        content: (
                          <div>
                            <Title level={5}>Confirm</Title>
                            <Text>Are you sure to delete this Vendor ?</Text>
                          </div>
                        ),

                        onOk: () => mutate(id),
                      });
                    }}
                  >
                    <Space>
                      <DeleteOutlined />
                      Delete Vendor
                    </Space>
                  </div>
                )}
              </Card>
            }
            placement="bottomRight"
          >
            <Button
              style={{ fontSize: 20 }}
              type="text"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        ),
      },
    ],
    [statusList, statusCodeLoading]
  );

  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeVendor(val),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendors",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    [
      "vendors",
      queryKey,
      page,
      provinceID,
      cityID,
      statusCodeID,
      businessCategoryIDS,
      active,
      searchRating,
      asc,
    ],
    getVendors,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: editStateMutate } = useMutation(editVendorStatus, {
    onSuccess: (data) => {
      setSelectedID(null);
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "vendors",
      });
    },
    onError: (e) => {
      setSelectedID(null);
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    updateVendorStatus,
    {
      onSuccess: (data) => {
        setSelectedID(null);
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendors",
        });
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    setSelectedID(row.id);
    let data = {
      id: row.id,
      active: !row.active,
    };
    editMutate(data);
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  return (
    <>
      <Table
        loading={loading || addLoading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
      <PureMapModal data={selected} />
      <WorkingSchedule data={selected} />
    </>
  );
};
