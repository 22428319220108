import create from "zustand";

export const useVendorLog = create((set) => ({
  queryKey: "",
  page: 1,
  historyData: null,
  isStatusModal: false,
  isSchedual:  false,
  workingSchedule: null,
  row: null,
  queryFilter: "",
  from: null,
  to: null,
  
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setPage: (page) => set({ page }),
  setHistoryData: (historyData) => set({ historyData }),
  setIsStatusModal: (isStatusModal) => set({ isStatusModal }),
  setIsSchedule: (isSchedual) => set({ isSchedual }),
  setWorkingSchedule: (workingSchedule) => set({ workingSchedule }),
  setRow: (row) => set({ row }),
}));
