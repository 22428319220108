import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useStatusCode } from "./store";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "react-query";

import { createStatusCode, editStatusCode } from "../../api";
import { ColorPicker } from "../../comonents/global";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    color,
    name,
    description,
    setColor,
    setName,
    setId,
    setDescription,
  } = useStatusCode();

  const resetState = () => {
    setName(null);
    setId(null);
    setDescription(null);
  };

  const { mutate, isLoading } = useMutation(createStatusCode, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "statusCode",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
   
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editStatusCode,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "statusCode",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
        
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.color = color;

    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Status</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, name, description }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Status Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Status Code name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <TextArea rows={4} placeholder={"Status Code Description"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <ColorPicker value={color} onChange={(val) => setColor(val)} />
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
